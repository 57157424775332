import { Component } from "react";
import autoBind from "react-autobind";
import queryString from "query-string";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { VendorUtilities } from "../../utilities/VendorUtilities";
import Modal from "react-modal";
import isEmpty from "lodash/isEmpty";

import Footer from "../shared/Footer";
import ModalHeader from "../modals/ModalHeader";
import ModalBody from "../modals/ModalBody";
import ReplicatedInfo from "./ReplicatedInfo";

import "../../scss/components/auth/AuthPage.scss";
import { Button } from "../shared/Button";
import { User } from "@/types";

interface LoginState {
  email: string;
  password: string;
  rememberMe: string;
  emailError: boolean;
  emailMessage: string;
  passwordError: boolean;
  passwordMessage: string;
  resetError: boolean;
  resetMessage: string;
  viewPassword: boolean;
  needsOtp: boolean;
  otpCode: string;
  resetPasswordEmailAddress: string;
  isTrialClosed: boolean;
}

interface RouteParams {
  next: string;
}

interface LoginProps extends RouteComponentProps<RouteParams> {
  breakpoint: "mobile" | "tablet" | undefined;
  clearServerError: () => void;
  dataLoading: {
    activationCodeLoading: boolean;
    googleLoginLoading: boolean;
    loginLoading: boolean;
    otpLoginLoading: boolean;
    passwordResetLoading: boolean;
    signupLoading: boolean;
  };
  displayModal: (type: string, isTrue: boolean) => void;
  err: boolean;
  errMessage: string;
  handleGoogleLogin: () => void;
  handleLogIn: (
    payload: { email: string; password: string },
    history: RouteComponentProps["history"],
    parsedSearch: string | string[]
  ) => void;
  handleOtpLogin: (payload: { code: string }, callback: () => void) => void;
  hasRehydrated: boolean;
  hasToken: string[];
  loadingData: (type: string, loading: boolean) => void;
  logOutUser: () => void;
  modals: {
    forgotPasswordModal: boolean;
  };
  needsOtp: boolean;
  resetPasswordError: string;
  resetPasswordStep: string;
  sendPasswordResetEmail: (payload: { email: string }) => void;
  setResetPasswordStep: (step: string) => void;
  user: User;
}

class Login extends Component<LoginProps, LoginState> {
  refPassword: HTMLInputElement;
  refOtpCode: HTMLInputElement;
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: "0",
      emailError: false,
      emailMessage: "",
      passwordError: false,
      passwordMessage: "",
      resetError: false,
      resetMessage: "",
      viewPassword: false,
      needsOtp: false,
      otpCode: "",
      resetPasswordEmailAddress: "",
      isTrialClosed: false
    };
    autoBind(this);
  }

  clearErrors = () => {
    this.setState({
      emailError: false,
      emailMessage: "",
      passwordError: false,
      passwordMessage: "",
      resetError: false,
      resetMessage: ""
    });
  };

  togglVisiblePassword = () => {
    this.setState({
      viewPassword: !this.state.viewPassword
    });
    this.refPassword.focus();
  };

  handleFormChange = (field, e) => {
    if (this.state.emailError || this.state.passwordError || this.state.resetError) {
      this.clearErrors();
    }
    if (this.props.err) {
      this.props.clearServerError();
    }
    const nextState = {};
    nextState[field] = e.target.value;
    this.setState(nextState);
  };

  buildPayload = () => {
    return {
      email: this.state.email.trim(),
      password: this.state.password.trim()
    };
  };

  validateFields = () => {
    if (this.state.email.length === 0) {
      this.setState({
        emailError: true,
        emailMessage: "Email address is required"
      });
      return false;
    }
    if (this.state.password.length === 0) {
      this.setState({
        passwordError: true,
        passwordMessage: "Password is required"
      });
      return false;
    }
    return true;
  };

  handleGoogleLoginClick = async ev => {
    ev.preventDefault();
    this.props.handleGoogleLogin();
  };

  onSubmit = e => {
    e.preventDefault();
    this.clearErrors();
    const payload = this.buildPayload();
    const canSubmit = this.validateFields();
    const parsedSearch = queryString.parse(this.props.location.search);
    if (canSubmit) {
      this.props.handleLogIn(payload, this.props.history, parsedSearch.next);
    }
    window.localStorage.setItem("isAfterLogin", "true");
    this.setState({
      isTrialClosed: false
    });
  };

  onOtpSubmit = e => {
    e.preventDefault();
    const parsedSearch = queryString.parse(this.props.location.search);
    const payload = {
      code: this.state.otpCode.trim()
    };
    const callback = () => {
      this.props.history.push(`${parsedSearch.next || "apps"}`);
    };
    this.props.handleOtpLogin(payload, callback);
  };

  resetPassword = () => {
    const payload = {
      email: this.state.resetPasswordEmailAddress
    };
    if (!VendorUtilities.isEmailValid(this.state.resetPasswordEmailAddress)) {
      this.setState({
        resetError: true,
        resetMessage: "Please use a valid email address"
      });
      return false;
    }
    this.props.sendPasswordResetEmail(payload);
  };

  closeResetPassModal = () => {
    this.setState({
      resetPasswordEmailAddress: "",
      resetError: false,
      resetMessage: ""
    });
    this.props.displayModal("forgotPassword", false);
    this.props.setResetPasswordStep("info");
    this.props.clearServerError();
  };

  closeTrialExpiredModal = () => {
    this.setState({
      isTrialClosed: true
    });
    this.props.displayModal("trialExpired", false);
  };

  componentDidUpdate(lastProps) {
    if (this.props.needsOtp !== lastProps.needsOtp && this.props.needsOtp) {
      this.setState({
        email: "",
        password: ""
      });
      if (this.refOtpCode) {
        setTimeout(() => {
          this.refOtpCode.focus();
        }, 50);
      }
    }
  }

  componentWillMount() {
    const { next } = this.props.match.params;
    if (
      this.props.hasToken &&
      !!this.props.hasToken.length &&
      !this.props.needsOtp &&
      !isEmpty(this.props.user)
    ) {
      this.props.history.push(`/${next || "apps"}`);
    }
  }

  componentDidMount() {
    VendorUtilities.purgeVOneData();
    this.props.clearServerError();
    this.props.loadingData("login", false);
    this.props.loadingData("otpLogin", false);
    this.props.loadingData("googleLogin", false);
    const persist = !window.localStorage.getItem("persist_session") ? "0" : "1";
    this.setState({
      rememberMe: persist
    });
  }

  componentWillUnmount() {
    this.props.clearServerError();
  }

  render() {
    const { dataLoading, needsOtp, modals } = this.props;

    if (
      this.props.hasToken &&
      !!this.props.hasToken.length &&
      !needsOtp &&
      !isEmpty(this.props.user)
    ) {
      return null;
    }

    return (
      <div className="u-width--full u-overflow--auto flex-column">
        <div className="u-flexTabletReflowReverse flex-1-auto u-width--full">
          <ReplicatedInfo />
          <div className="Login-wrapper flex1 flex-column flex-verticalCenter alignItems--center u-paddingBottom--normal auth-pages">
            <div className="Form-wrapper">
              <p className="form-title u-lineHeight--normal">
                {needsOtp ? "Verification Code" : "Log in"}
              </p>
            </div>

            {needsOtp || dataLoading.otpLoginLoading ? (
              <div className="Form-wrapper">
                <form
                  onSubmit={e => {
                    this.onOtpSubmit(e);
                  }}
                >
                  <div className="FormSection-wrapper">
                    {this.props.err ? (
                      <div className="ErrorBlock u-marginBottom--small">
                        <p>{this.props.errMessage}</p>
                      </div>
                    ) : null}
                    <div className="u-marginBottom--normal">
                      <input
                        className="Input"
                        type="text"
                        ref={input => (this.refOtpCode = input)}
                        placeholder="Code"
                        value={this.state.otpCode}
                        onChange={e => {
                          this.handleFormChange("otpCode", e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="FormButton-wrapper flex">
                    <Button
                      kind="primary-purple"
                      type="submit"
                      size="large"
                      className="flex-auto tw-mt-2.5 tw-mb-5"
                      disabled={
                        dataLoading.otpLoginLoading || this.state.otpCode === ""
                      }
                    >
                      {dataLoading.otpLoginLoading ? "Verifying" : "Verify"}
                    </Button>
                    <span className="u-marginLeft--more flex-column justifyContent--center">
                      <p
                        className="u-color--astral u-fontSize--normal u-fontWeight--medium u-textDecoration--underlineOnHover"
                        onClick={() => this.props.logOutUser()}
                      >
                        Log out
                      </p>
                    </span>
                  </div>
                </form>
                <p className="u-fontSize--small u-color--dustyGray u-marginTop--more u-lineHeight--normal">
                  Use a supported 2FA mobile app to retrieve your verification code,
                  such as{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-color--astral u-fontWeight--medium"
                  >
                    Google Authenticator
                  </a>
                  .
                </p>
              </div>
            ) : (
              <div className="Form-wrapper">
                <form
                  onSubmit={e => {
                    this.onSubmit(e);
                  }}
                >
                  <div className="FormSection-wrapper">
                    {this.state.emailError ? (
                      <div className="ErrorBlock u-marginBottom--small">
                        <p>{this.state.emailMessage}</p>
                      </div>
                    ) : null}
                    {this.props.err ? (
                      <div className="ErrorBlock u-marginBottom--small">
                        <p>{this.props.errMessage}</p>
                      </div>
                    ) : null}
                    <div className="u-marginBottom--more">
                      <input
                        className="Input"
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        autoFocus
                        value={this.state.email}
                        onChange={e => {
                          this.handleFormChange("email", e);
                        }}
                      />
                    </div>
                    {this.state.passwordError ? (
                      <div className="ErrorBlock u-marginBottom--small">
                        <p>{this.state.passwordMessage}</p>
                      </div>
                    ) : null}
                    <div className="u-position--relative u-marginBottom--normal">
                      <input
                        className="Input"
                        type={this.state.viewPassword ? "text" : "password"}
                        ref={input => (this.refPassword = input)}
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={e => {
                          this.handleFormChange("password", e);
                        }}
                      />
                      {this.state.password !== "" ? (
                        <span
                          className="see-pass u-color--astral u-fontSize--small u-fontWeight--medium u-textDecoration--underlineOnHover"
                          onClick={this.togglVisiblePassword}
                        >
                          {this.state.viewPassword ? "Hide" : "Show"}
                        </span>
                      ) : null}
                    </div>
                    <div className="forgot-pass">
                      <p
                        className="u-fontSize--small u-fontWeight--medium u-color--blue u-textDecoration--underlineOnHover u-display--inlineBlock"
                        onClick={() => {
                          this.props.displayModal("forgotPassword", true);
                        }}
                      >
                        Forgot password?
                      </p>
                    </div>
                  </div>
                  <div className="FormButton-wrapper flex">
                    <Button
                      kind="primary-purple"
                      size="large"
                      className="flex-auto tw-mt-2.5 tw-mb-5 tw-w-full !tw-text-lg"
                      disabled={dataLoading.loginLoading}
                    >
                      {dataLoading.loginLoading ? "Logging in" : "Log in"}
                    </Button>
                  </div>
                  <div className="u-orTransitionBlock">
                    <span>or</span>
                  </div>
                  <div className="flex u-marginTop--20">
                    <a
                      href="#"
                      onClick={this.handleGoogleLoginClick}
                      className="Button third-party-auth google flex alignItems--center justifyContent--center"
                    >
                      <span className="icon clickable google-g u-marginRight--normal" />{" "}
                      Log in with Google
                    </a>
                  </div>
                </form>
                <div className="u-marginTop--15 u-fontSize--normal">
                  <p className="u-color--dustyGray u-textAlign--center">
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                    >
                      Sign up
                    </Link>
                  </p>
                </div>
              </div>
            )}
            <div className="auth-footer">
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          isOpen={
            !this.state.isTrialClosed && this.props.errMessage === "Trial expired"
          }
          ariaHideApp={false}
          contentLabel="Trial Expired Modal"
          className="Modal DefaultSize"
          onRequestClose={this.closeTrialExpiredModal}
        >
          <ModalHeader
            title="Trial expired"
            requestClose={this.closeTrialExpiredModal}
          />
          <ModalBody>
            <>
              Your free trial has ended. Please contact us at{" "}
              <a
                href="mailto:contact@replicated.com"
                target="_blank"
                rel="noopener noreferrer"
                className="u-color--astral u-fontWeight--medium u-textDecoration--underlineOnHover"
              >
                contact@replicated.com{" "}
              </a>
              if you need an extension.
              <div className="tw-mt-8">
                <Button
                  kind="secondary-gray"
                  className="tw-mr-3 tw-mt-3"
                  onClick={this.closeTrialExpiredModal}
                >
                  Cancel
                </Button>
              </div>
            </>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modals.forgotPasswordModal}
          contentLabel="Forgot Password Modal"
          ariaHideApp={false}
          className="Modal DefaultSize"
          onRequestClose={this.closeResetPassModal}
        >
          <ModalHeader
            title="Reset your password"
            requestClose={this.closeResetPassModal}
          />
          <ModalBody>
            {this.props.resetPasswordStep === "success" ? (
              <>
                <p className="u-fontSize--large u-fontWeight--normal u-color--tuna u-lineHeight--normal u-textAlign--center">
                  Check your inbox. If there is an account with the specified address,
                  an email will be sent with instructions for resetting the password.
                </p>
                <div className="u-marginTop--more u-textAlign--center">
                  <Button
                    type="button"
                    kind="primary-purple"
                    onClick={this.closeResetPassModal}
                  >
                    Ok, got it!
                  </Button>
                </div>
              </>
            ) : (
              <>
                {this.props.resetPasswordError ? (
                  <div className="ErrorBlock u-marginBottom--small">
                    <p>{this.props.resetPasswordError}</p>
                  </div>
                ) : null}
                <div className="tw-mb-8">
                  <p className="FormLabel u-margin--none">
                    What is your email address?
                  </p>
                  {this.state.resetError ? (
                    <div className="ErrorBlock u-marginBottom--small">
                      <p>{this.state.resetMessage}</p>
                    </div>
                  ) : null}
                  <input
                    className="Input"
                    type="text"
                    placeholder="you@example.com"
                    value={this.state.resetPasswordEmailAddress}
                    onChange={e => {
                      this.handleFormChange("resetPasswordEmailAddress", e);
                    }}
                  />
                </div>
                <div className="tw-flex tw-items-center tw-justify-between">
                  <Button
                    type="button"
                    kind="secondary-purple"
                    className="tw-mr-2"
                    onClick={this.closeResetPassModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    kind="primary-purple"
                    disabled={
                      this.state.resetPasswordEmailAddress === "" ||
                      dataLoading.passwordResetLoading
                    }
                    onClick={this.resetPassword}
                  >
                    {dataLoading.passwordResetLoading ? "Sending" : "Send email"}
                  </Button>
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Login);
