import "isomorphic-fetch";
import { SessionManager } from "../../../../services/SessionManager";
import {
  setSessionError,
  logOutUser,
  setUserAccountInformation
} from "../../../auth/sessions/actions";
import { showSavedToast } from "../../../ui/main/apps/view_release/actions";
import { loadingData, displayModal, forbiddenData } from "../../../ui/main/actions";

export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";
export const SET_TWO_FACTOR_STATUS = "SET_TWO_FACTOR_STATUS";
export const SET_TWO_FACTOR_STEP = "SET_TWO_FACTOR_STEP";
export const SET_TWO_FACTOR_PROVISION = "SET_TWO_FACTOR_PROVISION";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";
export const SET_NOTIFICATION_ERROR = "SET_NOTIFICATION_ERROR";
export const CLEAR_NOTIFICATION_ERROR = "CLEAR_NOTIFICATION_ERROR";
export const SET_CHANGEPASSWORD_ERROR = "SET_CHANGEPASSWORD_ERROR";
export const SET_RECOVERY_CODES = "SET_RECOVERY_CODES";
export const CLEAR_RECOVERY_CODES = "CLEAR_RECOVERY_CODES";
export const SET_USER_TOKENS = "SET_USER_TOKENS";
export const SET_NEW_USER_TOKEN = "SET_NEW_USER_TOKEN";
export const SET_NEW_USER_TOKEN_ERR = "SET_NEW_USER_TOKEN_ERR";
const SET_NEW_SESSION = "SET_NEW_SESSION";

function updateToken(token) {
  return {
    type: UPDATE_USER_TOKEN,
    payload: token
  };
}

function setUserNotifications(notifications) {
  return {
    type: SET_USER_NOTIFICATIONS,
    payload: notifications
  };
}

function setTwoFactorStatus(enabled, enforced) {
  return {
    type: SET_TWO_FACTOR_STATUS,
    payload: { enabled, enforced }
  };
}

export function setTwoFactorStep(step) {
  return {
    type: SET_TWO_FACTOR_STEP,
    payload: step
  };
}

function setTwoFactorProvision(data) {
  return {
    type: SET_TWO_FACTOR_PROVISION,
    payload: data
  };
}

function setNotificationError(err) {
  return {
    type: SET_NOTIFICATION_ERROR,
    payload: err
  };
}

export function clearNotificationError() {
  return {
    type: CLEAR_NOTIFICATION_ERROR
  };
}

function setChangePasswordError(err) {
  return {
    type: SET_CHANGEPASSWORD_ERROR,
    payload: err
  };
}

function setRecoveryCodes(codes) {
  return {
    type: SET_RECOVERY_CODES,
    payload: codes
  };
}

function setNewSession(token, user) {
  return {
    type: SET_NEW_SESSION,
    payload: { token, user }
  };
}

export function clearRecoveryCodes() {
  return {
    type: CLEAR_RECOVERY_CODES
  };
}

function setUserTokens(tokens) {
  return {
    type: SET_USER_TOKENS,
    payload: tokens
  };
}

export function setNewUserToken(token) {
  return {
    type: SET_NEW_USER_TOKEN,
    payload: token
  };
}

export function setNewUserTokenErr(message) {
  return {
    type: SET_NEW_USER_TOKEN_ERR,
    payload: message
  };
}

// Change Password

export function changePassword(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("changePassword", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/password/change`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(updateToken(body.token));
      dispatch(loadingData("changePassword", false));
      dispatch(showSavedToast(true));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (err) {
      const error = await response.json();
      dispatch(loadingData("changePassword", false));
      dispatch(setChangePasswordError(error));
      return;
    }
  };
}

// Two Factor Auth

// while this function is called confirmPassword, what it does is flip the 2fa status
export function confirmPassword(payload, activating) {
  return async (dispatch, getState) => {
    dispatch(loadingData("confirmPassword", true));
    let response;
    try {
      let url = "";
      if (activating) {
        url = `${SessionManager.getApiEndpoint()}/otp/provision`;
      } else {
        url = `${SessionManager.getApiEndpoint()}/otp/unprovision`;
      }

      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }

      dispatch(loadingData("confirmPassword", false));
      dispatch(displayModal("confirmPassword", false));
      if (activating) {
        const body = await response.json();
        dispatch(setTwoFactorProvision(body));
        dispatch(setTwoFactorStep("configure"));
      } else {
        dispatch(checkTwoFactor());
        dispatch(clearRecoveryCodes());
        dispatch(showSavedToast(true));
        setTimeout(() => {
          dispatch(showSavedToast(false));
        }, 3000);
      }
    } catch (error) {
      dispatch(loadingData("confirmPassword", false));
      dispatch(
        setSessionError({
          error: {
            message: "Password is incorrect, please try again"
          }
        })
      );
      return;
    }
  };
}

export function checkTwoFactor() {
  return async (dispatch, getState) => {
    dispatch(loadingData("twoFactor", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/otp/status`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setTwoFactorStatus(body.enabled, body.enforced));
      dispatch(loadingData("twoFactor", false));
    } catch (error) {
      return;
    }
  };
}

export function finalizeCode(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("authCode", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/otp/finalize`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      const updatedUser = {
        ...getState().auth.sessions.sessionData.user,
        ...body.user
      };
      dispatch(setRecoveryCodes(body.codes));
      dispatch(setNewSession(body.newToken, updatedUser));
      dispatch(loadingData("recoveryCodes", false));
      dispatch(loadingData("authCode", false));
      dispatch(checkTwoFactor());
      dispatch(setTwoFactorStep("main"));
      dispatch(showSavedToast(true));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (error) {
      dispatch(loadingData("authCode", false));
      dispatch(
        setSessionError({
          error: {
            message: "Invalid code"
          }
        })
      );
      return;
    }
  };
}

// Notifications

export function getNotifications() {
  return async (dispatch, getState) => {
    dispatch(loadingData("notifications", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/user/notifications`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setUserNotifications(body.user_notifications));
      dispatch(loadingData("notifications", false));
    } catch (error) {
      dispatch(loadingData("notifications", false));
      return;
    }
  };
}

export function updateNotification(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData(`${payload.user_notifications[0].id}`, true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/user/notifications`;
      response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setUserNotifications(body.user_notifications));
      dispatch(loadingData(`${payload.user_notifications[0].id}`, false));
      dispatch(showSavedToast(true));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 1500);
    } catch (err) {
      dispatch(loadingData(`${payload.user_notifications[0].id}`, false));
      dispatch(
        setNotificationError({
          error: {
            message: "Unable to update notification setting, please try again.",
            id: payload.user_notifications[0].id
          }
        })
      );
      return;
    }
  };
}

export function getUserTokens() {
  return async (dispatch, getState) => {
    dispatch(loadingData("userTokens", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/user/tokens`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("userTokens", false));
          dispatch(forbiddenData("userTokens", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("userTokens", false));
      dispatch(setUserTokens(body));
    } catch (error) {
      dispatch(loadingData("userTokens", false));
      console.log(error);
      return;
    }
  };
}

export function createNewUserToken(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("createUserToken", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/user/token`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("newToken", false));
        }
        if (response.status === 400) {
          dispatch(setNewUserTokenErr(`Token name ${payload.name} is already in use`));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const res = await response.json();
      dispatch(loadingData("createUserToken", false));
      dispatch(setNewUserToken(res.access_token));
      dispatch(getUserTokens());
    } catch (error) {
      dispatch(loadingData("createUserToken", false));
      console.log(error);
      return;
    }
  };
}

export function removeUserToken(token) {
  return async (dispatch, getState) => {
    dispatch(loadingData("removeUserToken", true));
    let response;
    try {
      const payload = {
        name: token
      };

      const url = `${SessionManager.getApiEndpoint()}/user/token`;
      response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("confirmRemoveToken", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("removeUserToken", false));
      dispatch(displayModal("confirmRemoveToken", false));
      dispatch(getUserTokens());
    } catch (error) {
      dispatch(loadingData("removeUserToken", false));
      console.log(error);
      return;
    }
  };
}

export function updateUserAuth(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("updateUserAuth", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("1")}/user/auth`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("updateUserAuth", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      const updatedUser = {
        ...getState().auth.sessions.sessionData.user,
        ...body
      };
      dispatch(loadingData("updateUserAuth", false));
      dispatch(setUserAccountInformation(updatedUser));
      dispatch(showSavedToast(true));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (error) {
      dispatch(loadingData("updateUserAuth", false));
      console.log(error);
      return;
    }
  };
}
