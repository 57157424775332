import "isomorphic-fetch";
import {
  displayModal,
  loadingData,
  errorData,
  forbiddenData
} from "../../ui/main/actions";
import { logOutUser } from "../../auth/sessions/actions";
import { SessionManager } from "../../../services/SessionManager";

export const SET_KOTS_CHANNEL = "SET_KOTS_CHANNEL";

function setKotsChannel(channel) {
  return {
    type: SET_KOTS_CHANNEL,
    payload: {
      channel: channel
    }
  };
}

export function buildAirgapChannelRelease(appId, channelId, sequence) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint(
        "3"
      )}/app/${appId}/channel/${channelId}/release/${sequence}/airgap/build`;
      response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(getKotsChannel(appId, channelId));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function getKotsChannel(appId, channelId, excludeReleases = false) {
  return async (dispatch, getState) => {
    if (!appId || !channelId) {
      return;
    }

    dispatch(loadingData("kotsChannel", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint(
        "3"
      )}/app/${appId}/channel/${channelId}?excludeReleases=${excludeReleases}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        },
        mode: "cors"
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("kotsChannel", false));
          dispatch(forbiddenData("kotsChannel", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setKotsChannel(body));
      dispatch(forbiddenData("kotsChannel", false));
      dispatch(loadingData("kotsChannel", false));
      dispatch(errorData("kotsChannel", false));
    } catch (error) {
      dispatch(loadingData("kotsChannel", false));
      dispatch(errorData("kotsChannel", true));
      console.log(error);
      return;
    }
  };
}

export function getKotsChannelReleaseInstallCommands(
  appId,
  channelId,
  channelSequence
) {
  return async (dispatch, getState) => {
    if (!appId || !channelId) {
      return;
    }
    dispatch(loadingData("kotsChannelReleaseInstallCommands", true));
    const url = `${SessionManager.getApiEndpoint(
      "3"
    )}/app/${appId}/channel/${channelId}/release/${channelSequence}/install-commands`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: getState().auth.sessions.sessionData.accessToken,
        Accept: "application/json"
      },
      mode: "cors"
    });
    if (!response.ok) {
      if (response.status === 401) {
        dispatch(logOutUser());
      }
      if (response.status === 403) {
        dispatch(loadingData("kotsChannelReleaseInstallCommands", false));
        dispatch(forbiddenData("kotsChannelReleaseInstallCommands", true));
      }
      throw new Error(`Unexpected response status code ${response.status}`);
    }
    const body = await response.json();
    dispatch(forbiddenData("kotsChannelReleaseInstallCommands", false));
    dispatch(loadingData("kotsChannelReleaseInstallCommands", false));
    dispatch(errorData("kotsChannelReleaseInstallCommands", false));
    return body?.commands;
  };
}
