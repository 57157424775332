import { apiFetchVendor, VendorUtilities } from "@/utilities/VendorUtilities";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import cx from "classnames";

const LegacySchedulersEOLBanner = () => {
  const { data, refetch } = useQuery({
    queryKey: ["nativeEOLNotice"],
    queryFn: async () => {
      const path = `/heritage-eol-notice`;
      return (await apiFetchVendor(path)).json();
    },
    retry: 0
  });

  const { mutate: dismissEOLNotice } = useMutation({
    mutationFn: async () => {
      const path = `/heritage-eol-notice`;
      await apiFetchVendor(path, {
        method: "POST",
        body: JSON.stringify({})
      });
    },
    retry: 0,
    onSuccess: () => refetch()
  });

  const nativeData = data?.native;
  if (
    !VendorUtilities.isFeatureEnabled("heritage_eol_notice") ||
    !nativeData ||
    data?.showWarning === false
  ) {
    return null;
  }

  return (
    <>
      <div className="tw-text-[#424242] tw-w-full tw-box-border tw-relative tw-z-[310] tw-bg-[#FDBF44] tw-text-white tw-py-4 tw-px-9 tw-flex tw-flex-col tw-gap-3">
        <h3 className="tw-text-[#424242] tw-text-lg">
          You're currently using the Replicated Native Scheduler
        </h3>
        <div className="tw-text-sm">
          The Native Scheduler is currently in maintenance mode and is slated for end of
          availability December 31st, 2023, and end of life December 31st, 2024. Your
          team's Native Scheduler application(s) have{" "}
          <span className="tw-font-bold tw-text-base">
            {nativeData.onlineCustomers}
          </span>{" "}
          active customer{nativeData.onlineCustomers === 1 ? "" : "s"}, with{" "}
          <span className="tw-font-bold tw-text-base">
            {nativeData.onlineInstances}
          </span>{" "}
          active instance{nativeData.onlineInstances === 1 ? "" : "s"}, and{" "}
          <span className="tw-font-bold tw-text-base">
            {nativeData.airgapCustomers}
          </span>{" "}
          potentially-active airgap customer
          {nativeData.airgapCustomers === 1 ? "" : "s"}. If these licenses are unused,
          please archive them.
        </div>
        <div className="tw-flex tw-gap-2 tw-font-semibold tw-w-full tw-justify-between tw-items-center">
          <div className="tw-flex tw-gap-4">
            <a
              href="https://docs.replicated.com/vendor/policies-support-lifecycle"
              className={cx(
                "tw-text-xs tw-text-[#B18630] tw-px-2 tw-py-1.5 tw-bg-white",
                "tw-border tw-border-solid tw-border-[#B18630] tw-rounded"
              )}
              target="_blank"
            >
              View EOL announcement and FAQ
            </a>
            <Link
              to="/support?requestType=feature&productArea=vendor&title=Help%20migrating%20off%20of%20Native%20Scheduler"
              className={cx(
                "tw-text-xs tw-text-[#585858] tw-px-2 tw-py-1.5 tw-bg-white",
                "tw-border tw-border-solid tw-border-[#585858] tw-rounded"
              )}
            >
              Get help migrating
            </Link>
          </div>
          <span
            onClick={() => dismissEOLNotice()}
            className="tw-text-sm tw-cursor-pointer tw-border-b tw-border-solid tw-border-white tw-text-white"
          >
            Dismiss for 30 days
          </span>
        </div>
      </div>
    </>
  );
};

export default LegacySchedulersEOLBanner;
