import {
  CLEAR_INVITE_ERROR,
  CLEAR_REMOVE_INVITE_ERROR,
  CLEAR_SUPPORT_TICKET,
  SET_GOOGLE_AUTHENTICATION_SETTINGS,
  SET_INVITE_ERROR,
  SET_NEW_SERVICE_ACCOUNT,
  SET_NEW_SERVICE_ACCOUNT_ERR,
  SET_PASSWORD_SETTINGS,
  SET_REMOVE_INVITE_ERROR,
  SET_SAML_AUTHENTICATION_SETTINGS,
  SET_SAML_AUTHENTICATION_SETTINGS_ERROR,
  SET_SERVICE_ACCOUNTS,
  SET_SUPPORT_TICKET_SUCCESS,
  SET_TEAM_DATA,
  SET_TEAM_ENTITLEMENTS,
  SET_TEAM_MEMBERS,
  SET_TEAM_MEMBER_DATA,
  SET_TEAM_POLICIES,
  SET_TEAM_POLICY_DATA,
  SET_TEAM_TOKENS,
  SET_UPLOAD_SUCCESS
} from "./actions";
import keyBy from "lodash/keyBy";
import map from "lodash/map";

const teamsState = {
  team: {},
  member: {},
  policy: {},
  teamMembers: {},
  teamMemberIds: [],
  teamInviteeEmails: [],
  teamEntitlements: {},
  supportBundleUploaded: {},
  ticketSubmitted: null,
  serverInviteError: false,
  serverInviteErrMsg: "",
  serverRemoveInviteError: false,
  serverRemoveInviteErrorMsg: ""
};

export function teamsData(state = teamsState, action) {
  switch (action.type) {
    case SET_TEAM_DATA:
      return Object.assign({}, state, {
        team: action.payload
      });
    case SET_TEAM_MEMBER_DATA:
      return Object.assign({}, state, {
        member: action.payload
      });
    case SET_TEAM_POLICY_DATA:
      return Object.assign({}, state, {
        policy: action.payload
      });
    case SET_TEAM_MEMBERS: {
      const members = Object.assign({}, action.payload);
      const keyedTeamMembers = keyBy(members, "id");
      const mappedTeamMembers = map(members, "id");
      return Object.assign({}, state, {
        teamMembers: keyedTeamMembers,
        teamMemberIds: mappedTeamMembers
      });
    }
    case SET_INVITE_ERROR:
      return Object.assign({}, state, {
        serverInviteError: true,
        serverInviteErrMsg: action.payload.error.message
      });
    case CLEAR_INVITE_ERROR:
      return Object.assign({}, state, {
        serverInviteError: false,
        serverInviteErrMsg: ""
      });
    case SET_REMOVE_INVITE_ERROR:
      return Object.assign({}, state, {
        serverRemoveInviteError: true,
        serverRemoveInviteErrorMsg: action.payload.error.message
      });
    case CLEAR_REMOVE_INVITE_ERROR:
      return Object.assign({}, state, {
        serverRemoveInviteError: false,
        serverRemoveInviteErrorMsg: ""
      });
    case SET_TEAM_ENTITLEMENTS: {
      const settings = Object.assign({}, action.payload);
      const { entitlements } = settings;
      return Object.assign({}, state, {
        teamEntitlements: entitlements
      });
    }
    case SET_UPLOAD_SUCCESS: {
      const bundlePayload = Object.assign({}, action.payload);
      return Object.assign({}, state, {
        supportBundleUploaded: bundlePayload
      });
    }
    case SET_SUPPORT_TICKET_SUCCESS: {
      const ticketPayload = action.payload;
      return Object.assign({}, state, {
        ticketSubmitted: ticketPayload
      });
    }
    case CLEAR_SUPPORT_TICKET:
      return Object.assign({}, state, {
        ticketSubmitted: null
      });
    default:
      return state;
  }
}

const tokensState = {
  tokens: {},
  tokenList: []
};

export function tokensData(state = tokensState, action) {
  switch (action.type) {
    case SET_TEAM_TOKENS: {
      const tokens = Object.assign({}, action.payload);
      const keyedTokens = keyBy(tokens, "access_token");
      const mappedTokens = map(tokens, "access_token");
      return Object.assign({}, state, {
        tokens: keyedTokens,
        tokenList: mappedTokens
      });
    }
    default:
      return state;
  }
}

const serviceAccountsState = {
  serviceAccounts: {},
  serviceAccountList: [],
  newServiceAccount: null,
  newServiceAccountErr: null
};

export function serviceAccountsData(state = serviceAccountsState, action) {
  switch (action.type) {
    case SET_SERVICE_ACCOUNTS: {
      const serviceAccounts = Object.assign({}, action.payload);
      const keyedServiceAccounts = keyBy(serviceAccounts, "id");
      const mappedServiceAccounts = map(serviceAccounts, "id");
      return Object.assign({}, state, {
        serviceAccounts: keyedServiceAccounts,
        serviceAccountList: mappedServiceAccounts
      });
    }
    case SET_NEW_SERVICE_ACCOUNT:
      return Object.assign({}, state, {
        newServiceAccount: action.payload
      });
    case SET_NEW_SERVICE_ACCOUNT_ERR:
      return Object.assign({}, state, {
        newServiceAccountErr: action.payload
      });
    default:
      return state;
  }
}

const securityState = {
  securitySettings: {}
};

export function securityData(state = securityState, action) {
  switch (action.type) {
    case SET_PASSWORD_SETTINGS: {
      const securityPayload = Object.assign({}, action.payload);
      return Object.assign({}, state, {
        securitySettings: securityPayload
      });
    }
    default:
      return state;
  }
}

const googleAuthenticationState = {
  googleAuthenticationSettings: {}
};

export function googleAuthenticationData(state = googleAuthenticationState, action) {
  switch (action.type) {
    case SET_GOOGLE_AUTHENTICATION_SETTINGS: {
      const authPayload = Object.assign({}, action.payload);
      return Object.assign({}, state, {
        googleAuthenticationSettings: authPayload
      });
    }
    default:
      return state;
  }
}
const samlAuthenticationState = {
  samlAuthenticationSettings: {}
};

export function samlAuthenticationData(state = samlAuthenticationState, action) {
  switch (action.type) {
    case SET_SAML_AUTHENTICATION_SETTINGS: {
      const authPayload = Object.assign({}, action.payload);
      return Object.assign({}, state, {
        samlAuthenticationSettings: authPayload
      });
    }
    case SET_SAML_AUTHENTICATION_SETTINGS_ERROR: {
      return Object.assign({}, state, {
        samlAuthenticationSettings: {
          err: action.payload.errorMsg
        }
      });
    }
    default:
      return state;
  }
}

const policiesState = {
  availablePolicies: {}
};

export function policiesData(state = policiesState, action) {
  switch (action.type) {
    case SET_TEAM_POLICIES: {
      const policies = Object.assign({}, action.payload);
      const keyedPolicies = keyBy(policies, "id");
      return Object.assign({}, state, {
        availablePolicies: keyedPolicies
      });
    }
    default:
      return state;
  }
}
