import { combineReducers } from "redux";
import { appList } from "./reducer";
import ReleasesUiReducer from "./releases/reducer";
import ChannelsUiReducer from "./channels/reducer";
import LicensesUiReducer from "./licenses/reducer";
import LicenseFieldsUiReducer from "./license_fields/reducer";
import ViewReleaseUiReducer from "./view_release/reducer";
import TeamUiReducer from "./team/reducer";

export default combineReducers({
  appList,
  releases: ReleasesUiReducer,
  channels: ChannelsUiReducer,
  licenses: LicensesUiReducer,
  licenseFields: LicenseFieldsUiReducer,
  viewRelease: ViewReleaseUiReducer,
  team: TeamUiReducer
});
