import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQueryParam } from "use-query-params";

import { SET_SESSION_DATA } from "@/redux/auth/sessions/actions";
import { loadingData } from "@/redux/ui/main/actions";
import { SessionManager } from "@/services/SessionManager";
import { apiFetchVendorV1 } from "@/utilities/VendorUtilities";

import Footer from "../shared/Footer";

import "../../scss/components/auth/AuthPage.scss";

const SAML = () => {
  const [nonce] = useQueryParam("nonce");
  const history = useHistory();
  const dispatch = useDispatch();
  useQuery({
    queryKey: ["saml", { nonce }],
    queryFn: async () => {
      const res = await apiFetchVendorV1("/login/nonce", {
        method: "POST",
        body: JSON.stringify({ nonce })
      });
      return await res.json();
    },
    enabled: !!nonce,
    onSuccess: data => {
      dispatch({ type: SET_SESSION_DATA, payload: data });
      SessionManager.setSessionId();
      dispatch(loadingData(false));
      history.push("/apps");
    }
  });

  return (
    <div className="u-width--full u-overflow--auto flex-column">
      <div className="u-flex--mobileoff flex-1-auto u-width--full">
        <div className="AuthIllustration-wrapper">
          <div className="ReplicatedLogoShadow logo-standalone"></div>
        </div>

        <div className="Login-wrapper flex1 flex-column flex-verticalCenter u-paddingBottom--normal alignItems--center auth-pages">
          <p className="form-title u-color--blue u-lineHeight--normal u-paddingLeft--most">
            Logging In...
          </p>
          <div className="Form-wrapper">
            <div className="FormButton-wrapper flex"></div>
          </div>
        </div>
        <div className="auth-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SAML;
