import { Component } from "react";
import * as PropTypes from "prop-types";
import "../../scss/components/shared/Popover.scss";

export default class Popover extends Component {
  static propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool,
    text: PropTypes.string,
    content: PropTypes.node,
    position: PropTypes.string,
    onClick: PropTypes.func,
    minWidth: PropTypes.string
  };

  static defaultProps = {
    position: "bottom-left",
    minWidth: "120"
  };

  render() {
    const { className, visible, text, content, position, onClick, minWidth } =
      this.props;

    const wrapperClass = `Popover-wrapper popover-${position} ${className || ""} ${
      visible ? "is-active" : ""
    }`;

    return (
      <div
        className={wrapperClass}
        style={{ minWidth: `${minWidth}px` }}
        onClick={onClick}
      >
        <div className="Popover-content">{content || text}</div>
      </div>
    );
  }
}
