import { Component } from "react";
import autoBind from "react-autobind";
import Footer from "../shared/Footer";
import { withRouter } from "react-router-dom";
import { VendorUtilities } from "../../utilities/VendorUtilities";

import Modal from "react-modal";
import ModalHeader from "../modals/ModalHeader";
import ModalBody from "../modals/ModalBody";

import "../../scss/components/account/AccountSettings.scss";
import { Button } from "../shared/Button";

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      newPassErr: false,
      newPassMessage: "",
      viewPassword: false,
      resetToken: "",
      resetError: false,
      resetMessage: "",
      resetPasswordEmailAddress: ""
    };
    autoBind(this);
  }

  clearErrors = () => {
    this.setState({
      newPassErr: false,
      newPassMessage: "",
      resetError: false,
      resetMessage: ""
    });
    if (this.props.err) {
      this.props.clearServerError();
    }
    if (this.props.errToken) {
      this.props.clearExpiredTokenError();
    }
  };

  togglVisiblePassword = key => {
    this.setState({
      [`${key}Visible`]: !this.state[`${key}Visible`]
    });
    if (key === "newPassword") {
      this.refNewPassword.focus();
    } else {
      this.refConfirmPassword.focus();
    }
  };

  handleFormChange = (field, e) => {
    if (
      this.state.newPassErr ||
      this.props.err ||
      this.state.resetError ||
      this.props.errToken
    ) {
      this.clearErrors();
    }

    if (this.props.err) {
      this.props.clearServerError();
    }

    if (this.props.errToken) {
      this.props.clearExpiredTokenError();
    }

    let nextState = {};
    nextState[field] = e.target.value;
    this.setState(nextState);
  };

  validateFields = () => {
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        newPassErr: true,
        newPassMessage: "Passwords did not match"
      });
      return false;
    }
    return true;
  };

  buildPayloadObject = () => {
    return {
      reset_token: this.state.resetToken,
      password: this.state.newPassword.trim()
    };
  };

  onSubmit = () => {
    this.clearErrors();
    const canSubmit = this.validateFields();
    const payload = this.buildPayloadObject();
    if (canSubmit) {
      this.props.resetPassword(payload);
    }
  };

  setInitialState = token => {
    if (!token) {
      return;
    }
    this.setState({ resetToken: token });
  };

  componentDidMount() {
    const resetToken = this.props.location.hash.substring(1);
    if (resetToken) {
      this.setInitialState(resetToken);
    }
  }

  resetPassword = () => {
    const payload = {
      email: this.state.resetPasswordEmailAddress
    };
    if (!VendorUtilities.isEmailValid(this.state.resetPasswordEmailAddress)) {
      this.setState({
        resetError: true,
        resetMessage: "Please use a valid email address"
      });
      return false;
    }
    this.props.sendPasswordResetEmail(payload);
  };

  closeResetPassModal = () => {
    this.setState({
      resetPasswordEmailAddress: "",
      resetError: false,
      resetMessage: ""
    });
    this.props.displayModal("forgotPassword", false);
    this.props.setResetPasswordStep("info");
  };

  render() {
    const { dataLoading, modals } = this.props;
    const { newPassword, confirmNewPassword } = this.state;

    return (
      <div className="u-width--full u-overflow--auto flex-column">
        <div className="u-flex--mobileoff flex-1-auto u-width--full">
          <div className="AuthIllustration-wrapper">
            <div className="ReplicatedLogoShadow logo-standalone"></div>
          </div>

          <div className="Login-wrapper flex1 flex-column flex-verticalCenter u-paddingBottom--normal alignItems--center auth-pages">
            {this.props.passwordResetStep === "success" ? (
              <div className="Form-wrapper">
                <p className="form-title u-color--blue u-lineHeight--normal">
                  Reset password
                </p>
                <p className="form-subhead u-fontWeight--normal u-color--dustyGray u-lineHeight--normal u-marginTop--normal">
                  Your password has successfully been reset
                </p>
                <div className="flex u-marginTop--more">
                  <div className="FormButton-wrapper flex">
                    <Button
                      kind="primary-purple"
                      className="flex-auto tw-mt-2.5 tw-mb-5 tw-w-full !tw-text-lg"
                      onClick={() => this.props.history.push("/login")}
                    >
                      Back to login
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="Form-wrapper">
                <p className="form-title u-color--blue u-lineHeight--normal">
                  Reset password
                </p>
                <div>
                  <form>
                    <div className="FormSection-wrapper flex-column flex1">
                      {this.state.newPassErr ? (
                        <div className="ErrorBlock u-marginBottom--normal">
                          <p>{this.state.newPassMessage}</p>
                        </div>
                      ) : null}
                      {this.props.err ? (
                        <div className="ErrorBlock u-marginBottom--small">
                          <p>{this.props.errMessage}</p>
                        </div>
                      ) : null}
                      {this.props.errToken ? (
                        <div className="ErrorBlock u-marginBottom--small">
                          <p>
                            {this.props.errTokenMessage} or
                            <span
                              className="u-color--astral u-fontSize--small u-fontWeight--medium u-textDecoration--underlineOnHover"
                              onClick={() => {
                                this.props.displayModal("forgotPassword", true);
                              }}
                            >
                              {" "}
                              request a new password
                            </span>
                          </p>
                        </div>
                      ) : null}
                      <div className="flex1 u-marginTop--more u-position--relative">
                        <label>New password</label>
                        <div className="u-position--relative">
                          <input
                            className="Input"
                            placeholder="New password"
                            type={this.state.newPasswordVisible ? "text" : "password"}
                            ref={input => (this.refNewPassword = input)}
                            value={newPassword}
                            onChange={e => {
                              this.handleFormChange("newPassword", e);
                            }}
                          />
                          {this.state.newPassword === "" ? null : (
                            <span
                              className="see-pass u-color--astral u-fontSize--small u-fontWeight--medium u-textDecoration--underlineOnHover"
                              onClick={() => this.togglVisiblePassword("newPassword")}
                            >
                              {this.state.newPasswordVisible ? "Hide" : "Show"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex1 u-marginTop--more u-position--relative">
                        <label>Confirm new password</label>
                        <div className="u-position--relative">
                          <input
                            className="Input"
                            placeholder="One more time"
                            type={
                              this.state.confirmNewPasswordVisible ? "text" : "password"
                            }
                            ref={input => (this.refConfirmPassword = input)}
                            value={confirmNewPassword}
                            onChange={e => {
                              this.handleFormChange("confirmNewPassword", e);
                            }}
                          />
                          {this.state.confirmNewPassword === "" ? null : (
                            <span
                              className="see-pass u-color--astral u-fontSize--small u-fontWeight--medium u-textDecoration--underlineOnHover"
                              onClick={() =>
                                this.togglVisiblePassword("confirmNewPassword")
                              }
                            >
                              {this.state.confirmNewPasswordVisible ? "Hide" : "Show"}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="FormButton-wrapper flex">
                      <Button
                        type="button"
                        kind="primary-purple"
                        size="large"
                        className="flex-auto tw-mt-2.5 tw-mb-5 tw-w-full !tw-text-lg"
                        disabled={
                          dataLoading.resettingPasswordLoading ||
                          newPassword === "" ||
                          confirmNewPassword === ""
                        }
                        onClick={this.onSubmit}
                      >
                        {dataLoading.resettingPasswordLoading
                          ? "Resetting"
                          : "Reset Password"}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className="auth-footer">
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          isOpen={modals.forgotPasswordModal}
          contentLabel="Forgot Password Modal"
          ariaHideApp={false}
          className="Modal DefaultSize"
          onRequestClose={this.closeResetPassModal}
        >
          <ModalHeader
            title="Reset your password"
            requestClose={this.closeResetPassModal}
          />
          <ModalBody>
            <div>
              {this.props.resetPasswordStep === "success" ? (
                <div>
                  <p className="u-fontSize--large u-fontWeight--normal u-color--tuna u-lineHeight--normal u-textAlign--center">
                    Check your inbox. If there is an account with the specified address,
                    an email will be sent with instructions for resetting the password.
                  </p>
                  <div className="u-marginTop--more u-textAlign--center">
                    <Button
                      type="button"
                      kind="primary-purple"
                      onClick={this.closeResetPassModal}
                    >
                      Ok, got it!
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="u-marginBottom--normal">
                    <p className="FormLabel u-margin--none">
                      What is your email address?
                    </p>
                    {this.state.resetError ? (
                      <div className="ErrorBlock u-marginBottom--small">
                        <p>{this.state.resetMessage}</p>
                      </div>
                    ) : null}
                    {this.props.err ? (
                      <div className="ErrorBlock u-marginBottom--small">
                        <p>{this.props.errMessage}</p>
                      </div>
                    ) : null}
                    <input
                      className="Input"
                      type="text"
                      placeholder="you@example.com"
                      value={this.state.resetPasswordEmailAddress}
                      onChange={e => {
                        this.handleFormChange("resetPasswordEmailAddress", e);
                      }}
                    />
                  </div>
                  <div className="FormButton-wrapper u-textAlign--right">
                    <Button
                      kind="secondary-gray"
                      type="button"
                      className="tw-mr-3"
                      onClick={this.closeResetPassModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      kind="primary-purple"
                      disabled={
                        this.state.resetPasswordEmailAddress === "" ||
                        dataLoading.passwordResetLoading
                      }
                      onClick={this.resetPassword}
                    >
                      {dataLoading.passwordResetLoading ? "Sending" : "Send email"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(PasswordReset);
