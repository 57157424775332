export const SET_SELECTED_APP = "SET_SELECTED_APP";

function selectedApp(appId) {
  return {
    type: SET_SELECTED_APP,
    payload: appId
  };
}

export function setSelectedApp(appId) {
  return dispatch => {
    dispatch(selectedApp(appId));
  };
}
