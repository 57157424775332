import "isomorphic-fetch";
import { displayModal, loadingData, forbiddenData } from "../../../../ui/main/actions";
import { logOutUser } from "../../../../auth/sessions/actions";
import { SessionManager } from "../../../../../services/SessionManager";

export const SET_CHANNEL_LIST = "SET_CHANNEL_LIST";
export const SET_LEGACY_CHANNEL_LIST = "SET_LEGACY_CHANNEL_LIST";
export const SET_CHANNEL_BRANDING = "SET_CHANNEL_BRANDING";
export const SET_ARCHIVE_ERROR = "SET_ARCHIVE_ERROR";
export const CLEAR_ARCHIVE_ERROR = "CLEAR_ARCHIVE_ERROR";

function setChannelList(channelList) {
  return {
    type: SET_CHANNEL_LIST,
    payload: channelList
  };
}

function setLegacyChannelList(channelList) {
  return {
    type: SET_LEGACY_CHANNEL_LIST,
    payload: channelList
  };
}

function setBranding(branding) {
  return {
    type: SET_CHANNEL_BRANDING,
    payload: branding
  };
}

function setArchiveErr(err) {
  return {
    type: SET_ARCHIVE_ERROR,
    payload: err
  };
}

export function clearArchiveErr() {
  return {
    type: CLEAR_ARCHIVE_ERROR
  };
}

export function getChannelList(appId, isLegacy = false) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("channels", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint(
        isLegacy ? "" : "3"
      )}/app/${appId}/channels`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("channels", false));
          dispatch(forbiddenData("channels", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      // legacy replicated api returns just channels with Id instead of id
      // new api returns channels and enterprise_channels and uses id
      if (isLegacy) {
        dispatch(setLegacyChannelList(body));
      } else {
        dispatch(setChannelList(body.channels));
      }
      dispatch(forbiddenData("channels", false));
      dispatch(loadingData("channels", false));
    } catch (error) {
      dispatch(loadingData("channels", false));
      console.log(error);
      return;
    }
  };
}

export function createNewChannel(appId, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("channelUpdate", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(getBranding(appId));
      if (body.channels) {
        dispatch(setChannelList(body.channels));
      } else {
        dispatch(setLegacyChannelList(body));
      }
      dispatch(loadingData("channelUpdate", false));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("channelUpdate", false));
      return;
    }
  };
}

export function updateChannel(appId, channelId, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("channelUpdate", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel/${channelId}`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(getChannelList(appId, true));
      dispatch(loadingData("channelUpdate", false));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("channelUpdate", false));
      return;
    }
  };
}

export function archiveChannel(appId, channelId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel/${channelId}/archive`;
      response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        const res = await response.text();
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(setArchiveErr(res));
          dispatch(displayModal("permissions", true));
        }

        if (response.status === 500) {
          dispatch(
            setArchiveErr("Unexpected response status code 500, please try again")
          );
        } else {
          dispatch(setArchiveErr(JSON.parse(res).message));
        }
        return;
      }
      dispatch(getChannelList(appId, true));
      dispatch(displayModal("archiveChannel", false));
    } catch (error) {
      console.log(error);
      dispatch(displayModal("archiveChannel", false));
      return;
    }
  };
}

// Branding

export function getBranding(appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("branding", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/branding`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setBranding(body.branding));
      dispatch(loadingData("branding", false));
    } catch (error) {
      dispatch(loadingData("branding", false));
      return;
    }
  };
}

export function createCustomBranding(appId, payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("createBranding", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/branding`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      await response.json();
      dispatch(getBranding(appId));
      dispatch(loadingData("createBranding", false));
    } catch (error) {
      dispatch(loadingData("createBranding", false));
      return;
    }
  };
}

export function updateBranding(appId, payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("updateBranding", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/branding`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      await response.json();
      dispatch(loadingData("updateBranding", false));
      dispatch(getBranding(appId));
    } catch (error) {
      dispatch(loadingData("updateBranding", false));
      return;
    }
  };
}

export function deleteCustomBranding(appId, channelId) {
  return async (dispatch, getState) => {
    dispatch(loadingData("deleteBranding", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/branding?channel_id=${channelId}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("deleteBranding", false));
      dispatch(getBranding(appId));
    } catch (error) {
      dispatch(loadingData("deleteBranding", false));
      return;
    }
  };
}
