import { combineReducers } from "redux";
import apps from "./apps";
import accountSettings from "./account_settings";
import appSettings from "./app_settings";
import auditLogs from "./audit_logs";
import teamsAndTokens from "./teams";
import support from "./support";

export default combineReducers({
  apps,
  accountSettings,
  appSettings,
  auditLogs,
  teamsAndTokens,
  support
});
