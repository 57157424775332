import { connect } from "react-redux";
import realAcceptInvite from "../auth/AcceptInvite";

import {
  getInvite,
  handleInviteSignUp,
  clearInviteError,
  setLoadingToFalse,
  handleGoogleInviteAccept,
  logOutUser,
  clearSignupValidationErrors
} from "../../redux/auth/sessions/actions";

const AcceptInvite = connect(
  state => ({
    modals: state.ui.main.modals,
    errMessage: state.auth.sessions.sessionData.errMessage,
    accessToken: state.auth.sessions.sessionData.accessToken,
    inviteError: state.auth.sessions.sessionData.inviteError,
    invite: state.auth.sessions.sessionData.invite,
    team: state.auth.sessions.sessionData.team,
    needsActivation: state.auth.sessions.sessionData.needsActivation,
    codeWasResent: state.auth.sessions.sessionData.codeWasResent,
    hasToken: state.auth.sessions.sessionData.accessToken,
    dataLoading: state.ui.main.loading,
    user: state.auth.sessions.sessionData.user
  }),
  dispatch => ({
    getInvite(inviteId) {
      return dispatch(getInvite(inviteId));
    },
    handleInviteSignUp(payload, router, inviteId, callback) {
      return dispatch(handleInviteSignUp(payload, router, inviteId, callback));
    },
    clearInviteError() {
      return dispatch(clearInviteError());
    },
    setLoadingToFalse() {
      return dispatch(setLoadingToFalse());
    },
    handleGoogleInviteAccept(inviteId, replaceAccount) {
      return dispatch(handleGoogleInviteAccept(inviteId, replaceAccount));
    },
    logOutUser() {
      return dispatch(logOutUser());
    },
    clearSignupValidationErrors() {
      return dispatch(clearSignupValidationErrors());
    }
  })
)(realAcceptInvite);

export default AcceptInvite;
