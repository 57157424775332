// @ts-strict
import { QueryKey, useQuery } from "@tanstack/react-query";
import jsonStableStringify from "json-stable-stringify";
import { isEmpty } from "lodash-es";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch as useUntypedDispatch } from "react-redux";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import * as R from "remeda";

import { useAppState } from "@/context/app-state";
import { adminFeatureFlags, futureFeatureFlags } from "@/hooks/use-flag";
import { logOutUser } from "@/redux/auth/sessions/actions";
import { getAppList } from "@/redux/data/main/apps/actions";
import { getTeam, listTeamEntitlements } from "@/redux/data/main/teams/actions";
import { getKotsAppList } from "@/redux/kots/app/actions";
import { getKotsAppChannels } from "@/redux/kots/channels/actions";
import {
  getActiveKotsReleases,
  getKotsReleaseList
} from "@/redux/kots/releases/actions";
import {
  Channel,
  CustomerSearchResponse,
  DataLoading,
  FeatureValue,
  FeaturesResponse,
  KotsApp,
  KotsRelease,
  KotsReleasesResponse,
  PlatformApp,
  Team,
  TeamEntitlements
} from "@/types";
import { apiFetchVendorV1, apiFetchVendor } from "@/utilities/VendorUtilities";
import { useQueryParam } from "use-query-params";

export const useDispatch = (): ThunkDispatch<any, any, AnyAction> => {
  return useUntypedDispatch();
};

type SetQuery = <QK>(params: {
  stableKey: string;
  queryFn: (params: { queryKey: QK }) => Promise<any>;
  enabled: boolean;
  networkMode: "always" | "online";
}) => void;

interface ReduxQueryContextValue {
  setQuery: SetQuery;
  refetch: (stableKey: string) => void;
}

const ReduxQueryContext = createContext<ReduxQueryContextValue>(
  {} as ReduxQueryContextValue
);

interface Queries {
  [key: string]: {
    queryFn: any;
    lastRunAt: number;
    networkMode: "always" | "online";
  };
}

export const ReduxQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [queue, setQueue] = useState(() => [] as string[]);
  const [queries, setQueries] = useState<Queries>({} as Queries);

  useEffect(() => {
    if (!queue.length) {
      return;
    }
    queue.forEach(key => {
      const queryObj = queries[key];
      if (!queryObj) {
        return;
      }
      const { queryFn, lastRunAt, networkMode } = queryObj;
      const now = Date.now();
      // don't run more than once every 30 seconds
      // call refetch after mutations
      if (networkMode !== "always" && now - lastRunAt < 1000 * 30) {
        return;
      }
      queryFn({ queryKey: JSON.parse(key) });
      setQueries(queries => ({
        ...queries,
        [key]: { ...queries[key], lastRunAt: now }
      }));
    });
    setQueue([]);
  }, [queue]);

  const setQuery: SetQuery = ({ stableKey, queryFn, enabled, networkMode }) => {
    setQueries(state => {
      if (state[stableKey]) {
        return state;
      }
      return { ...state, [stableKey]: { queryFn, lastRunAt: 0, networkMode } };
    });
    setQueue(state => {
      if (
        (enabled && state.includes(stableKey)) ||
        (!enabled && !state.includes(stableKey))
      ) {
        return state;
      }
      return enabled
        ? R.uniq([...state, stableKey])
        : R.reject(state, key => key === stableKey);
    });
  };

  const refetch = (stableKey: string) => {
    setQueries(state => ({
      ...state,
      [stableKey]: { ...state[stableKey], lastRunAt: 0 }
    }));
    setQueue(state => R.uniq([...state, stableKey]));
  };

  const contextValue = {
    setQuery,
    refetch
  };

  return (
    <ReduxQueryContext.Provider value={contextValue}>
      {children}
    </ReduxQueryContext.Provider>
  );
};

interface UseReduxQueryParams<QK> {
  queryKey: QK;
  queryFn: (params: { queryKey: QK }) => Promise<any>;
  enabled?: boolean;
  networkMode?: "always" | "online";
}

const useReduxQuery = <QK extends QueryKey>({
  queryKey,
  queryFn,
  enabled = true,
  // "always" always runs `queryFn`, "online" only runs once per queryKey per 30 seconds.
  // React Query defaults to "online", but our redux store doesn't store values keyed by
  // params - each dispatch generally overwrites the last, so "always" is safer. Only use
  // "online" for queries that are consistent for the entire team, like `teams`, `apps`,
  // and `teamEntitlements`. If a query is causing unexpected referesh/re-renders, see if
  // it's possible to use "online". Only these two modes are supported.
  //
  // In case of a mutation that would change the result of a query, call `refetch`.
  networkMode = "always"
}: UseReduxQueryParams<QK>) => {
  const { isAuthenticated } = useAppState();
  const { setQuery, refetch } = useContext(ReduxQueryContext);
  const stableKey = jsonStableStringify(queryKey);

  useEffect(() => {
    setQuery<QK>({
      stableKey,
      queryFn,
      enabled: isAuthenticated && enabled,
      networkMode
    });
  }, [stableKey, enabled, isAuthenticated]);
  return {
    refetch: () => refetch(stableKey)
  };
};

/**
 * A hook that retrieves a list of KOTS apps for the current user's team.
 *
 * @example
 * const kotsApps = useKotsApps();
 * const app = kotsApps?.find(app => app.id === appId);
 */
export const useKotsApps = () => {
  const dispatch = useDispatch();
  useReduxQuery({
    queryKey: ["kotsApps"],
    queryFn: () => dispatch(getKotsAppList()),
    networkMode: "online"
  });
  return useSelector<any, KotsApp[]>((state: any) => state.kots.apps.kotsApps);
};

/**
 * A react-query custom hook that retrieves the current user's team.
 */
export const useTeam = () => {
  const dispatch = useDispatch();
  useReduxQuery({
    queryKey: ["team"],
    queryFn: () => dispatch(getTeam()),
    networkMode: "online"
  });
  return useSelector<any, Team>(
    (state: any) => state.data.main.teamsAndTokens.teamsData.team
  );
};

export const useTeamEntitlementsQuery = () => {
  const dispatch = useDispatch();
  const { refetch } = useReduxQuery({
    queryKey: ["teamEntitlements"],
    queryFn: () => dispatch(listTeamEntitlements()),
    networkMode: "online"
  });
  const data = useSelector<any, TeamEntitlements>(
    (state: any) => state.data.main.teamsAndTokens.teamsData.teamEntitlements
  );
  const isLoading = useSelector<any, DataLoading>(
    (state: any) => state.ui.main.loading
  ).listTeamEntitlementsLoading;
  const isSuccess = !isEmpty(data);
  return { data, isLoading, isSuccess, refetch };
};

/**
 * A react-query custom hook that retrieves the current user's team entitlements.
 */
export const useTeamEntitlements = () => {
  const { data } = useTeamEntitlementsQuery();
  return data;
};

/**
 * A react-query custom hook that retrieves Platform apps for the current user's team.
 *
 * @returns object with keys:
 * - `platformApps`: an object of Platform apps for the current user's team, keyed by id
 * - `platformAppsList`: a list of id's of the Platform apps for the current user's team
 */
export const usePlatformAppsQuery = () => {
  const dispatch = useDispatch();
  useReduxQuery({
    queryKey: ["platformApps"],
    queryFn: () => dispatch(getAppList()),
    networkMode: "online"
  });
  const platformApps = useSelector<any, { [id: string]: PlatformApp }>(
    (state: any) => state.data.main.apps.apps
  );
  const platformAppsList = useSelector<any, string[]>(
    (state: any) => state.ui.main.apps.appList
  );
  const platformAppsLoaded = useSelector<any, boolean>(
    (state: any) => state.data.main.apps.appsLoaded.platform
  );
  const data = { platformApps, platformAppsList };
  return { data, isSuccess: platformAppsLoaded };
};

/**
 * A react-query custom hook that retrieves the current app's channels.
 */
export const useAppChannels = () => {
  const { currentAppId } = useAppState();
  const dispatch = useDispatch();
  useReduxQuery({
    queryKey: ["kotsAppChannels", currentAppId] as const,
    queryFn: ({ queryKey }) => dispatch(getKotsAppChannels(queryKey[1], false)),
    enabled: !!currentAppId
  });
  return useSelector<any, Channel[]>((state: any) => state.kots.channels.appChannels);
};

/**
 * A react-query custom hook that logs the current user out.
 */
export const useLogOutUserMutation = () => {
  const dispatch = useDispatch();
  const mutate = async (removeNextLink: boolean) => {
    try {
      dispatch(logOutUser(removeNextLink));
    } catch (error) {
      return;
    }
  };
  return { mutate };
};

/**
 * A react-query custom hook that retrieves the current user's team feature values.
 */
export const useFeatureValues = () => {
  const { isAuthenticated } = useAppState();

  const fetchFeatures = async (): Promise<FeaturesResponse> => {
    const response = await apiFetchVendorV1("/user/features", {
      enableErrorHandling: false
    });
    if (!response.ok) {
      return { features: [], futureFeatures: [] };
    }
    return response.json();
  };

  const { data } = useQuery({
    queryKey: ["features"],
    queryFn: fetchFeatures,
    enabled: isAuthenticated,
    initialData: { features: [], futureFeatures: [] },
    select: data => {
      const features: Partial<Record<keyof typeof adminFeatureFlags, string>> = (
        data?.features || []
      ).reduce((acc, { Key, Value }: FeatureValue) => {
        return { ...acc, [Key]: Value };
      }, {});
      const futureFeatures: Partial<Record<keyof typeof futureFeatureFlags, boolean>> =
        (data?.futureFeatures || []).reduce((acc, feature) => {
          return { ...acc, [feature]: true };
        }, {});
      return { features, futureFeatures };
    }
  });
  return data;
};

export const useKotsReleaseQuery = (appId: string, releaseSequence: string) => {
  const shouldFetch = !!releaseSequence && !isNaN(Number(releaseSequence));

  const query = useQuery<{ release: KotsRelease }, Error, KotsRelease>({
    queryKey: ["getKotsRelease", releaseSequence, appId],
    queryFn: async ({ queryKey }): Promise<{ release: KotsRelease }> => {
      const releaseSequence = Number(queryKey[1]);
      const appId = queryKey[2];
      const path = `/app/${appId}/release/${releaseSequence}`;
      return (await apiFetchVendor(path)).json();
    },
    select: data => data?.release,
    enabled: shouldFetch
  });
  if (!shouldFetch) {
    return {
      data: null,
      error:
        // check if it's not a valid release sequence && it's also not an empty string (not a draft)
        isNaN(Number(releaseSequence)) && releaseSequence
          ? new Error(
              "Release not found. It could be that you don't have access or that you've tried to access a page that doesn't exist. Please try again or contact your team administrator to validate your privileges."
            )
          : null,
      isLoading: false,
      refetch: () => {}
    };
  }

  return query;
};

/**
 * A react-query custom hook that retrieves the releases for an app.
 * @param appId - the id of the app
 * @param startIndex - the index to start at for pagination
 * @param pageCount - how many releases to fetch for pagination
 * @returns result partial {@link UseQueryResult}
 * @example
 * const { data } = useKotsReleasesQuery(appId);
 */
export const useKotsReleasesQuery = (appId: string, startIndex = 0, pageCount = 20) => {
  const dispatch = useDispatch();
  const { refetch } = useReduxQuery({
    queryKey: ["kotsReleases", appId, startIndex, pageCount] as const,
    queryFn: ({ queryKey }) => {
      return dispatch(getKotsReleaseList(queryKey[1], queryKey[2], queryKey[3]));
    },
    enabled: !!appId
  });
  const data: KotsReleasesResponse = {
    releases: useSelector<any, KotsReleasesResponse["releases"]>(
      (state: any) => state.kots.releases.kotsReleases
    ),
    approximateTotalCount: useSelector<
      any,
      KotsReleasesResponse["approximateTotalCount"]
    >((state: any) => state.kots.releases.approximateTotalCount)
  };
  const isLoading = useSelector<any, DataLoading>(
    (state: any) => state.ui.main.loading
  ).kotsReleasesLoading;
  return { data, isLoading, refetch };
};

/**
 * A react-query custom hook that retrieves the active releases for an app.
 * @param appId - the id of the app
 * @returns result partial {@link UseQueryResult}
 * @example
 * const { data } = useActiveKotsReleasesQuery(appId);
 */
export const useActiveKotsReleasesQuery = (appId: string) => {
  const dispatch = useDispatch();
  const { refetch } = useReduxQuery({
    queryKey: ["activeKotsReleases", appId] as const,
    queryFn: ({ queryKey }) => dispatch(getActiveKotsReleases(queryKey[1])),
    enabled: Boolean(appId)
  });
  const data = useSelector<any, KotsReleasesResponse["releases"]>(
    (state: any) => state.kots.releases.activeKotsReleases
  );
  const isLoading = useSelector<any, DataLoading>(
    (state: any) => state.ui.main.loading
  ).activeKotsReleasesLoading;
  return { data, isLoading, refetch };
};

export const useLatestReplicatedSDKQuery = () => {
  return useQuery({
    queryKey: ["latestReplicatedSDKVersion"],
    queryFn: async (): Promise<{ version: string }> => {
      const path = `/replicated-sdk-version?strict=true`;
      return (await apiFetchVendor(path)).json();
    }
  });
};

interface CustomerSearchQueryParams {
  appId: string;
  query?: string;
  pageSize?: number;
  page?: number;
  includeActive?: boolean;
  includeInactive?: boolean;
  includeArchived?: boolean;
  includeLicenseTypes?: string[];
  channelNames: (string | null)[];
  sortField?: string;
  sortDirection?: string;
  adoptionRateFilter?: string;
}

/**
 * A react-query custom hook that retrieves a list of customers for a given search query.
 *
 * @param config - a {@link CustomerSearchQueryParams} object, configures the query
 * @param config.appId - the id of the app
 * @param config.query - the search query
 * @param config.pageSize - the number of results to return per page
 * @param config.page - the page number to return
 * @param config.includeActive - whether to include active customers
 * @param config.includeInactive - whether to include inactive customers
 * @param config.includeArchived - whether to include archived customers
 * @param config.includeLicenseTypes - the license types to include
 * @param config.channelNames - the channel names to include
 * @param config.sortField - the field to sort by
 * @param config.sortDirection - the direction to sort by
 * @returns result {@link UseQueryResult}
 */
export const useCustomerSearchQuery = ({
  appId: appIdParam,
  query: queryParam = "",
  pageSize: pageSizeParam = 20,
  page: pageParam = 0,
  includeActive: includeActiveParam = true,
  includeInactive: includeInactiveParam = true,
  includeArchived: includeArchivedParam = false,
  adoptionRateFilter: adoptionRateFilterParam,
  includeLicenseTypes: includeLicenseTypesParam = [],
  channelNames: channelNamesParam,
  sortField: sortFieldParam = "createdAt",
  sortDirection: sortDirectionParam = "desc"
}: CustomerSearchQueryParams) => {
  const { isAuthenticated } = useAppState();
  const [sortParam] = useQueryParam("sort");
  // Convert to objects to ensure that the query key is stable,
  // and filter for valid values
  const stableLicenseTypes = includeLicenseTypesParam.flatMap(value => {
    if (!value) {
      return [];
    }
    if (!["dev", "trial", "paid", "community"].includes(value)) {
      console.error(`Search: Invalid license type "${value}", ignoring`);
      return [];
    }
    return { value };
  });
  const stableChannelNames = channelNamesParam.flatMap(value => {
    if (!value) {
      return [];
    }
    return { value };
  });
  const validSortDirection = sortDirectionParam === "asc" ? "asc" : "desc";
  return useQuery({
    queryKey: [
      "customersBySearch",
      appIdParam,
      { query: queryParam, pageSize: pageSizeParam, page: pageParam },
      {
        active: includeActiveParam,
        inactive: includeInactiveParam,
        archived: includeArchivedParam,
        licenseTypes: stableLicenseTypes,
        channelNames: stableChannelNames,
        adoptionRateFilter: adoptionRateFilterParam
      },
      {
        sortField: sortFieldParam,
        sortDirection: validSortDirection
      }
    ] as const,
    queryFn: async ({ queryKey }): Promise<CustomerSearchResponse> => {
      const appId = queryKey[1];
      const { query, pageSize, page } = queryKey[2];
      const {
        active,
        inactive,
        archived,
        licenseTypes,
        channelNames,
        adoptionRateFilter
      } = queryKey[3];
      const { sortField, sortDirection } = queryKey[4];
      const isIncluded = (value: string, arr: { value: string }[]) => {
        if (arr.length === 0) {
          return true;
        }
        return arr.some(({ value: arrValue }) => value === arrValue);
      };
      const response = await apiFetchVendor("/customers/search", {
        method: "POST",
        body: JSON.stringify({
          app_id: appId,
          query,
          offset: page > 0 ? pageSize * page : 0,
          page_size: pageSize,
          channel_names: channelNames.map(({ value }) => value),
          sort_field: sortField,
          sort_direction: sortDirection,
          include_dev: isIncluded("dev", licenseTypes),
          include_trial: isIncluded("trial", licenseTypes),
          include_paid: isIncluded("paid", licenseTypes),
          include_community: isIncluded("community", licenseTypes),
          include_active: active || !inactive,
          include_inactive: inactive || !active,
          include_archived: archived,
          instance_preview: true,
          ...(adoptionRateFilter ? { adoption_rate_filter: adoptionRateFilter } : {})
        })
      });
      return response?.json();
    },
    cacheTime: 0,
    keepPreviousData: true,
    // Only enable the query if the user is authenticated and all required params are present
    // We check for sortParam here because we need to wait for the sort query param to be set after we fetch the user's customer table settings
    enabled: isAuthenticated && !!appIdParam && !!sortParam
  });
};
