import { useEffect, useState } from "react";

const useTimeout = () => {
  const [timeouts, setTimeouts] = useState<ReturnType<typeof setTimeout>[]>([]);

  useEffect(() => {
    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, [timeouts]);

  return (callback: () => void, delay: number) => {
    setTimeouts(prev => [...prev, setTimeout(callback, delay)]);
  };
};

export default useTimeout;
