import "isomorphic-fetch";
import { SessionManager } from "../../../../services/SessionManager";
import { loadingData, displayModal, forbiddenData } from "../../../ui/main/actions";
import { logOutUser } from "../../../auth/sessions/actions";

export const SET_APP_SETTINGS_EXTERNAL_REGISTRIES =
  "SET_APP_SETTINGS_EXTERNAL_REGISTRIES";
export const SET_APP_SETTINGS_INTEGRATIONS = "SET_APP_SETTINGS_INTEGRATIONS";
export const SET_REGISTRY_ERROR = "SET_REGISTRY_ERROR";
export const CLEAR_REGISTRY_ERROR = "CLEAR_REGISTRY_ERROR";
export const SET_INTEGRATION_ERROR = "SET_INTEGRATION_ERROR";
export const CLEAR_INTEGRATION_ERROR = "CLEAR_INTEGRATION_ERROR";
export const SET_VALIDATE_PASSWORD_ERROR = "SET_VALIDATE_PASSWORD_ERROR";
export const CLEAR_VALIDATE_PASSWORD_ERROR = "CLEAR_VALIDATE_PASSWORD_ERROR";

export function setExternalRegistries(registries) {
  return {
    type: SET_APP_SETTINGS_EXTERNAL_REGISTRIES,
    payload: registries
  };
}

function setIntegrations(integrations) {
  return {
    type: SET_APP_SETTINGS_INTEGRATIONS,
    payload: integrations
  };
}

export function getAppSettings(appId) {
  return dispatch => {
    dispatch(getExternalRegistries(appId));
    dispatch(getIntegrations(appId));
  };
}

// External Registries

function setRegistryErr(err) {
  return {
    type: SET_REGISTRY_ERROR,
    payload: err
  };
}

export function clearRegistryErr() {
  return {
    type: CLEAR_REGISTRY_ERROR
  };
}

function getExternalRegistries(appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("externalRegistries", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/externalregistries`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(loadingData("externalRegistries", false));
          dispatch(forbiddenData("externalRegistries", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setExternalRegistries(body));
      dispatch(forbiddenData("externalRegistries", false));
      dispatch(loadingData("externalRegistries", false));
    } catch (error) {
      dispatch(loadingData("externalRegistries", false));
      return;
    }
  };
}

export function updateExternalRegistry(appId, payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("updatingRegistry", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/externalregistry`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        dispatch(loadingData("updatingRegistry", false));
        const res = await response.text();
        if (response.status === 403) {
          if (response.statusText === "Forbidden") {
            dispatch(setRegistryErr(res));
          } else {
            dispatch(displayModal("editRegistry", false));
            dispatch(displayModal("permissions", true));
          }
        }
        if (response.status === 500) {
          dispatch(
            setRegistryErr("Unexpected response status code 500, please try again")
          );
        } else {
          dispatch(setRegistryErr(res));
        }
        return;
      }
      await response.json();
      dispatch(loadingData("updatingRegistry", false));
      dispatch(displayModal("editRegistry", false));
      dispatch(getExternalRegistries(appId));
    } catch (error) {
      dispatch(loadingData("updatingRegistry", false));
      return;
    }
  };
}

export function deleteExternalRegistry(appId, registryName) {
  return async (dispatch, getState) => {
    let response;
    const encodedRegName = encodeURIComponent(registryName);
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/externalregistry/${encodedRegName}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(displayModal("confirmRemoveRegistry", false));
      dispatch(getExternalRegistries(appId));
    } catch (error) {
      return;
    }
  };
}

// Integrations

function setIntegrationErr(err) {
  return {
    type: SET_INTEGRATION_ERROR,
    payload: err
  };
}

function getIntegrations(appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("integrations", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/integrations`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(loadingData("integrations", false));
          dispatch(forbiddenData("integrations", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setIntegrations(body));
      dispatch(loadingData("integrations", false));
    } catch (error) {
      dispatch(loadingData("integrations", false));
      return;
    }
  };
}

export function connectGithubIntegration(appId) {
  return async (dispatch, getState) => {
    const origin = `${
      window.location.hostname === "localhost"
        ? `${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ""
          }`
        : `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ""
          }`
    }`;
    const next = `${origin}${window.location.pathname}${window.location.search}`;
    const payload = {
      app_id: appId,
      next: next
    };
    dispatch(loadingData("connectingGithub", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/integration/github/authorize`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        dispatch(loadingData("connectingGithub", false));
        const res = await response.text();
        dispatch(setIntegrationErr(`Error requesting Github integration: ${res}`));
      }
      const body = await response.json();
      window.location.href = body.redirect_url;
    } catch (error) {
      dispatch(loadingData("connectingGithub", false));
      return;
    }
  };
}

export function disconnectGithubIntegration(appId) {
  return async (dispatch, getState) => {
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/integration/github`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(displayModal("confirmRemoveGithub", false));
      dispatch(getIntegrations(appId));
    } catch (error) {
      return;
    }
  };
}

// Delete application

export function deleteApplication(appId, payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("deleteApp", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}`;
      response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("confirmDeleteApp", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("deleteApp", false));
      dispatch(displayModal("confirmDeleteApp", false));
      window.location.replace("/apps");
    } catch (error) {
      dispatch(loadingData("deleteApp", false));
      return;
    }
  };
}

// Validate password on deleting application

function setValidatePasswordError(err) {
  return {
    type: SET_VALIDATE_PASSWORD_ERROR,
    payload: err
  };
}

export function clearValidatePasswordError() {
  return {
    type: CLEAR_VALIDATE_PASSWORD_ERROR
  };
}

export function validatePassword(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("validatePassword", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/password/validate`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("validatePassword", false));
    } catch (err) {
      const error = await response.json();
      dispatch(loadingData("validatePassword", false));
      dispatch(setValidatePasswordError(error));
      throw new Error(`Unexpected response status code ${response.status}`);
    }
  };
}
