import type { Action } from "@/types";
import { SHOW_SAVE_TEAM_TOAST } from "./actions";

const initialState = {
  showSavedTeamToast: false
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SHOW_SAVE_TEAM_TOAST:
      return Object.assign({}, state, {
        showSavedTeamToast: action.payload
      });
    default:
      return state;
  }
}
