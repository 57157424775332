import { ChangeEvent, SyntheticEvent, useEffect, useReducer, useRef } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Modal from "react-modal";

import { VendorUtilities } from "@/utilities/VendorUtilities";
import { DataLoading, User } from "@/types";
import Footer from "../shared/Footer";
import ModalHeader from "../modals/ModalHeader";
import ModalBody from "../modals/ModalBody";
import ReplicatedInfo from "./ReplicatedInfo";

import "@/scss/components/auth/AuthPage.scss";
import { Button } from "../shared/Button";

interface RouteParams {
  next: string;
}

interface VerifySignupProps extends RouteComponentProps<RouteParams> {
  accessToken?: string;
  clearServerError: () => void;
  dataLoading: DataLoading;
  displayModal: (modalName: string, display: boolean) => void;
  err: boolean;
  errMessage: string;
  handleSubmitActivationCode: (
    payload: { token: string },
    history: RouteComponentProps["history"]
  ) => void;
  hasToken?: string;
  logOutUser: () => void;
  needsActivation: boolean;
  setLoadingToFalse: () => void;
  user: User;
}

interface VerifySignupState {
  needsActivation?: boolean;
  activationCode?: string;
  isTrialClosed?: boolean;
  resetError?: boolean;
  resetMessage?: string;
  activationCodeEmptyError?: boolean;
}

const VerifySignup = ({
  accessToken,
  clearServerError,
  dataLoading,
  displayModal,
  err,
  errMessage,
  handleSubmitActivationCode,
  hasToken,
  history,
  location,
  logOutUser,
  match,
  needsActivation,
  setLoadingToFalse,
  user
}: VerifySignupProps) => {
  const [state, setState] = useReducer(
    (prevState: VerifySignupState, newState: VerifySignupState) => ({
      ...prevState,
      ...newState
    }),
    {
      needsActivation: false,
      activationCode: "",
      isTrialClosed: false,
      activationCodeEmptyError: false
    }
  );
  const refActivationCode = useRef(null);

  const handleClearErrors = () => {
    if (state.resetError || state.activationCodeEmptyError) {
      setState({
        resetError: false,
        resetMessage: "",
        activationCodeEmptyError: false
      });
    }
    if (err) {
      clearServerError();
    }
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleClearErrors();

    setState({
      activationCode: e.target.value
    });
  };

  const closeTrialExpiredModal = () => {
    setState({
      isTrialClosed: true
    });
    displayModal("trialExpired", false);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    handleClearErrors();

    const payload = {
      token: state.activationCode.trim()
    };
    handleSubmitActivationCode(payload, history);
  };

  useEffect(() => {
    clearServerError();
    const activationCode = location.hash.slice(1);
    if (!activationCode && refActivationCode.current) {
      refActivationCode.current.focus();
    }

    // if there is an activation token
    if (activationCode) {
      setState({
        activationCode: activationCode,
        needsActivation: true
      });
    } else {
      // if we are logged in to an existing account, log out. This is for google auth
      if (accessToken?.length > 0) {
        logOutUser();
        return;
      }
    }

    VendorUtilities.purgeVOneData();
    const { next } = match.params;

    // if user is logged in and already activated, redirect to the next page
    if (!!hasToken && user?.id && (!needsActivation || !state.needsActivation)) {
      history.push(`/${next || "apps"}`);
      return;
    }
    setLoadingToFalse();
  }, []);

  const userNeedsActivation = needsActivation || state.needsActivation;

  // if user is already logged in and activated
  // we return nothing while we wait for our useEffect to redirect them
  if (!!hasToken && user?.id && !userNeedsActivation) return null;

  const userAlreadyExists = errMessage === "User already exists";

  return (
    <div className="u-width--full u-overflow--auto flex-column">
      <div className="u-flexTabletReflowReverse flex-1-auto u-width--full">
        <ReplicatedInfo needsActivation={true} />
        <div className="Login-wrapper flex1 flex-column flex-verticalCenter alignItems--center u-paddingBottom--normal u-textAlign--left auth-pages">
          <h1 className="Form-wrapper tw-text-indigo-500 !tw-mt-0 lg:!tw-mt-[70px] tw-text-2xl lg:tw-text-3xl">
            Activate account
          </h1>
          <div className="Form-wrapper">
            <p className="form-subhead u-fontSize--large u-fontWeight--normal u-marginTop--normal">
              {!userAlreadyExists &&
                "We sent an activation code to the email address you signed up with. Copy and paste that code below to continue."}
              {userAlreadyExists &&
                "A user already exists for this email address. Please visit the log in page to log in or reset your password."}
            </p>
            {userAlreadyExists && (
              <Link
                to="/login"
                className="u-color--blue hover:tw-underline tw-font-semibold tw-mt-5 tw-block"
              >
                Go to Log In
              </Link>
            )}
            {!userAlreadyExists && (
              <form onSubmit={handleSubmit}>
                <div className="FormSection-wrapper">
                  {err && (
                    <div className="ErrorBlock u-marginBottom--small">
                      <p>{errMessage}</p>
                    </div>
                  )}
                  {state.activationCodeEmptyError && (
                    <div className="ErrorBlock u-marginBottom--small">
                      <p>Activation code cannot be blank</p>
                    </div>
                  )}
                  <label htmlFor="activationCode" className="u-display--none">
                    Activation code
                  </label>
                  <input
                    id="activationCode"
                    className="Input"
                    type="text"
                    ref={refActivationCode}
                    placeholder="Activation code"
                    value={state.activationCode}
                    onChange={handleFormChange}
                  />
                </div>
                <div className="FormButton-wrapper tw-flex tw-flex-wrap tw-mb-8 tw-gap-4">
                  <Button
                    type="submit"
                    kind="primary-purple"
                    size="large"
                    className=""
                    disabled={dataLoading.activationCodeLoading}
                  >
                    {dataLoading.activationCodeLoading ? "Activating" : "Activate"}
                  </Button>
                  <div className="flex-auto u-fontSize--normal flex-verticalCenter flex-column u-position--relative">
                    <p className="u-color--dustyGray">
                      Didn't get a code?{" "}
                      <Link
                        to="/signup"
                        className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                      >
                        Request another
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="auth-footer">
            <Footer />
          </div>
        </div>
      </div>
      <Modal
        isOpen={!state.isTrialClosed && errMessage === "Trial expired"}
        contentLabel="Trial Expired Modal"
        ariaHideApp={false}
        className="auth-pages Modal DefaultSize"
        onRequestClose={closeTrialExpiredModal}
      >
        <ModalHeader title="Trial Expired" requestClose={closeTrialExpiredModal} />
        <ModalBody>
          <div>
            Your team's free trial has ended. Please contact us at{" "}
            <a
              href="mailto:contact@replicated.com"
              target="_blank"
              rel="noopener noreferrer"
              className="u-color--astral u-fontWeight--medium u-textDecoration--underlineOnHover"
            >
              contact@replicated.com{" "}
            </a>
            if you need an extension.
            <div className="FormButton-wrapper u-textAlign--right">
              <Button
                type="button"
                kind="secondary-gray"
                className="tw-mr-3 tw-mt-3"
                onClick={closeTrialExpiredModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withRouter(VerifySignup);
