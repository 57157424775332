import { connect } from "react-redux";
import realReplRedirect from "../shared/ReplRedirect";

import { getAppList } from "../../redux/data/main/apps/actions";
import { getKotsAppList } from "../../redux/kots/app/actions";

const ReplRedirect = connect(
  state => ({
    apps: state.data.main.apps.apps,
    appsList: state.ui.main.apps.appList,
    appsLoaded: state.data.main.apps.appsLoaded,
    kotsApps: state.kots.apps.kotsApps
  }),
  dispatch => ({
    getAppList() {
      return dispatch(getAppList());
    },
    getKotsAppList() {
      return dispatch(getKotsAppList());
    }
  })
)(realReplRedirect);

export default ReplRedirect;
