import styled from "styled-components";

export const FormSection = styled.div`
  padding: 20px 0;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CenteredRow = styled(Row)`
  justify-content: center;
`;

export const CenteredOnPage = styled.div`
  display: flex;
  margin: auto;
`;
