import "isomorphic-fetch";

import {
  loadingData,
  forbiddenData,
  errorData,
  displayModal
} from "../../ui/main/actions";
import { logOutUser } from "../../auth/sessions/actions";
import { SessionManager } from "../../../services/SessionManager";
import { apiFetchVendor, gzipData } from "@/utilities/VendorUtilities";
import { gzip } from "pako";
import { Buffer } from "buffer";

export const SET_KOTS_RELEASE_LIST = "SET_KOTS_RELEASE_LIST";
export const SET_ACTIVE_KOTS_RELEASE_LIST = "SET_ACTIVE_KOTS_RELEASE_LIST";
export const SET_KOTS_START_INDEX_AND_PAGE_COUNT =
  "SET_KOTS_START_INDEX_AND_PAGE_COUNT";
export const SET_LATEST_KOTS_RELEASE = "SET_LATEST_KOTS_RELEASE";
export const SET_PROMOTE_ERROR_LINT_EXPRESSIONS = "SET_PROMOTE_ERROR_LINT_EXPRESSIONS";
export const SET_PROMOTE_ERROR_CHANNEL = "SET_PROMOTE_ERROR_CHANNEL";
export const SET_PROMOTE_ERROR = "SET_PROMOTE_ERROR";
export const SET_DRAFT_RELEASE = "SET_DRAFT_RELEASE";
export const SET_VIEW_MODE = "SET_VIEW_MODE";

function setKotsReleaseList(releaseList) {
  return {
    type: SET_KOTS_RELEASE_LIST,
    payload: {
      releases: releaseList.releases,
      approximateTotalCount: releaseList.approximateTotalCount
    }
  };
}

function setActiveKotsReleases(activeReleases) {
  return {
    type: SET_ACTIVE_KOTS_RELEASE_LIST,
    payload: {
      activeReleases: activeReleases.releases
    }
  };
}

function setLatestKotsReleaseToState(release) {
  return {
    type: SET_LATEST_KOTS_RELEASE,
    payload: {
      release: release
    }
  };
}

function setPromoteErrorLintExpressions(lintExpressions) {
  return {
    type: SET_PROMOTE_ERROR_LINT_EXPRESSIONS,
    payload: {
      lintExpressions
    }
  };
}

function setPromoteErrorChannel(channel) {
  return {
    type: SET_PROMOTE_ERROR_CHANNEL,
    payload: {
      channel
    }
  };
}

function setPromoteError(message) {
  return {
    type: SET_PROMOTE_ERROR,
    payload: message
  };
}

function setDraftRelease(draftRelease) {
  return {
    type: SET_DRAFT_RELEASE,
    payload: { draftRelease, viewMode: "draft" }
  };
}

export function setViewMode(viewMode) {
  return {
    type: SET_VIEW_MODE,
    payload: viewMode
  };
}

export function getActiveKotsReleases(appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("activeKotsReleases", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("3")}/app/${appId}/releases/active`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        },
        mode: "cors"
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("activeKotsReleases", false));
          dispatch(forbiddenData("activeKotsReleases", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setActiveKotsReleases(body));
      dispatch(forbiddenData("activeKotsReleases", false));
      dispatch(loadingData("activeKotsReleases", false));
      dispatch(errorData("activeKotsReleases", false));
    } catch (error) {
      dispatch(loadingData("activeKotsReleases", false));
      dispatch(errorData("activeKotsReleases", true));
      console.log(error);
      return;
    }
  };
}

export function getKotsReleaseList(appId, startIndex = 0, pageCount = 20) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("kotsReleases", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint(
        "3"
      )}/app/${appId}/releases?currentPage=${startIndex}&pageSize=${pageCount}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        },
        mode: "cors"
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("kotsReleases", false));
          dispatch(forbiddenData("kotsReleases", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setKotsReleaseList(body));
      if (startIndex === 0) {
        dispatch(setLatestKotsReleaseToState(body.releases[0]));
      }
      dispatch(forbiddenData("kotsReleases", false));
      dispatch(loadingData("kotsReleases", false));
      dispatch(errorData("kotsReleases", false));
    } catch (error) {
      dispatch(loadingData("kotsReleases", false));
      dispatch(errorData("kotsReleases", true));
      console.log(error);
      return;
    }
  };
}

export function createKotsRelease(appId, callback, spec) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("createKotsRelease", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("3")}/app/${appId}/release`;

      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ spec: JSON.stringify(spec) }),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        if (response.status !== 400) {
          // 400 is a validation error, allow it through so it can be displayed to the user
          throw new Error(`Unexpected response status code ${response.status}`);
        }
      }

      const body = await response.json();
      callback(body);
      dispatch(loadingData("createKotsRelease", false));
    } catch (error) {
      dispatch(loadingData("createKotsRelease", false));
      console.log(error);
      return;
    }
  };
}

export function createDraftRelease(appId, isBuilders, callback = () => {}) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("createDraftRelease", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("3")}/app/${appId}/release/draft`;

      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          isBuildersRelease: isBuilders
        }),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }

      const body = await response.json();
      callback();
      dispatch(loadingData("createDraftRelease", false));
      dispatch(setDraftRelease(body.release));
    } catch (error) {
      dispatch(loadingData("createDraftRelease", false));
      console.log(error);
      return;
    }
  };
}

export function updateKotsRelease(appId, sequence, payload) {
  return async dispatch => {
    if (!appId) {
      return;
    }
    try {
      const path = `/app/${appId}/release/${sequence}`;
      const response = await apiFetchVendor(path, {
        method: "PUT",
        body: JSON.stringify({ spec_gzip: gzipData(payload) }),
        enableErrorHandling: false
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
          return "Unauthorized";
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          return "Unauthorized";
        }
        if (response.status === 400) {
          const responseJson = await response.json();
          return responseJson?.message;
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
    } catch (error) {
      console.log(error);
      return error?.message;
    }
  };
}

export function promoteKotsRelease(appId, sequence, payload) {
  return async dispatch => {
    if (!appId) {
      return false;
    }
    dispatch(setPromoteError(""));
    try {
      const path = `/app/${appId}/release/${sequence}/promote`;
      const response = await apiFetchVendor(path, {
        method: "POST",
        body: JSON.stringify({
          channelIds: payload.channels,
          versionLabel: payload.versionLabel,
          releaseNotesGzip: compressReleaseNotes(payload.releaseNotes),
          ignoreWarnings: payload.ignoreWarnings,
          isRequired: payload.isRequired,
          omitDetailsInResponse: true // Default to true - excludes channels, charts, and compatibilityResults properties in the response body
        }),
        enableErrorHandling: false
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        } else if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        } else {
          try {
            const error = await response.json();
            if (error?.lintExpressions) {
              dispatch(setPromoteErrorLintExpressions(error?.lintExpressions));
              dispatch(setPromoteErrorChannel(error?.channel));
              dispatch(displayModal("promoteReleaseError", true));
            }
            dispatch(setPromoteError(error.message));
          } catch (err) {
            console.log(err);
            dispatch(
              setPromoteError("An error occurred while promoting, please try again.")
            );
          }
        }
        return false;
      }
      dispatch(getKotsReleaseList(appId));
      dispatch(getActiveKotsReleases(appId));
      dispatch(displayModal("promoteReleaseError", false));
      return true;
    } catch (error) {
      return false;
    }
  };
}

function compressReleaseNotes(releaseNotes) {
  return Buffer.from(gzip(releaseNotes)).toString("base64");
}
