import {
  CLEAR_EXPIRED_TOKEN_ERROR,
  CLEAR_INVITE_ERROR,
  CLEAR_SERVER_ERROR,
  CLEAR_SIGNUP_VALIDATION_ERRORS,
  SET_ACCOUNT_UNLOCKED_STEP,
  SET_ACCOUNT_UNLOCK_STEP,
  SET_EXPIRED_TOKEN_MESSAGE,
  SET_INVITE_DATA,
  SET_INVITE_ERROR,
  SET_NEW_SESSION,
  SET_PASSWORD_RESET_STEP,
  SET_RESENT_CODE,
  SET_RESET_PASSWORD_ERROR,
  SET_RESET_PASSWORD_STEP,
  SET_SESSION_DATA,
  SET_SESSION_ERROR,
  SET_SIGNUP_VALIDATION_ERRORS,
  SET_TOKEN,
  SET_UPDATE_ACCOUNT_ERROR,
  SET_USER_ACCOUNT_INFORMATION
} from "./actions";
import { UPDATE_USER_TOKEN } from "../../data/main/account_settings/actions";
import { ErrorCodes } from "../../../utilities/ErrorCodes";
import { VendorUtilities } from "../../../utilities/VendorUtilities";
import type { Action } from "@/types";

const sessionState = {
  err: false,
  errMessage: "",
  inviteError: {},
  resetPasswordStep: "info",
  passwordResetStep: "info",
  accountUnlockStep: "info",
  accountUnlockedStep: "info",
  resetPasswordError: null,
  needsActivation: false,
  codeWasResent: false,
  user: {},
  updateAccountError: "",
  policies: [],
  errToken: false,
  errTokenMessage: "",
  signupValidationErrors: {}
};

export function sessionData(state = sessionState, action: Action = {}) {
  switch (action.type) {
    case UPDATE_USER_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.payload
      });
    case SET_RESENT_CODE:
      return Object.assign({}, state, {
        codeWasResent: action.payload
      });
    case CLEAR_SERVER_ERROR:
      return Object.assign({}, state, {
        err: false,
        errMessage: "",
        resetPasswordError: null
      });
    case CLEAR_SIGNUP_VALIDATION_ERRORS:
      return Object.assign({}, state, {
        signupValidationErrors: {}
      });
    case SET_RESET_PASSWORD_STEP:
      return Object.assign({}, state, {
        resetPasswordStep: action.payload
      });
    case SET_PASSWORD_RESET_STEP:
      return Object.assign({}, state, {
        passwordResetStep: action.payload
      });
    case SET_ACCOUNT_UNLOCK_STEP:
      return Object.assign({}, state, {
        accountUnlockStep: action.payload
      });
    case SET_ACCOUNT_UNLOCKED_STEP:
      return Object.assign({}, state, {
        accountUnlockedStep: action.payload
      });
    case SET_SESSION_ERROR:
      return Object.assign({}, state, {
        err: true,
        errMessage: action.payload.error?.message || JSON.stringify(action.payload)
      });
    case SET_RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        resetPasswordError: action.payload.error.message
      });
    case SET_INVITE_DATA:
      return Object.assign({}, state, {
        invite: action.payload.invite,
        team: action.payload.team
      });
    case SET_INVITE_ERROR: {
      const error = Object.assign({}, action.payload);
      const err = {
        title: ErrorCodes.toString(error.error.messageCode),
        status: error.error.code,
        message: error.error.message
      };
      return Object.assign({}, state, {
        inviteError: err
      });
    }
    case CLEAR_INVITE_ERROR:
      return Object.assign({}, state, {
        inviteError: {}
      });
    case SET_NEW_SESSION:
      return Object.assign({}, state, {
        accessToken: action.payload.token,
        user: action.payload.user
      });
    case SET_EXPIRED_TOKEN_MESSAGE:
      return Object.assign({}, state, {
        errToken: true,
        errTokenMessage: action.payload.error.message
      });
    case CLEAR_EXPIRED_TOKEN_ERROR:
      return Object.assign({}, state, {
        errToken: false,
        errTokenMessage: ""
      });
    case SET_SESSION_DATA: {
      const data = Object.assign({}, action.payload);
      if (data.signup) {
        return Object.assign({}, state, {
          needsActivation: true,
          accessToken: data.token,
          user: data.signup
        });
      } else if (data.signup_verification) {
        return Object.assign({}, state, {
          accessToken: data.token,
          user: data.signup_verification
        });
      } else {
        const needsOtp = data.ReplicatedNeedsOtp === "true" || data.needs_otp;
        return Object.assign({}, state, {
          needsOtp: needsOtp,
          needsActivation: false,
          features: data.features,
          futureFeatures: data.futureFeatures,
          readOnly: needsOtp ? undefined : VendorUtilities.isReadOnlyToken(data.token),
          team: data.team,
          accessToken: data.token,
          downloadWebHost: data.download_web_host,
          invite: {},
          user: data.user,
          policy: needsOtp ? undefined : VendorUtilities.getMemberPolicy(data.token)
        });
      }
    }
    case SET_TOKEN: {
      return Object.assign({}, state, {
        accessToken: action.payload.token
      });
    }
    case SET_SIGNUP_VALIDATION_ERRORS:
      return Object.assign({}, state, {
        signupValidationErrors: action.payload
      });
    case SET_USER_ACCOUNT_INFORMATION:
      return Object.assign({}, state, {
        user: action.payload
      });
    case SET_UPDATE_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        updateAccountError: action.payload
      });
    default:
      return state;
  }
}
