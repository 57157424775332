import { connect } from "react-redux";
import realRequireTwoFactor from "../auth/RequireTwoFactor";

import {
  checkTwoFactor,
  finalizeCode,
  setTwoFactorStep,
  confirmPassword,
  clearRecoveryCodes
} from "../../redux/data/main/account_settings/actions";
import { displayModal } from "../../redux/ui/main/actions";
import { clearServerError } from "../../redux/auth/sessions/actions";

const RequireTwoFactor = connect(
  state => ({
    twoFactorIsEnabled: state.data.main.accountSettings.twoFactorData.isEnabled,
    twoFactorServerErr: state.data.main.accountSettings.twoFactorData.err,
    twoFactorServerErrMessage: state.data.main.accountSettings.twoFactorData.errMessage,
    twoFactorStep: state.data.main.accountSettings.twoFactorData.step,
    qrCodeUrl: state.data.main.accountSettings.twoFactorData.qrCodeUrl,
    secret: state.data.main.accountSettings.twoFactorData.secret,
    showSavedToast: state.ui.main.apps.viewRelease.showSavedToast,
    modals: state.ui.main.modals,
    dataLoading: state.ui.main.loading,
    recoveryCodes: state.data.main.accountSettings.twoFactorData.recoveryCodes
  }),
  dispatch => ({
    checkTwoFactor() {
      return dispatch(checkTwoFactor());
    },
    clearServerError() {
      return dispatch(clearServerError());
    },
    setTwoFactorStep(step) {
      return dispatch(setTwoFactorStep(step));
    },
    finalizeCode(payload) {
      return dispatch(finalizeCode(payload));
    },
    clearRecoveryCodes() {
      return dispatch(clearRecoveryCodes());
    },
    confirmPassword(payload, activating) {
      return dispatch(confirmPassword(payload, activating));
    },
    displayModal(key, display) {
      return dispatch(displayModal(key, display));
    }
  })
)(realRequireTwoFactor);

export default RequireTwoFactor;
