import { connect } from "react-redux";
import realAccountUnlock from "../auth/AccountUnlock";

import { unlockAccount } from "../../redux/auth/sessions/actions";
import { loadingData } from "../../redux/ui/main/actions";

const AccountUnlock = connect(
  state => ({
    err: state.auth.sessions.sessionData.err,
    errMessage: state.auth.sessions.sessionData.errMessage,
    dataLoading: state.ui.main.loading,
    accountUnlockedStep: state.auth.sessions.sessionData.accountUnlockedStep
  }),
  dispatch => ({
    unlockAccount(payload) {
      return dispatch(unlockAccount(payload));
    },
    loadingData(key, isLoading) {
      return dispatch(loadingData(key, isLoading));
    }
  })
)(realAccountUnlock);

export default AccountUnlock;
