import { connect } from "react-redux";
import realAuthGoogleComplete from "../auth/AuthGoogleComplete";

import { handleGoogleLoginComplete } from "../../redux/auth/sessions/actions";

const AuthGoogleComplete = connect(
  state => ({
    dataLoading: state.ui.main.loading
  }),
  dispatch => ({
    handleGoogleLoginComplete(router, nonce, nextPage) {
      return dispatch(handleGoogleLoginComplete(router, nonce, nextPage));
    }
  })
)(realAuthGoogleComplete);

export default AuthGoogleComplete;
