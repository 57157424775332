import { combineReducers } from "redux";
import ReleasesReducer from "./releases/reducer";
import ChannelsReducer from "./channels/reducer";
import AppsReducer from "./app/reducer";
import ChannelReleasesReducer from "./channel_releases/reducer";
import CustomersReducer from "./customers/reducer";

export default combineReducers({
  releases: ReleasesReducer,
  channels: ChannelsReducer,
  apps: AppsReducer,
  channelReleases: ChannelReleasesReducer,
  customers: CustomersReducer
});
