import { connect } from "react-redux";
import realAccountLocked from "../auth/AccountLocked";

import {
  sendAccountUnlockEmail,
  setAccountUnlockStep,
  clearServerError
} from "../../redux/auth/sessions/actions";
import { displayModal, loadingData } from "../../redux/ui/main/actions";

const AccountLocked = connect(
  state => ({
    err: state.auth.sessions.sessionData.err,
    errMessage: state.auth.sessions.sessionData.errMessage,
    dataLoading: state.ui.main.loading,
    accountUnlockStep: state.auth.sessions.sessionData.accountUnlockStep
  }),
  dispatch => ({
    sendAccountUnlockEmail(payload) {
      return dispatch(sendAccountUnlockEmail(payload));
    },
    clearServerError() {
      return dispatch(clearServerError());
    },
    setAccountUnlockStep(step) {
      return dispatch(setAccountUnlockStep(step));
    },
    loadingData(key, isLoading) {
      return dispatch(loadingData(key, isLoading));
    },
    displayModal(key, display) {
      return dispatch(displayModal(key, display));
    }
  })
)(realAccountLocked);

export default AccountLocked;
