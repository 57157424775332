import { VendorUtilities } from "../../../utilities/VendorUtilities";
import { SessionManager } from "../../../services/SessionManager";

export async function joinTeam(payload) {
  const response = await fetch(`${SessionManager.getApiEndpoint()}/signup/auto-join`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: VendorUtilities.getToken(),
      "Content-Type": "application/json"
    }
  });

  return await response.json();
}
