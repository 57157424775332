import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { logOutUser } from "../../redux/auth/sessions/actions";
import { displayModal } from "../../redux/ui/main/actions";

import Modal from "react-modal";
import ModalBody from "../modals/ModalBody";
import { Button } from "../shared/Button";

const LogOutErrorModal = () => {
  const showModal = useSelector(state => state.ui.main.modals.logoutErrorModal);
  const isLoading = useSelector(state => state.ui.main.loading.loggingOutLoading);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={showModal}
      contentLabel="Logout Error Modal"
      ariaHideApp={false}
      className="Modal DefaultSize"
      onRequestClose={() => dispatch(displayModal("logoutError", false))}
    >
      <ModalBody>
        <div className="flex flex-column justifyContent--center alignItems--center">
          <span className="icon u-errorIcon u-marginBottom--normal"></span>
          <h2 className="u-fontSize--larger u-marginBottom--normal u-fontWeight--medium u-textAlign--center u-color--tuna">
            There was a problem logging you out
          </h2>
          <p className="u-fontSize--normal u-textAlign--center u-color--dustyGray u-lineHeight--normal u-marginBottom--more">
            We encountered an error while attempting to log you out. Please try again.
            If this problem persists,{" "}
            <Link
              to="/support"
              className="tw-text-blue-300 tw-font-semibold hover:tw-underline"
            >
              contact support
            </Link>
            .
          </p>
          <div className="FormButton-wrapper flex justifyContent--center">
            <Button
              type="button"
              kind="secondary-gray"
              className="tw-mr-3"
              onClick={() => dispatch(displayModal("logoutError", false))}
            >
              Cancel
            </Button>
            <Button
              type="button"
              kind="primary-purple"
              disabled={isLoading}
              onClick={() => dispatch(logOutUser)}
            >
              {isLoading ? "Logging out" : "Log out"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LogOutErrorModal;
