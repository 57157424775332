import type { Action } from "@/types";
import { DISPLAY_MODAL, ERROR_DATA, FORBIDDEN_DATA, LOADING_DATA } from "./actions";

const permissionsState = {
  // Licenses Forbidden
  licensesForbidden: false,
  instancesForbidden: false,
  getLicenseForbidden: false,
  kotsAppCustomersForbidden: false,

  // Misc Forbidden
  retracedForbidden: false,
  scopedRetracedForbidden: false,

  // Releases Forbidden
  releasesForbidden: false,
  releasePropForbidden: false,
  preflightChecksForbidden: false,

  // License Field Forbidden
  licenseFieldsForbidden: false,

  // Images Forbidden
  imagesForbidden: false,

  // Channels Forbidden
  channelsForbidden: false,
  channelHistoriesForbidden: false,
  appChannelsForbidden: false,
  kotsAppChannelsForbidden: false,
  kotsChannelReleaseHistoryForbidden: false,

  // External Registries Forbidden
  externalRegistriesForbidden: false,
  kotsExternalRegistriesForbidden: false,

  // Integrations Forbidden
  integrationsForbidden: false,

  // Individual Team Members Forbidden
  viewMemberForbidden: false,

  // Team Tokens Forbidden
  teamTokensForbidden: false,

  // Service Account Tokens Forbidden
  serviceAccountsForbidden: false,

  // Team Policies Forbidden
  teamPolicies: false
};

export function forbidden(state = permissionsState, action: Action = {}) {
  switch (action.type) {
    case FORBIDDEN_DATA:
      return Object.assign({}, state, {
        [`${action.payload.key}Forbidden`]: action.payload.isForbidden
      });
    default:
      return state;
  }
}

const errorState = {
  // Releases Error
  releasesError: false,

  // License Error
  licensesError: false
};

export function error(state = errorState, action: Action = {}) {
  switch (action.type) {
    case ERROR_DATA:
      return Object.assign({}, state, {
        [`${action.payload.key}Error`]: action.payload.isError
      });
    default:
      return state;
  }
}

const modalState = {
  // Auth modals
  forgotPasswordModal: false,
  confirmPasswordModal: false,
  permissionsModal: false,
  trialExpiredModal: false,
  logoutErrorModal: false,

  // Licenses modals
  createEditLicenseModal: false,
  cannotCreateLicenseModal: false,
  confirmArchiveLicenseModal: false,
  licenseFieldModal: false,
  instanceDetailsModal: false,
  licenseChannelSelectModal: false,
  customerSearchWarningModal: false,
  removeLicenseModal: false,
  confirmArchiveKotsCustomerModal: false,

  // Releases modals
  viewSnippetModal: false,
  promoteModal: false,
  archiveReleaseModal: false,
  updateReleaseErrorModal: false,
  releasePreflightCheckModal: false,
  promoteReleaseErrorModal: false,

  // Troubleshoot modals
  promoteCollectorModal: false,
  promoteAnalyzerModal: false,

  // Channels modals
  createChannelModal: false,
  archiveChannelModal: false,
  editHistoryModal: false,
  installInstructionsModal: false,
  hideChannelReleaseModal: false,
  showChannelReleaseModal: false,

  // Misc modals
  confirmDeleteImageKeyModal: false,
  editBrandingModal: false,
  editRegistryModal: false,
  confirmRemoveCssModal: false,
  confirmRemoveRegistryModal: false,
  confirmRemoveGithubModal: false,
  confirmDeleteAppModal: false,
  airgapErrorModal: false,
  replicatedStudioModal: false,

  // Teams and tokens
  inviteTeamMemberModal: false,
  newTokenModal: false,
  confirmRemoveInviteModal: false,
  confirmReinviteModal: false,
  confirmRemoveMemberModal: false,
  confirmRemoveTokenModal: false,
  confirmRemovePolicyModal: false,
  createPermissionsPolicyModal: false,

  // Account Settings
  confirmRecoveryCodesModal: false
};

export function modals(state = modalState, action: Action = {}) {
  switch (action.type) {
    case DISPLAY_MODAL:
      return Object.assign({}, state, {
        [`${action.payload.key}Modal`]: action.payload.display
      });
    default:
      return state;
  }
}

const loadingState = {
  // Auth loading
  signupLoading: false,
  activationCodeLoading: false,
  loginLoading: false,
  otpLoginLoading: false,
  inviteLoading: false,
  passwordResetLoading: false,
  accountUnlockEmailLoading: false,
  unlockAccountLoading: false,
  resettingPasswordLoading: false,
  loggingOutLoading: false,

  // Settings loading
  changePasswordLoading: false,
  notificationsLoading: false,
  twoFactorLoading: false,
  authCodeLoading: false,
  confirmPassword: false,
  updateAccountInformation: false,

  // Licenses loading
  licensesLoading: false,
  instancesLoading: false,
  licenseCreateLoading: false,
  licenseUpdateLoading: false,
  licenseBillingUpdateLoading: false,
  licenseFieldsLoading: false,
  instanceDetailLoading: false,
  getLicenseLoading: false,
  kotsAppCustomersLoading: false,
  createKotsCustomerLoading: false,
  updateKotsCustomerLoading: false,
  archiveKotsCustomerLoading: false,
  unarchiveKotsCustomerLoading: false,
  kotsInstancesLoading: false,
  kotsCustomerLoading: false,

  // Apps loading
  createApp: false,
  appListLoading: false,
  selectedAppLoading: false,
  createKotsApp: false,
  kotsAppListLoading: false,

  // Channels loading
  channelsLoading: false,
  channelHistoryLoading: false,
  releaseUpdateLoading: false,
  channelHistoriesLoading: false,
  channelUpdateLoading: false,
  deleteBrandingLoading: false,
  createBrandingLoading: false,
  appChannelsLoading: false,
  kotsAppChannelsLoading: false,
  kotsChannelLoading: false,
  kotsChannelReleaseHistoryLoading: false,
  createKotsChannel: false,
  archiveKotsChannel: false,
  hideChannelReleaseLoading: false,
  unhideChannelReleaseLoading: false,

  // Releases loading
  releasesLoading: false,
  updatingReleaseLoading: false,
  promoteReleaseLoading: false,
  preflightChecksLoading: false,
  activeKotsReleasesLoading: false,
  kotsReleases: false,

  // Misc loading
  retracedLoading: false,
  imagesLoading: false,
  deleteKeyLoading: false,
  kotsExternalRegistriesLoading: false,
  updatingKotsExternalRegistryLoading: false,
  deleteKotsExternalRegistryLoading: false,

  // Teams and Tokens
  teamLoading: false,
  teamMembersLoading: false,
  teamTokensLoading: false,
  serviceTokensLoading: false,
  teamLicensesLoading: false,
  teamInvitesLoading: false,
  inviteMemberLoading: false,
  removeInvitationLoading: false,
  removeMemberLoading: false,
  removeTokenLoading: false,
  viewMemberLoading: false,
  viewPolicyLoading: false,
  updateMemberLoading: false,
  teamPasswordPoliciesSettingsLoading: false,
  teamPoliciesLoading: false,
  createTeamPolicyLoading: false,
  teamPasswordPoliciesSettingsUpdatedLoading: false,
  listTeamEntitlementsLoading: false,
  sendSupportTicketLoading: false,
  removeTeamPolicyLoading: false,

  // App Settings
  brandingLoading: false,
  updateBrandingLoading: false,
  externalRegistriesLoading: false,
  updatingRegistryLoading: false,
  integrationsLoading: false,
  connectingGithubLoading: false,
  deleteAppLoading: false,
  validatePasswordLoading: false
};

export function loading(state = loadingState, action: Action = {}) {
  switch (action.type) {
    case LOADING_DATA:
      return Object.assign({}, state, {
        [`${action.payload.key}Loading`]: action.payload.isLoading
      });
    default:
      return state;
  }
}
