import {
  CLEAR_INTEGRATION_ERROR,
  CLEAR_REGISTRY_ERROR,
  CLEAR_VALIDATE_PASSWORD_ERROR,
  SET_APP_SETTINGS_EXTERNAL_REGISTRIES,
  SET_APP_SETTINGS_INTEGRATIONS,
  SET_INTEGRATION_ERROR,
  SET_REGISTRY_ERROR,
  SET_VALIDATE_PASSWORD_ERROR
} from "./actions";
import { CLEAR_SERVER_ERROR } from "../../../auth/sessions/actions";

const externalRegistryState = {
  externalRegistries: {},
  registryErr: false,
  registryErrMessage: ""
};

export function externalRegistryData(state = externalRegistryState, action) {
  switch (action.type) {
    case SET_APP_SETTINGS_EXTERNAL_REGISTRIES:
      return Object.assign({}, state, {
        externalRegistries: action.payload
      });
    case CLEAR_REGISTRY_ERROR:
      return Object.assign({}, state, {
        registryErr: false,
        registryErrMessage: ""
      });
    case SET_REGISTRY_ERROR:
      return Object.assign({}, state, {
        registryErr: true,
        registryErrMessage: action.payload
      });
    default:
      return state;
  }
}

const integrationState = {
  integrations: {},
  integrationErr: false,
  integrationErrMessage: ""
};

export function integrationData(state = integrationState, action) {
  switch (action.type) {
    case SET_APP_SETTINGS_INTEGRATIONS:
      return Object.assign({}, state, {
        integrations: action.payload
      });
    case CLEAR_INTEGRATION_ERROR:
      return Object.assign({}, state, {
        integrationErr: false,
        integrationErrMessage: ""
      });
    case SET_INTEGRATION_ERROR:
      return Object.assign({}, state, {
        integrationErr: true,
        integrationErrMessage: action.payload
      });
    default:
      return state;
  }
}

const passwordState = {
  passwordErr: false,
  passwordErrMessage: ""
};

export function validatePasswordData(state = passwordState, action) {
  switch (action.type) {
    case CLEAR_SERVER_ERROR:
      return Object.assign({}, state, {
        passwordErr: false,
        passwordErrMessage: ""
      });
    case CLEAR_VALIDATE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        passwordErr: false,
        passwordErrMessage: ""
      });
    case SET_VALIDATE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        passwordErr: true,
        passwordErrMessage: action.payload.error.message
      });
    default:
      return state;
  }
}
