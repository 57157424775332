import "isomorphic-fetch";
import { SessionManager } from "../../../../../services/SessionManager";
import { displayModal, loadingData, forbiddenData } from "../../../../ui/main/actions";
import { getReleaseList } from "../releases/actions";
import { showSavedToast } from "../../../../ui/main/apps/view_release/actions";
import { logOutUser } from "../../../../auth/sessions/actions";

export const SET_NEW_RELEASE = "SET_NEW_RELEASE";
export const SET_RELEASE_PROPERTIES = "SET_RELEASE_PROPERTIES";
export const SET_YAML = "SET_YAML";
export const SET_UPDATE_RELEASE_ERROR = "SET_UPDATE_RELEASE_ERROR";
export const CLEAR_UPDATE_ERROR = "CLEAR_UPDATE_ERROR";
export const UPDATE_IS_NEW_PROPERTY = "UPDATE_IS_NEW_PROPERTY";

export function setNewRelease(release) {
  return {
    type: SET_NEW_RELEASE,
    payload: release
  };
}

function setReleaseProperties(properties) {
  return {
    type: SET_RELEASE_PROPERTIES,
    payload: properties
  };
}

function setYaml(yaml) {
  return {
    type: SET_YAML,
    payload: yaml
  };
}

function setUpdateReleaseError(err) {
  return {
    type: SET_UPDATE_RELEASE_ERROR,
    payload: err
  };
}

export function clearUpdateError() {
  return {
    type: CLEAR_UPDATE_ERROR
  };
}

export function updateIsNewProperty(value) {
  return {
    type: UPDATE_IS_NEW_PROPERTY,
    payload: value
  };
}

export function getReleaseProperties(appId, sequence) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/${sequence}/properties`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("releaseProp", false));
          dispatch(forbiddenData("releaseProp", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      const yml = body.Config;
      dispatch(setReleaseProperties(body));
      dispatch(forbiddenData("releaseProp", false));
      dispatch(setYaml(yml));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function createNewRelease(appId, payload, callback) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/release`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setNewRelease(body));
      callback(body.Sequence);
      dispatch(getReleaseList(appId));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function updateRelease(appId, sequence, payload, linted) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("updatingRelease", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/${sequence}/raw`;
      response = await fetch(url, {
        method: "PUT",
        body: payload,
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "*/*",
          "Content-Type": "application/yaml",
          Linted: JSON.stringify(linted)
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        const res = await response.json();
        dispatch(setUpdateReleaseError(res.error));
        dispatch(loadingData("updatingRelease", false));
        dispatch(displayModal("updateReleaseError", true));
        return;
      }
      const body = await response.text();
      dispatch(setYaml(body));
      dispatch(loadingData("updatingRelease", false));
      dispatch(showSavedToast(true));
      dispatch(getReleaseProperties(appId, sequence));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 4000);
    } catch (error) {
      console.log(error);
    }
  };
}
