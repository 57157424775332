import styled from "styled-components";

export const FormLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.formLabel};
  margin-bottom: 12px;
`;

export const RequiredLabel = styled.span`
  font-size: 14px;
  position: relative;
  top: -1px;
  font-weight: 500;
  color: ${props => props.theme.error};
`;

export const ErrorBlock = styled.div`
  padding: 12px;
  background-color: ${props => props.theme.errorLight};
  border-radius: 4px;
  color: ${props => props.theme.errorDark};
`;

export const CheckboxLabel = styled.label`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #9b9b9b;
  display: block;
`;

export const Checkbox = styled.input`
  margin: 15px 8px 15px 15px;
  vertical-align: middle;
  position: relative;
  top: -1px;
`;

export const StyledTextarea = styled.textarea`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${props => props.theme.gray100};
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: normal;
  color: ${props => props.theme.gray600};
  padding: 13px 14px;
  resize: none;
`;
