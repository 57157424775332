import { SET_LICENSE_FIELDS_LIST } from "./actions";
import map from "lodash/map";
import keyBy from "lodash/keyBy";
import type { Action } from "@/types";

const initialState = {
  licenseFields: {},
  requiredFields: []
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_LICENSE_FIELDS_LIST: {
      const _licenseFields = Object.assign({}, action.payload);
      const _requiredFields = [];
      map(_licenseFields, field => {
        if (field.required) {
          _requiredFields.push(field.name);
        }
      });
      return Object.assign({}, state, {
        licenseFields: keyBy(_licenseFields, "name"),
        requiredFields: _requiredFields
      });
    }
    default:
      return state;
  }
}
