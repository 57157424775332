import { connect } from "react-redux";
import realSignup from "../auth/Signup";

import {
  setLoadingToFalse,
  handleGoogleSignup,
  logOutUser,
  handleSignUp,
  clearSignupValidationErrors
} from "../../redux/auth/sessions/actions";
import { displayModal } from "../../redux/ui/main/actions";

const Signup = connect(
  state => ({
    hasToken: state.auth.sessions.sessionData.accessToken,
    dataLoading: state.ui.main.loading,
    signupValidationErrors: state.auth.sessions.sessionData.signupValidationErrors,
    user: state.auth.sessions.sessionData.user
  }),
  dispatch => ({
    handleSignUp(payload) {
      return dispatch(handleSignUp(payload));
    },
    clearSignupValidationErrors() {
      return dispatch(clearSignupValidationErrors());
    },
    displayModal(key, display) {
      return dispatch(displayModal(key, display));
    },
    setLoadingToFalse() {
      return dispatch(setLoadingToFalse());
    },
    handleGoogleSignup(isBuildersOnly) {
      return dispatch(handleGoogleSignup(isBuildersOnly));
    },
    logOutUser() {
      return dispatch(logOutUser());
    }
  })
)(realSignup);

export default Signup;
