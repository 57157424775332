import { connect } from "react-redux";
import realVerifySignup from "../auth/VerifySignup";

import {
  setLoadingToFalse,
  clearServerError,
  logOutUser,
  handleSubmitActivationCode
} from "../../redux/auth/sessions/actions";
import { displayModal } from "../../redux/ui/main/actions";

const VerifySignup = connect(
  state => ({
    modals: state.ui.main.modals,
    err: state.auth.sessions.sessionData.err,
    errMessage: state.auth.sessions.sessionData.errMessage,
    accessToken: state.auth.sessions.sessionData.accessToken,
    team: state.auth.sessions.sessionData.team,
    needsActivation: state.auth.sessions.sessionData.needsActivation,
    codeWasResent: state.auth.sessions.sessionData.codeWasResent,
    hasToken: state.auth.sessions.sessionData.accessToken,
    dataLoading: state.ui.main.loading,
    user: state.auth.sessions.sessionData.user
  }),
  dispatch => ({
    handleSubmitActivationCode(payload, router) {
      return dispatch(handleSubmitActivationCode(payload, router));
    },
    clearServerError() {
      return dispatch(clearServerError());
    },
    displayModal(key, display) {
      return dispatch(displayModal(key, display));
    },
    setLoadingToFalse() {
      return dispatch(setLoadingToFalse());
    },
    logOutUser() {
      return dispatch(logOutUser());
    }
  })
)(realVerifySignup);

export default VerifySignup;
