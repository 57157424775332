import {
  CLEAR_RETRACED_ERROR,
  SET_RETRACED_ERROR,
  SET_RETRACED_TOKEN
} from "./actions";

const retracedState = {
  err: false,
  errMessage: "",
  retracedTokens: {}
};

export function retracedData(state = retracedState, action) {
  switch (action.type) {
    case SET_RETRACED_TOKEN: {
      const retracedTokens = retracedState.retracedTokens;
      retracedTokens[action.payload.scope] = action.payload.token;
      return Object.assign({}, state, {
        retracedTokens
      });
    }
    case CLEAR_RETRACED_ERROR:
      return Object.assign({}, state, {
        err: false,
        errMessage: ""
      });
    case SET_RETRACED_ERROR:
      return Object.assign({}, state, {
        err: true,
        errMessage: action.payload.error.message
      });
    default:
      return state;
  }
}
