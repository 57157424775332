import {
  CLEAR_LICENSE_ERROR,
  CLEAR_SELECTED_LICENSE,
  SET_CSV_LICENSES,
  SET_INSTANCE_DETAIL,
  SET_LICENSES_LIST,
  SET_LICENSES_VERSIONS,
  SET_LICENSE_ERROR,
  SET_LICENSE_INSTANCES,
  SET_SELECTED_LICENSE,
  SET_SELECTED_LICENSE_FIELDS
} from "./actions";
import { ErrorCodes } from "../../../../../utilities/ErrorCodes";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import forEach from "lodash/forEach";
import type { Action } from "@/types";

const initialState = {
  licenses: {},
  licenseVersions: {},
  totalLicenseCount: 0,
  selectedLicense: {},
  selectedInstance: {},
  licenseInstances: {},
  selectedLicenseFields: {},
  licenseErr: {},
  csvLicenses: []
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_LICENSES_LIST:
      return Object.assign({}, state, {
        licenses: keyBy(action.payload.licenses, "id"),
        totalLicenseCount: action.payload.total_hits
      });
    case SET_LICENSES_VERSIONS:
      return Object.assign({}, state, {
        licenseVersions: keyBy(action.payload.license_versions, "license_id")
      });
    case SET_CSV_LICENSES:
      return Object.assign({}, state, {
        csvLicenses: action.payload
      });
    case SET_SELECTED_LICENSE:
      return Object.assign({}, state, {
        selectedLicense: action.payload
      });
    case CLEAR_SELECTED_LICENSE:
      return Object.assign({}, state, {
        selectedLicense: {}
      });
    case SET_LICENSE_INSTANCES:
      return Object.assign({}, state, {
        licenseInstances: keyBy(action.payload, "InstanceId")
      });
    case SET_INSTANCE_DETAIL:
      return Object.assign({}, state, {
        selectedInstance: action.payload
      });
    case SET_SELECTED_LICENSE_FIELDS: {
      const byApp = groupBy(action.payload, "app_id");
      const fieldsByAppId = {};
      forEach(byApp, (fields, appId) => {
        fieldsByAppId[appId] = keyBy(fields, "field");
      });
      return Object.assign({}, state, {
        selectedLicenseFields: fieldsByAppId
      });
    }
    case SET_LICENSE_ERROR: {
      const error = Object.assign({}, action.payload);
      let err;
      if (error.error.messageCode) {
        err = {
          title: ErrorCodes.toString(error.error.messageCode),
          status: error.error.code,
          message: error.error.message
        };
      } else {
        err = {
          message: error.error
        };
      }
      return Object.assign({}, state, {
        licenseErr: err
      });
    }
    case CLEAR_LICENSE_ERROR:
      return Object.assign({}, state, {
        licenseErr: {}
      });
    default:
      return state;
  }
}
