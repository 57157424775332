import { Component } from "react";
import queryString from "query-string";
import Footer from "../shared/Footer";
import { withRouter } from "react-router-dom";

import "../../scss/components/auth/AuthPage.scss";

class AuthGoogleComplete extends Component {
  componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    this.props.handleGoogleLoginComplete(this.props.history, search.nonce, search.next);
  }

  render() {
    return (
      <div className="u-width--full u-overflow--auto flex-column">
        <div className="u-flex--mobileoff flex-1-auto u-width--full">
          <div className="AuthIllustration-wrapper">
            <div className="ReplicatedLogoShadow logo-standalone"></div>
          </div>

          <div className="Login-wrapper flex1 flex-column flex-verticalCenter u-paddingBottom--normal alignItems--center auth-pages">
            <p className="form-title u-color--blue u-lineHeight--normal u-paddingLeft--most">
              Logging In...
            </p>
            <div className="Form-wrapper">
              <div className="FormButton-wrapper flex"></div>
            </div>
          </div>
          <div className="auth-footer">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AuthGoogleComplete);
