import "../../scss/components/auth/AuthPage.scss";
import React from "react";
import fasterImage from "../../images/faster.svg";
import toolsImage from "../../images/tools.svg";
import serverImage from "../../images/server.svg";

const ReplicatedInfo = props => {
  return (
    <div
      className={`AuthIllustration-wrapper tw-flex ${
        props.needsActivation && "tw-hidden md:tw-flex"
      }`}
    >
      <div
        className={`${
          props.needsActivation ? "u-activationCode" : "ReplicatedLogoShadow"
        }`}
      ></div>
      <div
        className={`tw-block ${
          props.needsActivation ? "u-display--none" : "feature-list"
        }`}
      >
        <div className="flex feature-list-item">
          <div className="flex1 feature-list-icon">
            <img src={fasterImage} alt="faster time to market" />
          </div>
          <div className="flex5">
            <h2>Faster time to market</h2>
            <p>
              Focus on your product’s core value and let Replicated handle getting it
              delivered to on-prem customers.
            </p>
          </div>
        </div>

        <div className="flex feature-list-item">
          <div className="flex1 feature-list-icon">
            <img src={toolsImage} alt="faster time to market" />
          </div>
          <div className="flex5">
            <h2>Tools for your entire team</h2>
            <p>
              Cross-functional collaboration tools that help you deliver modern on-prem
              software to your most important enterprise customers.
            </p>
          </div>
        </div>

        <div className="flex feature-list-item">
          <div className="flex1 feature-list-icon">
            <img src={serverImage} alt="faster time to market" />
          </div>
          <div className="flex5">
            <h2>Every environment</h2>
            <p>
              Deliver to private clouds (AWS, GCP, Azure), Kubernetes platforms (GKE,
              OpenShift, Rancher), bare metal, and airgapped environments with one
              release.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ReplicatedInfo);
