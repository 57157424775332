export const ErrorCodes = {
  toString(code) {
    switch (code) {
      case "INVALID_YAML":
        return "Invalid YAML";
      case "NO_CONTRACT":
        return "No contract created";
      case "INVALID_INVITE_CODE":
        return "Invalid invitation code";
      default:
        return "Error";
    }
  }
};
