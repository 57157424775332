import {
  CLEAR_UPDATE_ERROR,
  SET_NEW_RELEASE,
  SET_RELEASE_PROPERTIES,
  SET_UPDATE_RELEASE_ERROR,
  SET_YAML,
  UPDATE_IS_NEW_PROPERTY
} from "./actions";
import { ErrorCodes } from "../../../../../utilities/ErrorCodes";
import type { Action } from "@/types";

const initialState = {
  release: {},
  rawYaml: "",
  isNew: false,
  hasUpdateErr: false,
  updateErr: {}
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_NEW_RELEASE:
      return Object.assign({}, state, {
        release: action.payload,
        isNew: true
      });
    case SET_RELEASE_PROPERTIES:
      return Object.assign({}, state, {
        release: action.payload
      });
    case UPDATE_IS_NEW_PROPERTY:
      return Object.assign({}, state, {
        isNew: action.payload
      });
    case SET_YAML:
      return Object.assign({}, state, {
        rawYaml: action.payload
      });
    case SET_UPDATE_RELEASE_ERROR: {
      const error = Object.assign({}, action.payload);
      const err = {
        title: ErrorCodes.toString(error.messageCode),
        status: error.code,
        message: error.message
      };
      return Object.assign({}, state, {
        hasUpdateErr: true,
        updateErr: err
      });
    }
    case CLEAR_UPDATE_ERROR:
      return Object.assign({}, state, {
        hasUpdateErr: false,
        updateErr: {}
      });
    default:
      return state;
  }
}
