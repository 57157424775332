import { SET_CHANNEL_VIEW } from "./actions";
import { SET_CHANNEL_LIST } from "../../../../data/main/apps/channels/actions";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import type { Action } from "@/types";

const initialState = {
  channelView: "grid",
  channelList: []
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_CHANNEL_VIEW:
      return Object.assign({}, state, {
        channelView: action.payload
      });
    case SET_CHANNEL_LIST: {
      const _channelList = Object.assign({}, action.payload);
      const _sortedChannelList = sortBy(_channelList, "Position");
      const sortedChannelList = _sortedChannelList.reverse();
      return Object.assign({}, state, {
        channelList: map(sortedChannelList, "Id")
      });
    }
    default:
      return state;
  }
}
