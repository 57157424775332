import { Component } from "react";
import "../../scss/components/shared/ErrorBoundary.scss";
import { Button } from "./Button";

export default class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
      message: "",
      sendingBugsnagMessage: false
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      hasError: true
    });
  }

  handleFormChange = (field, e) => {
    let nextState = {};
    nextState[field] = e.target.value;
    this.setState(nextState);
  };

  backToReplicated = () => {
    window.location.replace("/");
  };

  render() {
    const { info } = this.props;

    if (this.state.hasError || info) {
      return (
        <div className="flex1 flex-column u-overflow--auto">
          <div className="flex1 flex-column justifyContent--center alignItems--center">
            <div className="flex-column u-width--half">
              <span className="icon u-errorWarningIcon u-marginBottom--most u-textAlign--center alignSelf--center"></span>
              <p className="u-textAlign--center alignItems--center u-fontSize--header2 u-fontWeight--bold u-color--tundora">
                Oops, something went wrong.
              </p>
              <p className=" u-textAlign--center u-marginTop--more u-fontWeight--medium u-color--dustyGray u-fontSize--normal u-lineHeight--normal">
                {" "}
                We’ve notified our team of the error that occurred and will be working
                to resolve the issue. We apologize for any inconvenience this may have
                caused.{" "}
              </p>
              <div className="flex alignItems--center alignSelf--center u-marginTop--more">
                <Button
                  kind="secondary-gray"
                  className="tw-mr-3"
                  onClick={() => this.backToReplicated()}
                >
                  {" "}
                  Back to Replicated{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="flex-column flex1">{this.props.children}</div>;
    }
  }
}
