import { SET_IMAGES_LIST } from "./actions";
import {
  CLEAR_SERVER_ERROR,
  SET_SESSION_ERROR
} from "../../../../auth/sessions/actions";
import type { Action } from "@/types";

const initialState = {
  imagesList: [],
  err: false,
  errMessage: ""
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_IMAGES_LIST:
      return Object.assign({}, state, {
        imagesList: action.payload
      });
    case CLEAR_SERVER_ERROR:
      return Object.assign({}, state, {
        err: false,
        errMessage: ""
      });
    case SET_SESSION_ERROR:
      return Object.assign({}, state, {
        err: true,
        errMessage: action.payload.error?.message || action.payload.error
      });
    default:
      return state;
  }
}
