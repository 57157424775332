import "isomorphic-fetch";
import { SessionManager } from "../../../../services/SessionManager";
import { logOutUser } from "../../../auth/sessions/actions";
import { loadingData, displayModal, forbiddenData } from "../../../ui/main/actions";
import { showSavedToast } from "../../../ui/main/apps/team/actions";
import { setSessionError } from "../../../auth/sessions/actions";

export const SET_TEAM_DATA = "SET_TEAM_DATA";
export const SET_TEAM_MEMBER_DATA = "SET_TEAM_MEMBER_DATA";
export const SET_TEAM_POLICY_DATA = "SET_TEAM_POLICY_DATA";
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
export const SET_TEAM_TOKENS = "SET_TEAM_TOKENS";
export const SET_TEAM_POLICIES = "SET_TEAM_POLICIES";
export const SET_PASSWORD_SETTINGS = "SET_PASSWORD_SETTINGS";
export const SET_SAML_AUTHENTICATION_SETTINGS = "SET_SAML_AUTHENTICATION_SETTINGS";
export const SET_SAML_AUTHENTICATION_SETTINGS_ERROR =
  "SET_SAML_AUTHENTICATION_SETTINGS_ERROR";
export const SET_GOOGLE_AUTHENTICATION_SETTINGS = "SET_GOOGLE_AUTHENTICATION_SETTINGS";
export const SET_TEAM_ENTITLEMENTS = "SET_TEAM_ENTITLEMENTS";
export const SET_UPLOAD_SUCCESS = "SET_UPLOAD_SUCCESS";
export const SET_SUPPORT_TICKET_SUCCESS = "SET_SUPPORT_TICKET_SUCCESS";
export const CLEAR_SUPPORT_TICKET = "CLEAR_SUPPORT_TICKET";
export const SET_INVITE_ERROR = "SET_INVITE_ERROR";
export const CLEAR_INVITE_ERROR = "CLEAR_INVITE_ERROR";
export const SET_REMOVE_INVITE_ERROR = "SET_REMOVE_INVITE_ERROR";
export const CLEAR_REMOVE_INVITE_ERROR = "CLEAR_REMOVE_INVITE_ERROR";
export const SET_SERVICE_ACCOUNTS = "SET_SERVICE_ACCOUNTS";
export const SET_NEW_SERVICE_ACCOUNT = "SET_NEW_SERVICE_ACCOUNT";
export const SET_NEW_SERVICE_ACCOUNT_ERR = "SET_NEW_SERVICE_ACCOUNT_ERR";

function setTeamData(data) {
  return {
    type: SET_TEAM_DATA,
    payload: data
  };
}

function setTeamPolicyData(policy) {
  return {
    type: SET_TEAM_POLICY_DATA,
    payload: policy
  };
}

function setTeamMemberData(member) {
  return {
    type: SET_TEAM_MEMBER_DATA,
    payload: member
  };
}

function setTeamMembers(members) {
  return {
    type: SET_TEAM_MEMBERS,
    payload: members
  };
}

function setTeamTokens(tokens) {
  return {
    type: SET_TEAM_TOKENS,
    payload: tokens
  };
}

function setServiceAccounts(serviceAccounts) {
  return {
    type: SET_SERVICE_ACCOUNTS,
    payload: serviceAccounts
  };
}

export function setNewServiceAccount(serviceAccount) {
  return {
    type: SET_NEW_SERVICE_ACCOUNT,
    payload: serviceAccount
  };
}

export function setNewServiceAccountErr(message) {
  return {
    type: SET_NEW_SERVICE_ACCOUNT_ERR,
    payload: message
  };
}

function setTeamPolicies(policies) {
  return {
    type: SET_TEAM_POLICIES,
    payload: policies
  };
}

function setSecuritySettings(settings) {
  return {
    type: SET_PASSWORD_SETTINGS,
    payload: settings
  };
}

function setSamlAuthenticationSettings(settings) {
  return {
    type: SET_SAML_AUTHENTICATION_SETTINGS,
    payload: settings
  };
}

function setSamlAuthenticationSettingsError(err) {
  return {
    type: SET_SAML_AUTHENTICATION_SETTINGS_ERROR,
    payload: err
  };
}

function setGoogleAuthenticationSettings(settings) {
  return {
    type: SET_GOOGLE_AUTHENTICATION_SETTINGS,
    payload: settings
  };
}

function setTeamEntitlements(settings) {
  return {
    type: SET_TEAM_ENTITLEMENTS,
    payload: settings
  };
}

export function clearSupportTicket() {
  return {
    type: CLEAR_SUPPORT_TICKET
  };
}

function setInviteError(err) {
  return {
    type: SET_INVITE_ERROR,
    payload: err
  };
}

export function clearInviteError() {
  return {
    type: CLEAR_INVITE_ERROR
  };
}

function setRemoveInviteError(err) {
  return {
    type: SET_REMOVE_INVITE_ERROR,
    payload: err
  };
}

export function clearRemoveInviteError(err) {
  return {
    type: CLEAR_REMOVE_INVITE_ERROR,
    payload: err
  };
}

// TEAMS CALLS

export function getTeam() {
  return async (dispatch, getState) => {
    dispatch(loadingData("team", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("team", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("team", false));
      dispatch(setTeamData(body));
    } catch (error) {
      dispatch(loadingData("team", false));
      console.log(error);
      return;
    }
  };
}

export function getTeamMembers() {
  return async (dispatch, getState) => {
    dispatch(loadingData("teamMembers", true));
    let response;
    try {
      const url = `${SessionManager.getUnifiedApiEndpoint()}/team/members?includeInvites=true`;
      response = await doGetTeamMembers(
        url,
        getState().auth.sessions.sessionData.accessToken,
        fetch
      );
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("teamMembers", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("teamMembers", false));
      dispatch(setTeamMembers(body));
    } catch (error) {
      dispatch(loadingData("teamMembers", false));
      console.log(error);
      return;
    }
  };
}

export async function doGetTeamMembers(url, token, fetcher) {
  return await fetcher(url, {
    method: "GET",
    headers: {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

export function inviteTeamMember(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("inviteMember", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/invite`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        const res = await response.json();
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("inviteTeamMember", false));
        }
        if (response.status === 429 || response.status === 400) {
          dispatch(setInviteError(res));
          dispatch(loadingData("inviteMember", false));
        }
        if (response.status === 500) {
          dispatch(
            setInviteError({
              error: {
                message: "Unexpected response status code 500, please try again"
              }
            })
          );
        } else {
          dispatch(setInviteError(res));
        }
        return;
      }
      dispatch(loadingData("inviteMember", false));
      dispatch(displayModal("inviteTeamMember", false));
      dispatch(getTeamMembers());
    } catch (error) {
      dispatch(loadingData("inviteMember", false));
      console.log(error);
      return;
    }
  };
}

export function reInviteTeamMember(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("inviteMember", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/invite`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("confirmReinvite", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("inviteMember", false));
      dispatch(displayModal("confirmReinvite", false));
      dispatch(getTeamMembers());
    } catch (error) {
      dispatch(loadingData("inviteMember", false));
      console.log(error);
      return;
    }
  };
}

export function removeInvitation(inviteId) {
  return async (dispatch, getState) => {
    dispatch(loadingData("removeInvitation", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/invite/${encodeURIComponent(
        inviteId
      )}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        const res = await response.json();
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        if (response.status === 404) {
          dispatch(setRemoveInviteError(res));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("removeInvitation", false));
      dispatch(displayModal("confirmRemoveInvite", false));
      dispatch(getTeamMembers());
    } catch (error) {
      dispatch(loadingData("removeInvitation", false));
      console.log(error);
      return;
    }
  };
}

export function removeTeamMember(memberId) {
  return async (dispatch, getState) => {
    dispatch(loadingData("removeMember", true));
    let response;
    try {
      const url = `${SessionManager.getUnifiedApiEndpoint()}/team/member?id=${memberId}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("confirmRemoveMember", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("removeMember", false));
      dispatch(displayModal("confirmRemoveMember", false));
      dispatch(getTeamMembers());
    } catch (error) {
      dispatch(loadingData("removeMember", false));
      console.log(error);
      return;
    }
  };
}

// INDIVIDUAL TEAM POLICY CALLS

export function getTeamPolicy(policyId) {
  return async (dispatch, getState) => {
    if (!policyId) {
      return;
    }
    dispatch(loadingData("viewPolicy", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("1")}/policy/${policyId}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("viewPolicy", false));
      dispatch(setTeamPolicyData(body.policy));
    } catch (error) {
      dispatch(loadingData("viewPolicy", false));
      console.log(error);
      return;
    }
  };
}

export function updateTeamPolicy(policyId, payload) {
  return async (dispatch, getState) => {
    if (!policyId) {
      return;
    }
    dispatch(loadingData("viewPolicy", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("1")}/policy/${policyId}`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      await response.json();
      dispatch(loadingData("viewPolicy", false));
      dispatch(showSavedToast(true));
      dispatch(getTeamPolicy(payload.id));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (error) {
      dispatch(loadingData("viewPolicy", false));
      console.log(error);
      return;
    }
  };
}

// INDIVIDUAL TEAM MEMBER CALLS

export function getTeamMember(memberId) {
  return async (dispatch, getState) => {
    if (!memberId) {
      return;
    }
    dispatch(loadingData("viewMember", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/member/${memberId}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("viewMember", false));
          dispatch(forbiddenData("viewMember", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("viewMember", false));
      dispatch(forbiddenData("viewMember", false));
      dispatch(setTeamMemberData(body));
    } catch (error) {
      dispatch(loadingData("viewMember", false));
      console.log(error);
      return;
    }
  };
}

export function updateTeamMember(memberId, payload, callback) {
  return async (dispatch, getState) => {
    if (!memberId) {
      return;
    }

    payload["id"] = memberId;
    dispatch(loadingData("updateMember", true));
    let response;
    try {
      const url = `${SessionManager.getUnifiedApiEndpoint()}/team/member`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("updateMember", false));
      dispatch(getTeamMembers());
      callback();
    } catch (error) {
      dispatch(loadingData("updateMember", false));
      console.log(error);
      return;
    }
  };
}

export function disableTeamMemberMfa(memberId, callback) {
  return async (dispatch, getState) => {
    if (!memberId) {
      return;
    }

    const payload = {
      id: memberId,
      isMFAEnabled: false
    };
    dispatch(loadingData("disableTeamMemberMfa", true));
    let response;
    try {
      const url = `${SessionManager.getUnifiedApiEndpoint()}/team/member`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("disableTeamMemberMfa", false));
      dispatch(getTeamMembers());
      callback();
    } catch (error) {
      dispatch(loadingData("disableTeamMemberMfa", false));
      console.log(error);
      return;
    }
  };
}

export function getPendingInvite(inviteId) {
  return async (dispatch, getState) => {
    if (!inviteId) {
      return;
    }
    dispatch(loadingData("viewMember", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/invite/${encodeURIComponent(
        inviteId
      )}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("viewMember", false));
          dispatch(forbiddenData("viewMember", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("viewMember", false));
      dispatch(forbiddenData("viewMember", false));
      dispatch(setTeamMemberData(body));
    } catch (error) {
      dispatch(loadingData("viewMember", false));
      console.log(error);
      return;
    }
  };
}

export function updatePendingInvitation(inviteId, payload, callback) {
  return async (dispatch, getState) => {
    if (!inviteId) {
      return;
    }
    dispatch(loadingData("updateMember", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/invite/${encodeURIComponent(
        inviteId
      )}`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("updateMember", false));
      dispatch(getTeamMembers());
      callback();
    } catch (error) {
      dispatch(loadingData("updateMember", false));
      console.log(error);
      return;
    }
  };
}

// TOKENS CALLS

export function getTeamTokens() {
  return async (dispatch, getState) => {
    dispatch(loadingData("teamTokens", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/tokens`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("teamTokens", false));
          dispatch(forbiddenData("teamTokens", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("teamTokens", false));
      dispatch(setTeamTokens(body));
    } catch (error) {
      dispatch(loadingData("teamTokens", false));
      console.log(error);
      return;
    }
  };
}

// SERVICE ACCOUNT CALLS

export function createNewServiceAccount(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("createServiceAccount", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/serviceaccount`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("newServiceAccountModal", false));
        }
        if (response.status === 409) {
          dispatch(
            setNewServiceAccountErr(
              `Service Account name ${payload.name} is already in use for this team`
            )
          );
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const res = await response.json();
      dispatch(loadingData("createServiceAccount", false));
      dispatch(setNewServiceAccount(res.access_token));
      dispatch(getServiceAccounts());
    } catch (error) {
      dispatch(loadingData("createServiceAccount", false));
      console.log(error);
      return;
    }
  };
}

export function getServiceAccounts() {
  return async (dispatch, getState) => {
    dispatch(loadingData("serviceAccounts", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/serviceaccounts`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("serviceAccounts", false));
          dispatch(forbiddenData("serviceAccounts", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(loadingData("serviceAccounts", false));
      dispatch(setServiceAccounts(body));
    } catch (error) {
      dispatch(loadingData("serviceAccounts", false));
      console.log(error);
      return;
    }
  };
}

export function removeServiceAccount(name) {
  return async (dispatch, getState) => {
    dispatch(loadingData("removeServiceAccount", true));
    let response;
    try {
      const payload = {
        name: name
      };

      const url = `${SessionManager.getApiEndpoint()}/team/serviceaccount`;
      response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("confirmRemoveServiceAccount", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("removeServiceAccount", false));
      dispatch(displayModal("confirmRemoveServiceAccount", false));
      dispatch(getServiceAccounts());
    } catch (error) {
      dispatch(loadingData("removeServiceAccount", false));
      console.log(error);
      return;
    }
  };
}

export function getGoogleAuthenticationSettings() {
  return async (dispatch, getState) => {
    dispatch(loadingData("googleAuthenticationSettings", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/auth/google`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setGoogleAuthenticationSettings(body));
      dispatch(loadingData("googleAuthenticationSettings", false));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function updateGoogleAuthenticationSettings(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("googleAuthenticationSettingsUpdated", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/auth/google`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        if (response.status < 500) {
          const json = await response.json();
          throw new Error(json.error.message);
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setGoogleAuthenticationSettings(body));
      dispatch(loadingData("googleAuthenticationSettingsUpdated", false));
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export function updateSamlAuthenticationSettings(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("samlAuthenticationSettingsUpdated", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/auth/saml`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        if (response.status === 400) {
          const err = await response.json();
          dispatch(
            setSamlAuthenticationSettingsError({
              errorMsg: err.message ? err.message : "An unknown error occurred"
            })
          );
          return;
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(getSamlAuthenticationSettings());
      dispatch(loadingData("samlAuthenticationSettingsUpdated", false));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function getSamlAuthenticationSettings() {
  return async (dispatch, getState) => {
    dispatch(loadingData("samlAuthenticationSettings", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/auth/saml`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setSamlAuthenticationSettings(body));
      dispatch(loadingData("samlAuthenticationSettings", false));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function updateTeamPasswordPolicies(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("teamPasswordPoliciesUpdated", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/security`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(getTeamPasswordPolicies());
        }
        if (response.status === 400) {
          const body = await response.json();
          if (body?.error?.message) {
            throw new Error(body?.error?.message);
          }
        }
        dispatch(loadingData("teamPasswordPoliciesUpdated", false));
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(showSavedToast(true));
      dispatch(getTeamPasswordPolicies());
      dispatch(loadingData("teamPasswordPoliciesUpdated", false));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function getTeamPolicies() {
  return async (dispatch, getState) => {
    dispatch(loadingData("teamPolicies", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("1")}/policies`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(forbiddenData("teamPolicies", true));
          dispatch(loadingData("teamPolicies", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setTeamPolicies(body.policies));
      dispatch(loadingData("teamPolicies", false));
      dispatch(forbiddenData("teamPolicies", false));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function createTeamPolicy(payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("createTeamPolicy", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("1")}/policy`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("createPermissionsPolicy", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      await response.json();
      dispatch(loadingData("createTeamPolicy", false));
      dispatch(getTeamPolicies());
      dispatch(displayModal("createPermissionsPolicy", false));
    } catch (error) {
      dispatch(loadingData("createTeamPolicy", false));
      console.log(error);
      return;
    }
  };
}

export function removeTeamPolicy(policyId) {
  return async (dispatch, getState) => {
    dispatch(loadingData("removeTeamPolicy", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("1")}/policy/${policyId}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("confirmRemovePolicy", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("removeTeamPolicy", false));
      dispatch(getTeamPolicies());
      dispatch(displayModal("confirmRemovePolicy", false));
    } catch (error) {
      console.log(error);
      if (response.status !== 403) {
        dispatch(
          setSessionError({
            error: {
              message:
                "Policy is currently applied to a team member, and can't be deleted."
            }
          })
        );
      }
      dispatch(loadingData("removeTeamPolicy", false));
      return;
    }
  };
}

export function getTeamPasswordPolicies() {
  return async (dispatch, getState) => {
    dispatch(loadingData("teamPasswordPolicies", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/security`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setSecuritySettings(body));
      dispatch(loadingData("teamPasswordPolicies", false));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function removeTeamToken(token) {
  return async (dispatch, getState) => {
    dispatch(loadingData("removeToken", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/token/${token}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
          dispatch(displayModal("confirmRemoveToken", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("removeToken", false));
      dispatch(displayModal("confirmRemoveToken", false));
      dispatch(getTeamTokens());
    } catch (error) {
      dispatch(loadingData("removeToken", false));
      console.log(error);
      return;
    }
  };
}

export function listTeamEntitlements() {
  return async (dispatch, getState) => {
    dispatch(loadingData("listTeamEntitlements", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/team/entitlements`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setTeamEntitlements(body));
      dispatch(loadingData("listTeamEntitlements", false));
    } catch (error) {
      dispatch(loadingData("listTeamEntitlements", false));
      console.log(error);
      return;
    }
  };
}
