import { connect } from "react-redux";
import realPasswordReset from "../auth/PasswordReset";

import {
  resetPassword,
  clearServerError,
  setResetPasswordStep,
  sendPasswordResetEmail,
  clearExpiredTokenError
} from "../../redux/auth/sessions/actions";
import { displayModal, loadingData } from "../../redux/ui/main/actions";

const PasswordReset = connect(
  state => ({
    modals: state.ui.main.modals,
    err: state.auth.sessions.sessionData.err,
    errMessage: state.auth.sessions.sessionData.errMessage,
    dataLoading: state.ui.main.loading,
    passwordResetStep: state.auth.sessions.sessionData.passwordResetStep,
    errToken: state.auth.sessions.sessionData.errToken,
    errTokenMessage: state.auth.sessions.sessionData.errTokenMessage,
    resetPasswordStep: state.auth.sessions.sessionData.resetPasswordStep
  }),
  dispatch => ({
    resetPassword(payload) {
      return dispatch(resetPassword(payload));
    },
    sendPasswordResetEmail(payload) {
      return dispatch(sendPasswordResetEmail(payload));
    },
    setResetPasswordStep(step) {
      return dispatch(setResetPasswordStep(step));
    },
    clearServerError() {
      return dispatch(clearServerError());
    },
    clearExpiredTokenError() {
      return dispatch(clearExpiredTokenError());
    },
    loadingData(key, isLoading) {
      return dispatch(loadingData(key, isLoading));
    },
    displayModal(key, display) {
      return dispatch(displayModal(key, display));
    }
  })
)(realPasswordReset);

export default PasswordReset;
