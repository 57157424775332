import { useMutation, useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { SessionManager } from "@/services/SessionManager";
import { DataLoading } from "../../types";
import { VendorUtilities } from "../../utilities/VendorUtilities";
import { Button } from "../shared/Button";
import { Icon } from "../shared/Icon";
import Loader from "../shared/Loader";
import {
  Divider,
  LeftTeamCard,
  OrBlock,
  SubHeader,
  TeamCard,
  TeamMembers,
  TeamName,
  Title,
  UpperTeamcard
} from "./TeamSelection.styled";
import { joinTeam } from "./api/joinTeam";
import { getAvailableTeams } from "./api/teamSelect";

interface TeamSelectionProps extends RouteComponentProps {
  handleInviteSignUp: (
    acceptInviteRequest: {
      inviteFromTeamSelection: boolean;
    },
    history: RouteComponentProps["history"],
    inviteId: string,
    callback: () => void
  ) => void;
  registerTeam: (
    payload: Record<string, unknown>,
    history: RouteComponentProps["history"]
  ) => void;
  dataLoading: DataLoading;
}

const TeamSelection = ({
  history,
  handleInviteSignUp,
  registerTeam,
  dataLoading
}: TeamSelectionProps) => {
  const [inviteLoading, setInviteLoading] = useState(false);
  const { isLoading, data } = useQuery({
    queryKey: ["selectTeam"],
    queryFn: getAvailableTeams
  });
  const {
    isLoading: joinTeamLoading,
    error: joinTeamError,
    mutate,
    data: joinData
  } = useMutation({ mutationFn: joinTeam, retry: 0 });

  const handleInvite = async (inviteId: string) => {
    setInviteLoading(true);
    if (!VendorUtilities.isGoogleSignup()) {
      const inviteFromTeamSelectionParam = "?invite_from_team_selection=1";
      history.push(`/invite${inviteFromTeamSelectionParam}#${inviteId}`);
      return;
    }

    const acceptInviteRequest = {
      inviteFromTeamSelection: true
    };
    const callback = () => {
      history.push("/apps");
    };
    await handleInviteSignUp(acceptInviteRequest, history, inviteId, callback);
    window.localStorage.setItem("isAfterInvite", "true");
    setInviteLoading(false);
  };

  const handleJoin = async (e: SyntheticEvent, invite = false) => {
    const element = e.target as HTMLButtonElement;
    if (invite === true) {
      if (inviteLoading) return;
      handleInvite(element.id);
      return;
    } else {
      if (joinTeamLoading) return;
      // else fetch the team and get an invite id, then forward them to the invite accept page
      const payload = {
        team_id: element.id
      };
      mutate(payload);
    }
  };

  const handleCreateTeam = () => {
    const isGoogleSignup = VendorUtilities.isGoogleSignup();
    if (!isGoogleSignup) {
      history.push("/create-team");
      return;
    }
    if (!dataLoading?.registerLoading) {
      registerTeam({}, history);
    }
  };

  useEffect(() => {
    if (data?.error?.message === "Unauthorized") {
      // if someone visits this page manually/out of order, redirect to signup
      history.push("/signup");
    }

    // if user has no auto-join or invited teams to join, redirect to create team
    if (
      !isLoading &&
      !data?.invited_teams?.length &&
      !data?.auto_join_enabled_teams?.length
    ) {
      const isGoogleSignup = VendorUtilities.isGoogleSignup();
      if (!isGoogleSignup) {
        history.push("/create-team");
        return;
      }
      registerUser();
    }
  }, [isLoading, data]);

  const registerUser = () => {
    if (!dataLoading?.registerLoading) {
      registerTeam({}, history);
    }
  };

  useEffect(() => {
    // if the user has successfully joined a team and gotten an invite id back,
    // handle the invite signup and redirect them
    if (joinData?.invite_id) {
      handleInvite(joinData.invite_id);
      return;
    }
    if (joinTeamError) {
      console.log("Error joining team: ", joinTeamError);
    }
  }, [joinData]);

  // if data is still loading or is null, return null until we can do redirect logic
  if (
    isLoading ||
    (!data?.invited_teams?.length && !data?.auto_join_enabled_teams?.length)
  )
    return null;

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-bg-page-bg">
      <BasicNavBar />
      <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-overflow-auto">
        <div className="tw-flex tw-flex-col tw-max-w-[400px] tw-items-center tw-gap-5 tw-py-9 tw-mx-3 tw-h-full tw-box-border">
          <Title className="!tw-text-gray-800 tw-text-center">
            Create or join a team
          </Title>
          {data?.invited_teams?.length > 0 && (
            <>
              <SubHeader>You have been invited to join a team</SubHeader>
              {data.invited_teams
                .sort((a, b) => {
                  return a.user_names.length < b.user_names.length;
                })
                .map(team => {
                  return (
                    <TeamCard key={team.id}>
                      <UpperTeamcard>
                        <LeftTeamCard>
                          <TeamName>{team.name}</TeamName>
                        </LeftTeamCard>
                        {joinTeamLoading ? (
                          <Loader size="30" />
                        ) : (
                          <Button
                            kind="primary-purple"
                            id={team.invite_id}
                            onClick={e => handleJoin(e, true)}
                          >
                            Join
                          </Button>
                        )}
                      </UpperTeamcard>
                      <TeamMembers>{team.user_names.length} members</TeamMembers>
                    </TeamCard>
                  );
                })}
            </>
          )}
          {data?.auto_join_enabled_teams?.length > 0 && (
            <>
              <SubHeader>
                {data?.auto_join_enabled_teams?.length === 1
                  ? "We found a team that you are able to join"
                  : "We found teams that you are able to join"}
              </SubHeader>
              {data.auto_join_enabled_teams
                .sort((a, b) => {
                  return a.user_names.length < b.user_names.length;
                })
                .map(team => {
                  return (
                    <TeamCard key={team.id}>
                      <UpperTeamcard>
                        <LeftTeamCard>
                          <TeamName>{team.name}</TeamName>
                        </LeftTeamCard>
                        {joinTeamLoading ? (
                          <Loader size="30" />
                        ) : (
                          <Button
                            kind="primary-purple"
                            id={team.id}
                            onClick={e => handleJoin(e)}
                          >
                            Join
                          </Button>
                        )}
                      </UpperTeamcard>
                      <TeamMembers>{team.user_names.length} members</TeamMembers>
                    </TeamCard>
                  );
                })}
            </>
          )}
          <OrBlock>
            <Divider />
            or
            <Divider />
          </OrBlock>
          {dataLoading?.registerLoading ? (
            <div className="flex-column flex1 u-overflow--hidden justifyContent--center alignItems--center">
              <Loader size="48" />
            </div>
          ) : (
            <Button kind="primary-purple" size="large" onClick={handleCreateTeam}>
              Create a new team
            </Button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Footer = () => (
  <footer className="FooterContent-wrapper tw-flex tw-items-end">
    <div className={"container"}>
      <div className="tw-flex tw-justify-center tw-items-center">
        <div className="FooterItem-wrapper">
          <Link to="/privacy" target="_blank" className="FooterItem">
            Privacy policy
          </Link>
        </div>
        <div className="FooterItem-wrapper">
          <Link to="/terms" target="_blank" className="FooterItem">
            Terms of service
          </Link>
        </div>
        <div className="FooterItem-wrapper">
          <span className="FooterItem">{SessionManager.getBuildVersion()}</span>
        </div>
      </div>
    </div>
  </footer>
);

const BasicNavBar = () => (
  <header className="tw-w-full flex-column">
    <div className="NavBarWrapper tw-px-5 tw-py-3 tw-box-border flex flex-1 justifyContent--flexStart alignItems--center">
      <div className="HeaderLogo-wrapper u-position--relative">
        <Link to="/" className="HeaderLogo">
          <Icon name="replicatedLogo" width="150" height="34" />
        </Link>
      </div>
    </div>
  </header>
);

export default withRouter(TeamSelection);
