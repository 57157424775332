import {
  SET_ACTIVE_RELEASE_LIST,
  SET_RELEASE_LIST
} from "../../../../data/main/apps/releases/actions";
import { SET_CHANNEL_LIST } from "../../../../data/main/apps/channels/actions";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";
import type { Action } from "@/types";

const initialState = {
  releaseList: [],
  activeReleaseList: [],
  basicChannelData: [],
  headerItems: [
    {
      label: "",
      style: { maxWidth: "20px" }
    },
    {
      label: "Sequence",
      style: { maxWidth: "70px" }
    },
    {
      label: "Created On",
      style: { maxWidth: "250px" }
    },
    {
      label: "Active In",
      style: { maxWidth: "382px", boxSizing: "border-box" }
    },
    {
      label: "Preflight Checks",
      style: { maxWidth: "none" }
    },
    {
      label: "Actions",
      style: { maxWidth: "120px" }
    }
  ]
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_RELEASE_LIST: {
      const _releaseList = Object.assign({}, action.payload.releases);
      return Object.assign({}, state, {
        releaseList: map(_releaseList, "Sequence")
      });
    }
    case SET_ACTIVE_RELEASE_LIST: {
      const _activeReleaseList = Object.assign({}, action.payload.activeReleases);
      return Object.assign({}, state, {
        activeReleaseList: map(_activeReleaseList, "Sequence")
      });
    }
    case SET_CHANNEL_LIST: {
      const _channelList = Object.assign({}, action.payload);
      const sortedChannelList = sortBy(_channelList, "Position");
      const channelData = sortedChannelList.map(c => ({
        Id: c.Id,
        Name: c.Name,
        ReleaseSequence: c.ReleaseSequence
      }));
      return Object.assign({}, state, {
        basicChannelData: keyBy(channelData, "Id")
      });
    }
    default:
      return state;
  }
}
