import { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import Loader from "../shared/Loader";
import isEmpty from "lodash/isEmpty";
import Modal from "react-modal";
import ModalHeader from "../modals/ModalHeader";
import ModalBody from "../modals/ModalBody";

import "../../scss/components/account/AccountSettings.scss";
import { Button } from "../shared/Button";

class RequireTwoFactor extends Component {
  constructor() {
    super();

    this.state = {
      password: "",
      twoFACode: "",
      showSecret: false,
      showRecoveryCodes: true
    };
  }

  handleFormChange = (field, e) => {
    if (this.props.twoFactorServerErr) {
      this.props.clearServerError();
    }
    let nextState = {};
    nextState[field] = e.target.value;
    this.setState(nextState);
  };

  showSecret = () => {
    this.setState({
      showSecret: true
    });
  };

  confirmCode = async () => {
    const payload = {
      code: this.state.twoFACode.trim()
    };
    await this.props.finalizeCode(payload).then(() => {
      this.setState({ displayRecoveryCodeModal: true });
    });
  };

  closeRecoveryCodeModal = () => {
    this.setState({ displayRecoveryCodeModal: true });
    this.props.clearRecoveryCodes();
    this.props.history.push("/login");
  };

  onTurnOnClick = () => {
    this.props.confirmPassword({}, true);
  };

  handleCancel = () => {
    this.props.setTwoFactorStep("main");
  };

  buildRecoveryCodes = () => {
    return this.props.recoveryCodes.map((code, i) => (
      <p
        key={i}
        className="u-marginBottom--normal recoveryCode u-fontSize--small u-lineHeight--more"
      >
        {code}
      </p>
    ));
  };

  componentDidUpdate(lastProps) {
    if (this.props.twoFactorStep !== lastProps.twoFactorStep) {
      this.setState({ twoFACode: "" });
      if (this.props.twoFactorStep === "configure") {
        setTimeout(() => {
          this.refCodeInput.focus();
        }, 50);
      }
    }
    if (
      this.props.twoFactorIsEnabled !== lastProps.twoFactorIsEnabled &&
      this.props.twoFactorIsEnabled
    ) {
      this.setState({
        showRecoveryCodes: true
      });
    }
  }

  componentDidMount() {
    if (this.props.twoFactorStep !== "main") {
      this.props.setTwoFactorStep("main");
    }
    this.props.checkTwoFactor();
  }

  render() {
    const { dataLoading } = this.props;

    return (
      <div className="flex flex1 alignItems--center justifyContent--center bg-light-blue u-overflow--auto auth-pages">
        <div className="flex-column flex-auto fix-mobile-overflow">
          {dataLoading.twoFactorLoading ? (
            <div className="flex flex1 justifyContent--center alignItems--center">
              <Loader size="80" />
            </div>
          ) : (
            <div className="flex">
              {this.props.twoFactorStep === "main" ? (
                <div className="RequireTwoFactor--wrapper u-textAlign--center alignItems--center">
                  <span className="icon u-requireTFAIcon u-marginBottom--most"></span>
                  <p className="form-title-center u-color--blue u-marginBottom--more u-textAlign--center">
                    You must enable Two-Factor authentication
                  </p>
                  <div className="flex-column">
                    <p className="form-subhead u-color--dustyGray u-lineHeight--more">
                      Your organization has required that Two-Factor authentication be
                      enabled for all accounts. In order to log in and use the Vendor
                      Portal you must enable Two-Factor auth.
                    </p>
                    <p className="form-subhead u-color--dustyGray u-lineHeight--more u-marginTop--normal">
                      With two-factor authentication (2fa) enabled, you’ll be asked to
                      provide an authentication code as well as your password, when you
                      access the Replicated vendor portal.
                    </p>

                    <div
                      className={classNames("u-marginTop--more", {
                        "u-display--none": this.props.twoFactorIsEnabled
                      })}
                    >
                      <div className="FormButton-wrapper-100">
                        <Button
                          kind="primary-purple"
                          size="large"
                          onClick={this.onTurnOnClick}
                        >
                          Enable Two-Factor authentication
                        </Button>
                      </div>
                      <p className="u-color--dustyGray u-fontSize--small u-marginTop--none">
                        <a
                          href="https://help.replicated.com/docs/kb/supporting-your-customers/set-up-2fa/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="u-color--blue u-fontWeight--medium"
                        >
                          Learn more
                        </a>{" "}
                        about Two-Factor authentication.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="RequireTwoFactor--wrapper u-textAlign--center">
                  <span className="icon u-requireTFAIcon u-marginBottom--most"></span>
                  <p className="form-title u-color--blue u-marginBottom--more">
                    You must enable Two-Factor authentication
                  </p>
                  <div className="flex-column flex1">
                    <p className="form-subhead u-color--dustyGray u-lineHeight--normal u-marginTop--more">
                      Before continuing, you will need to install a supported two-factor
                      authentication app on your device. Visit our{" "}
                      <a
                        href="https://help.replicated.com/docs/kb/supporting-your-customers/set-up-2fa/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="u-color--blue u-fontWeight--medium"
                      >
                        Help Center
                      </a>{" "}
                      to get additional help.
                    </p>
                    <div className="flex flex-auto u-marginTop--most">
                      <div className="flex-column flex-auto u-marginRight--normal qr-code-wrapper">
                        <img src={this.props.qrCodeUrl} style={{ width: "200px" }} />
                      </div>
                      <div className="flex1 u-textAlign--left">
                        <p className="form-subhead u-lineHeight--normal u-color--doveGray">
                          1. Scan the QR Code with your app
                        </p>
                        <p className="u-fontSize--small u-fontWeight--normal u-color--dustyGray u-lineHeight--normal u-marginTop--small">
                          {!this.state.showSecret ? (
                            <span>
                              Can't use the QR code?{" "}
                              <span
                                className="u-color--blue u-font-weight--medium u-textDecoration--underlineOnHover"
                                onClick={this.showSecret}
                              >
                                Use this text code
                              </span>{" "}
                              instead
                            </span>
                          ) : (
                            <span>
                              Enter this code in your app:{" "}
                              <span className="u-fontWeight--medium u-fontSize--large u-color--vidaLoca">
                                {this.props.secret}
                              </span>
                            </span>
                          )}
                        </p>
                        <div className="u-marginTop--normal">
                          <p className="form-subhead u-lineHeight--normal u-color--doveGray">
                            2. Enter the code your app generated
                          </p>
                          {this.props.twoFactorServerErr &&
                          this.props.twoFactorStep === "configure" ? (
                            <div className="ErrorBlock u-marginTop--normal u-marginBottom--small">
                              <p>{this.props.twoFactorServerErrMessage}</p>
                            </div>
                          ) : null}
                          <input
                            className="Input u-marginTop--more"
                            placeholder="123456"
                            ref={input => (this.refCodeInput = input)}
                            value={this.state.twoFACode}
                            onChange={e => {
                              this.handleFormChange("twoFACode", e);
                            }}
                          />
                          <div className="flex">
                            <Button
                              kind="secondary-purple"
                              className="tw-mt-3 tw-mr-1.5"
                              onClick={() => {
                                this.handleCancel();
                              }}
                              disabled={dataLoading.authCodeLoading}
                            >
                              Cancel
                            </Button>
                            <Button
                              kind="primary-purple"
                              className="tw-mt-3"
                              onClick={this.confirmCode}
                              disabled={
                                dataLoading.authCodeLoading ||
                                this.state.twoFACode === ""
                              }
                            >
                              {dataLoading.authCodeLoading
                                ? "Authenticating"
                                : "Continue"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.displayRecoveryCodeModal && (
          <Modal
            isOpen={this.state.displayRecoveryCodeModal}
            ariaHideApp={false}
            contentLabel="Recovery Codes Modal"
            className="auth-pages Modal DefaultSize"
            onRequestClose={this.closeRecoveryCodeModal}
          >
            <ModalHeader
              title="Your recovery codes"
              requestClose={this.closeRecoveryCodeModal}
            />
            <ModalBody>
              {!isEmpty(this.props.recoveryCodes) ? (
                <div>
                  <p className="u-fontSize--normal u-marginBottom--small u-textAlign--center u-color--dustyGray u-lineHeight--normal">
                    If you lose access to your supported 2FA app, use your recovery
                    codes to log in to your account.
                  </p>
                  <div className="">
                    <div className="flex u-marginTop--normal">
                      <span className="icon u-warningIcon alignSelf--center u-paddingRight--normal"></span>
                      <p className="u-fontSize--small u-color--dustyGray u-lineHeight--more u-paddingLeft--small">
                        Keep these codes in a secure location. Codes can only be used
                        once, and these codes will not be shown later.
                      </p>
                    </div>
                    <div className="u-marginTop--more u-textAlign--center">
                      {this.buildRecoveryCodes()}
                    </div>
                    <p className="u-fontSize--normal u-marginTop--normal u-textAlign--center u-lineHeight--more u-color--dustyGray">
                      Codes cannot be displayed again after leaving the page. Store them
                      well.
                    </p>
                  </div>
                  <div className="u-textAlign--center u-marginTop--normal">
                    <Button kind="primary-purple" onClick={this.closeRecoveryCodeModal}>
                      Ok got it!
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex flex1 justifyContent--center alignItems--center">
                  <Loader size="80" />
                </div>
              )}
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(RequireTwoFactor);
