import ReactMarkdown from "react-markdown";
import * as R from "remeda";
import InlineCode from "@/components/shared/InlineCode";

interface MarkdownProps {
  text: string;
  openLinksInNewTab?: boolean;
  inline?: boolean;
}

const Markdown = ({ text, openLinksInNewTab, inline = true }: MarkdownProps) => (
  <ReactMarkdown
    allowedElements={["a", "p", "strong", "em", "code"]}
    components={{
      a: ({ ...props }) => (
        <a
          className="tw-text-blue-300 hover:tw-underline tw-font-semibold"
          {...R.omit(props, ["node"])}
        />
      ),
      code: InlineCode,
      ...(inline ? { p: "span" } : {})
    }}
    {...(openLinksInNewTab ? { linkTarget: "_blank" } : {})}
    skipHtml
  >
    {text}
  </ReactMarkdown>
);

export default Markdown;
