import { Component } from "react";

import Loader from "../shared/Loader";
import { Button } from "../shared/Button";

export default function (importComponent) {
  return class AsyncClassWrapper extends Component {
    state = {
      component: null,
      loadingChunkError: false
    };

    componentDidMount() {
      importComponent()
        .then(cmp => {
          this.setState({ component: cmp.default });
        })
        .catch(error => {
          if (error) {
            this.setState({ loadingChunkError: true });
          }
          console.log(error);
        });
    }

    refreshPage = () => {
      window.location.reload();
    };

    render() {
      const C = this.state.component;

      if (!C && this.state.loadingChunkError) {
        return (
          <div className="flex1 flex-column u-overflow--auto">
            <div className="flex1 flex-column justifyContent--center alignItems--center">
              <div className="flex-column u-width--half">
                <span className="icon u-errorWarningIcon u-marginBottom--most u-textAlign--center alignSelf--center"></span>
                <p className="u-textAlign--center alignItems--center u-fontSize--header2 u-fontWeight--bold u-color--tundora">
                  Oops, something went wrong
                </p>
                <p className=" u-textAlign--center u-marginTop--more u-fontWeight--medium u-color--dustyGray u-fontSize--normal u-lineHeight--normal">
                  {" "}
                  We’ve notified our team of the error that occurred and will be working
                  to resolve the issue. We apologize for any inconvenience this may have
                  caused.{" "}
                </p>
                <div className="flex alignItems--center alignSelf--center u-marginTop--more">
                  <Button
                    kind="secondary-gray"
                    className="tw-mr-3"
                    onClick={this.refreshPage}
                  >
                    {" "}
                    Reload{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (!C) {
        return (
          <div className="flex flex1 justifyContent--center alignItems--center paddingContainer">
            <Loader size="70" />
          </div>
        );
      }

      return <C {...this.props} />;
    }
  };
}
