import { SET_SELECTED_APP } from "./actions";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_SELECTED_APP: {
      return action.payload || null;
    }
    default:
      return state;
  }
}
