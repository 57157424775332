import {
  CLEAR_NOTIFICATION_ERROR,
  CLEAR_RECOVERY_CODES,
  SET_CHANGEPASSWORD_ERROR,
  SET_NEW_USER_TOKEN,
  SET_NEW_USER_TOKEN_ERR,
  SET_NOTIFICATION_ERROR,
  SET_RECOVERY_CODES,
  SET_TWO_FACTOR_PROVISION,
  SET_TWO_FACTOR_STATUS,
  SET_TWO_FACTOR_STEP,
  SET_USER_NOTIFICATIONS,
  SET_USER_TOKENS
} from "./actions";
import {
  CLEAR_SERVER_ERROR,
  SET_ENFORCED_TWO_FACTOR,
  SET_SESSION_ERROR
} from "../../../auth/sessions/actions";
import map from "lodash/map";
import keyBy from "lodash/keyBy";

const passwordState = {
  err: false,
  errMessage: ""
};

export function changePassword(state = passwordState, action) {
  switch (action.type) {
    case CLEAR_SERVER_ERROR:
      return Object.assign({}, state, {
        err: false,
        errMessage: ""
      });
    case SET_CHANGEPASSWORD_ERROR:
      return Object.assign({}, state, {
        err: true,
        errMessage: action.payload.error.message
      });
    default:
      return state;
  }
}

const twoFactorState = {
  err: false,
  errMessage: "",
  isEnabled: false,
  isEnforced: false,
  step: "main",
  qrCodeUrl: "",
  secret: "",
  recoveryCodes: {}
};

export function twoFactorData(state = twoFactorState, action) {
  switch (action.type) {
    case SET_TWO_FACTOR_PROVISION: {
      const url = action.payload.qr_code;
      return Object.assign({}, state, {
        qrCodeUrl: url,
        secret: action.payload.secret
      });
    }
    case SET_TWO_FACTOR_STEP:
      return Object.assign({}, state, {
        step: action.payload
      });
    case SET_ENFORCED_TWO_FACTOR:
      return Object.assign({}, state, {
        isEnforced: action.payload
      });
    case SET_TWO_FACTOR_STATUS:
      return Object.assign({}, state, {
        isEnabled: action.payload.enabled,
        isEnforced: action.payload.enforced
      });
    case CLEAR_SERVER_ERROR:
      return Object.assign({}, state, {
        err: false,
        errMessage: ""
      });
    case SET_SESSION_ERROR:
      return Object.assign({}, state, {
        err: true,
        errMessage: action.payload.error?.message || JSON.stringify(action.payload)
      });
    case SET_RECOVERY_CODES:
      return Object.assign({}, state, {
        recoveryCodes: action.payload
      });
    case CLEAR_RECOVERY_CODES:
      return Object.assign({}, state, {
        recoveryCodes: {}
      });
    default:
      return state;
  }
}

const notificationState = {
  notificationErr: {
    hasErr: false,
    message: "",
    id: ""
  },
  userNotifications: {},
  userNotificationsList: []
};

export function notificationData(state = notificationState, action) {
  switch (action.type) {
    case SET_USER_NOTIFICATIONS: {
      const _notifications = Object.assign({}, action.payload);
      const keyedNotifications = keyBy(_notifications, "id");
      const mappedNotifications = map(_notifications, "id");
      return Object.assign({}, state, {
        userNotifications: keyedNotifications,
        userNotificationsList: mappedNotifications
      });
    }
    case SET_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        notificationErr: {
          hasErr: true,
          message: action.payload.error.message,
          id: action.payload.error.id
        }
      });
    case CLEAR_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        notificationErr: {
          hasErr: false,
          message: "",
          id: ""
        }
      });
    default:
      return state;
  }
}

const tokensState = {
  tokens: {},
  tokenList: [],
  newUserToken: null,
  newUserTokenErr: null,
  newUserTokenNonce: null,
  newUserTokenNonceErr: null
};

export function tokensData(state = tokensState, action) {
  switch (action.type) {
    case SET_USER_TOKENS: {
      const tokens = Object.assign({}, action.payload);
      const keyedTokens = keyBy(tokens, "name");
      const mappedTokens = map(tokens, "name");
      return Object.assign({}, state, {
        tokens: keyedTokens,
        tokenList: mappedTokens
      });
    }
    case SET_NEW_USER_TOKEN:
      return Object.assign({}, state, {
        newUserToken: action.payload
      });
    case SET_NEW_USER_TOKEN_ERR:
      return Object.assign({}, state, {
        newUserTokenErr: action.payload
      });
    default:
      return state;
  }
}
