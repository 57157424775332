import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// TODO: remove RestLink, it's unused
export function VendorClient(
  vendorEndpoint,
  tokenFunction,
  fetcher,
  unauthorizedErrHandler
) {
  const cache = new InMemoryCache({
    dataIdFromObject: object => object.key || null,
    addTypename: false
  });

  if (fetcher) {
    global.Headers = fetcher.Headers;
  }

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: tokenFunction(),
        "X-Replicated-Client": "vendor-web"
      }
    };
  });

  const restLink = new RestLink({
    uri: vendorEndpoint, // default uri
    endpoints: {
      vendor: vendorEndpoint
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        const unauthorized =
          message === "Unauthorized" || message.includes("Unknown session type");
        if (unauthorized) {
          if (unauthorizedErrHandler) {
            response.errors = null;
            unauthorizedErrHandler();
          }
        } else if (message === "Forbidden") {
          return message;
        } else {
          if (import.meta.env.VITE_NODE_ENV === "development") {
            console.log(
              "[GraphQL error]:",
              "Message:",
              message,
              "|",
              "Location:",
              locations,
              "|",
              "Path:",
              path
            );
          }
        }
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const link = ApolloLink.from([errorLink, authLink, restLink]);

  const client = new ApolloClient({
    link,
    cache: cache
  });

  return client;
}
