import "isomorphic-fetch";
import { loadingData, forbiddenData, errorData } from "../../ui/main/actions";
import { logOutUser } from "../../auth/sessions/actions";
import { SessionManager } from "../../../services/SessionManager";

export const SET_KOTS_APP_LIST = "SET_KOTS_APP_LIST";

function setKotsAppList(kotsApps) {
  return {
    type: SET_KOTS_APP_LIST,
    payload: {
      kotsApps: kotsApps
    }
  };
}

export function getKotsAppList() {
  return async (dispatch, getState) => {
    dispatch(loadingData("kotsAppList", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("3")}/apps?excludeChannels=true`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        },
        mode: "cors"
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("kotsAppList", false));
          dispatch(forbiddenData("kotsAppList", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setKotsAppList(body));
      dispatch(forbiddenData("kotsAppList", false));
      dispatch(loadingData("kotsAppList", false));
      dispatch(errorData("kotsAppList", false));
    } catch (error) {
      dispatch(loadingData("kotsAppList", false));
      dispatch(errorData("kotsAppList", true));
      console.log(error);
      return;
    }
  };
}
