import { Component } from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Popover from "../shared/Popover";
import PopoverItem from "../shared/PopoverItem";

const stopPropagation = e => e.stopPropagation();
export default class NavItem extends Component {
  static propTypes = {
    href: PropTypes.string,
    isActive: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isDropDownActive: PropTypes.bool,
    dropdownContent: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
    linkTo: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    options: PropTypes.object
  };
  static defaultProps = {
    className: "",
    onClick: () => {
      return;
    }
  };

  render() {
    const {
      href,
      isActive,
      icon,
      label,
      className,
      linkTo,
      onClick,
      options,
      dropdownContent,
      isDropDownActive
    } = this.props;
    const wrapperClassName = [
      "NavItem u-position--relative flex flex-column flex-verticalCenter",
      className,
      isActive ? "is-active" : ""
    ].join(" ");

    function linkify(linkContent, linkClassName, options = {}) {
      return linkTo ? (
        <Link className={linkClassName} to={linkTo} tabIndex="-1">
          {linkContent}
        </Link>
      ) : href ? (
        <a href={href} target="_blank" rel="noopener noreferrer" tabIndex="-1">
          {linkContent}
        </a>
      ) : options.isButton ? (
        <button className={`Button ${options.buttonClassName || ""}`} tabIndex="-1">
          {linkContent}
        </button>
      ) : (
        <a tabIndex="-1">{linkContent}</a>
      );
    }

    return (
      <div
        onClick={e => {
          onClick(e);
          stopPropagation(e);
        }}
        className={wrapperClassName}
      >
        <div
          className={`HeaderContent-wrapper flex0 ${
            isDropDownActive ? "active-dropdown" : ""
          }`}
        >
          {icon ? linkify(icon, "HeaderLink flex0") : null}
          {label ? linkify(label, "HeaderLink flex0", options) : null}
          {dropdownContent ? (
            <Popover
              minWidth="170"
              visible={isDropDownActive}
              onClick={stopPropagation}
              content={
                Array.isArray(dropdownContent) ? (
                  <ul className="PopoverItem-wrapper">
                    {dropdownContent
                      .filter(x => x)
                      .map((contents, index) => (
                        <PopoverItem key={`item-${index}`} {...contents} />
                      ))}
                  </ul>
                ) : (
                  dropdownContent
                )
              }
            />
          ) : null}
        </div>
      </div>
    );
  }
}
