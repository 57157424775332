import asyncComponent from "../hoc/asyncComponent";
import { VendorUtilities } from "../../utilities/VendorUtilities";

export const AsyncKotsChannelsList = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsChannelsList"));
});
export const AsyncKotsChannelHistory = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsChannelReleaseHistory"));
});
export const AsyncKubernetesChannelHistory = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KubernetesChannelHistory"));
});
export const AsyncKotsReleases = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsReleases"));
});
export const AsyncViewKotsRelease = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/ViewKotsRelease"));
});
export const AsyncViewKotsReleasesDiff = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../kots/ViewKotsReleasesDiff.jsx"));
});
export const AsyncKotsCustomers = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/Customers"));
});
export const AsyncKotsCreateNewCustomer = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsCreateNewCustomer"));
});
export const AsyncKotsCustomerManage = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsCustomerManage"));
});
export const AsyncKotsLicenseReporting = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsCustomerReporting"));
});
export const AsyncKotsInstanceDetails = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../licenses/KotsInstanceDetails"));
});
export const AsyncKotsLicenseFields = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../kots/KotsLicenseFields"));
});
export const AsyncKotsImages = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsImages"));
});
export const AsyncKubernetesInstaller = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KubernetesInstaller"));
});
export const AsyncViewKubernetesInstaller = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/ViewKubernetesInstaller"));
});
export const AsyncKotsAppSettings = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/KotsAppSettings"));
});
export const AsyncLicenseAuditLogPage = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/LicenseAuditLog"));
});
export const AsyncTroubleshootCollectors = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/TroubleshootCollectors"));
});
export const AsyncSupportBundleNewCollector = asyncComponent(() => {
  return VendorUtilities.retry(() =>
    import("../../components/troubleshoot/SupportBundleNewCollector")
  );
});
export const AsyncSupportBundleNewAnalyzer = asyncComponent(() => {
  return VendorUtilities.retry(() =>
    import("../../components/troubleshoot/SupportBundleNewAnalyzer")
  );
});
export const AsyncTroubleshootAnalyzers = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/TroubleshootAnalyzers"));
});
export const AsyncCustomDomains = asyncComponent(() => {
  return VendorUtilities.retry(() => import("./CustomDomains"));
});
export const AsyncKotsCustomerBundles = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../licenses/KotsCustomerBundles"));
});
export const AsyncViewBuildersRelease = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../kots/releases/ViewHelmOnlyRelease"));
});
export const AsyncAppDashboard = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../app_dashboard/AppDashboard"));
});
export const AsyncCompatibilityMatrix = asyncComponent(() => {
  return VendorUtilities.retry(() =>
    import("../compatibility_matrix/CompatibilityMatrix")
  );
});
export const AsyncAIModels = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../ai_models/AIModels"));
});
