import React from "react";
import styled from "styled-components";

interface InputProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputStyles?: React.CSSProperties;
  name: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  type?: string;
  value: string;
  widthAuto?: boolean;
}

const StyledInput = styled.input<InputProps>`
  display: block;
  box-sizing: border-box;
  width: ${props => (props.widthAuto ? "auto" : "100%")};
  height: 42px;
  border: 1px solid ${({ error, theme }) =>
    error ? theme.errorBright : theme.gray100};
  border-radius: 4px;
  outline: 0;
  font-size: 16px;
  line-height: normal;
  color: ${props => (props.disabled ? props.theme.gray500 : props.theme.gray600)};
  padding: 13px 14px;
  transition: border 0.2s;
  user-select: ${props => props.disabled && "none"};
  cursor: ${props => props.disabled && "not-allowed"};
  background-color: ${props => props.disabled && "#f8f8f8"}

  :focus {
    border: 1px solid ${props =>
      props.error ? props.theme.errorBright : props.theme.secondaryColor};
    }

  &:focus {
    border: 1px solid ${props =>
      props.error ? props.theme.errorBright : props.theme.secondaryColor};
    }

  ::placeholder {
    color: ${props => props.theme.gray200};
  }
`;

function Input({
  autoFocus = false,
  disabled = false,
  error = false,
  handleChange,
  inputStyles = {},
  name,
  placeholder,
  required = false,
  readOnly = false,
  type = "text",
  value,
  widthAuto = undefined
}: InputProps) {
  const common = {
    autoFocus,
    disabled,
    error,
    id: name,
    name,
    placeholder,
    required,
    readOnly,
    style: inputStyles,
    type,
    value,
    widthAuto
  };

  return <StyledInput {...common} onChange={handleChange} />;
}

export default Input;
