import { SHOW_SAVE_TOAST } from "./actions";
import type { Action } from "@/types";

const initialState = {
  categoryList: [],
  showSavedToast: false
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SHOW_SAVE_TOAST:
      return Object.assign({}, state, {
        showSavedToast: action.payload
      });
    default:
      return state;
  }
}
