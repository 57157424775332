import {
  CLEAR_SUPPORT_REQUEST_ERROR,
  CLEAR_SUPPORT_REQUEST_SUBMITTED,
  SET_SUPPORT_REQUEST_ERROR,
  SET_SUPPORT_TICKET_URL
} from "./actions";

const supportState = {
  newSupportTicketUrl: "",
  ticketSubmitted: false,
  err: false,
  errMessage: ""
};

export function supportData(state = supportState, action) {
  switch (action.type) {
    case SET_SUPPORT_TICKET_URL: {
      return Object.assign({}, state, {
        newSupportTicketUrl: action.payload,
        ticketSubmitted: true
      });
    }
    case CLEAR_SUPPORT_REQUEST_ERROR:
      return Object.assign({}, state, {
        err: false,
        errMessage: ""
      });
    case CLEAR_SUPPORT_REQUEST_SUBMITTED:
      return Object.assign({}, state, {
        newSupportTicketUrl: "",
        ticketSubmitted: false
      });
    case SET_SUPPORT_REQUEST_ERROR:
      return Object.assign({}, state, {
        err: true,
        errMessage: action.payload.message
      });
    default:
      return state;
  }
}
