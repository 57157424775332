import { Component } from "react";
import { withRouter } from "react-router-dom";
import Footer from "../shared/Footer";
import Loader from "../shared/Loader";

import "../../scss/components/auth/AuthPage.scss";
import { Button } from "../shared/Button";

class AccountUnlock extends Component {
  componentDidMount() {
    const unlockAccountParams = new URLSearchParams(this.props.location.search);
    const tokenParam = unlockAccountParams.get("t");
    this.props.unlockAccount({ unlock_token: tokenParam });
  }

  render() {
    return (
      <div className="u-minHeight--full u-width--full u-overflow--auto flex-column flex1 justifyContent--center alignItems--center">
        <div className="flex flex-1-auto u-width--full">
          {this.props.accountUnlockedStep === "success" ? (
            <div className="flex-1-auto u-width--full flex-column justifyContent--center alignItems--center">
              <p className="form-title u-textAlign--center u-color--blue u-lineHeight--normal">
                Account has been unlocked
              </p>
              <div className="flex justifyContent--center u-marginTop--more">
                <div className="FormButton-wrapper flex">
                  <Button
                    kind="primary-purple"
                    className="flex-auto tw-mt-2.5 tw-mb-5 tw-w-full !tw-text-lg"
                    onClick={() => this.props.history.push("/login")}
                  >
                    Go to login
                  </Button>
                </div>
              </div>
            </div>
          ) : this.props.err ? (
            <div className="flex-column flex1 u-width--full u-minHeight--full alignItems--center justifyContent--center">
              <div
                className="ErrorBlock u-display--inlineBlock u-textAlign--center"
                style={{ maxWidth: "250px" }}
              >
                <p className="u-lineHeight--normal">{this.props.errMessage}</p>
              </div>
            </div>
          ) : (
            <div className="flex-column flex1 u-width--full u-minHeight--full alignItems--center justifyContent--center">
              <Loader size="80" />
            </div>
          )}
        </div>
        <div className="auth-footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(AccountUnlock);
