import { combineReducers } from "redux";
import {
  teamsData,
  tokensData,
  serviceAccountsData,
  securityData,
  samlAuthenticationData,
  googleAuthenticationData,
  policiesData
} from "./reducer";

export default combineReducers({
  teamsData,
  tokensData,
  serviceAccountsData,
  securityData,
  samlAuthenticationData,
  googleAuthenticationData,
  policiesData
});
