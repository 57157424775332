import { SET_LICENSE_FIELDS_LIST } from "../../../../data/main/apps/license_fields/actions";
import map from "lodash/map";
import type { Action } from "@/types";

const initialState = {
  licenseFieldList: [],
  headerItems: [
    {
      label: "Field",
      style: { maxWidth: "300px" }
    },
    {
      label: "Title",
      style: { maxWidth: "300px" }
    },
    {
      label: "Type",
      style: { maxWidth: "60px" }
    },
    {
      label: "Default",
      style: { maxWidth: "145px" }
    },
    {
      label: "Required",
      style: { maxWidth: "60px" }
    },
    {
      label: "Hidden",
      style: { maxWidth: "60px" }
    },
    {
      label: "",
      style: { maxWidth: "none" }
    }
  ]
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_LICENSE_FIELDS_LIST: {
      const _licenseFields = Object.assign({}, action.payload);
      return Object.assign({}, state, {
        licenseFieldList: map(_licenseFields, "name")
      });
    }
    default:
      return state;
  }
}
