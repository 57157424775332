import orderBy from "lodash/orderBy";
import map from "lodash/map";
import { RECEIVE_APP_LIST } from "../../../data/main/apps/actions";

export function appList(state = [], action) {
  switch (action.type) {
    case RECEIVE_APP_LIST: {
      const _appList = Object.assign({}, action.payload);
      const sortedList = orderBy(_appList, [app => app.Name.toLowerCase()], ["asc"]);
      return map(sortedList, "Id");
    }
    default:
      return state;
  }
}
