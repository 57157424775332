// @ts-strict
import {
  AksIcon,
  CivoIcon,
  DigitalOceanIcon,
  EksIcon,
  GkeIcon,
  IbmCloudIcon,
  K3sIcon,
  KindIcon,
  KubernetesIcon,
  KurlIcon,
  LinodeIcon,
  MicroK8sIcon,
  OpenShiftIcon,
  OracleIcon,
  Platform9Icon,
  RancherIcon,
  SpectroCloudIcon,
  TanzuIcon,
  EmbeddedClusterIcon
} from "@/components/shared/IconsBrands";
import {
  golangDurationToEnglish,
  golangDurationToMinutes
} from "@/utilities/VendorUtilities";

export const distros = [
  {
    shortName: "aks",
    label: "Azure AKS",
    instanceTypeKey: "aks",
    renderIcon: () => <AksIcon className="tw-w-4" />,
    renderIconLarge: () => <AksIcon className="tw-h-4 md:tw-h-6" />
  },
  {
    shortName: "alibabak8s",
    label: "Alibaba Cloud Kubernetes",
    instanceTypeKey: "alibaba",
    renderIcon: () => <IbmCloudIcon className="tw-w-4" />,
    renderIconLarge: () => <IbmCloudIcon className="tw-h-6" />
  },
  {
    shortName: "civo",
    label: "Civo Kubernetes",
    instanceTypeKey: "civo",
    renderIcon: () => <CivoIcon className="tw-w-4" />,
    renderIconLarge: () => <CivoIcon className="tw-h-6" />
  },
  {
    shortName: "digitalocean",
    label: "DigitalOcean Kubernetes",
    instanceTypeKey: "digitalocean",
    renderIcon: () => <DigitalOceanIcon className="tw-w-4" />,
    renderIconLarge: () => <DigitalOceanIcon className="tw-h-6" />
  },
  {
    shortName: "eks",
    label: "Amazon EKS",
    instanceTypeKey: "eks",
    renderIcon: () => <EksIcon className="tw-w-4" />,
    renderIconLarge: () => <EksIcon className="tw-h-6" />
  },
  //  this distro is for development only
  {
    shortName: "fake",
    label: "Fake",
    instanceTypeKey: "replicated",
    renderIcon: () => <KubernetesIcon className="tw-w-4" />,
    renderIconLarge: () => <KubernetesIcon className="tw-h-6" />
  },
  {
    shortName: "gke",
    label: "Google GKE",
    instanceTypeKey: "gke",
    renderIcon: () => <GkeIcon className="tw-w-5" />,
    renderIconLarge: () => <GkeIcon className="tw-h-6" />
  },
  {
    shortName: "embedded-cluster",
    label: "Embedded Cluster",
    instanceTypeKey: "replicated",
    renderIcon: () => <EmbeddedClusterIcon className="tw-w-4" />,
    renderIconLarge: () => <EmbeddedClusterIcon className="tw-h-6" />
  },
  {
    shortName: "iks",
    label: "IBM Cloud Kubernetes",
    instanceTypeKey: "iks",
    renderIcon: () => <IbmCloudIcon className="tw-w-4" />,
    renderIconLarge: () => <IbmCloudIcon className="tw-h-6" />
  },
  {
    shortName: "k3s",
    label: "k3s",
    instanceTypeKey: "replicated",
    renderIcon: () => <K3sIcon className="tw-w-4" />,
    renderIconLarge: () => <K3sIcon className="tw-h-6" />
  },
  {
    shortName: "rke2",
    label: "RKE2",
    instanceTypeKey: "replicated",
    renderIcon: () => <RancherIcon className="tw-w-4" />, // TODO
    renderIconLarge: () => <RancherIcon className="tw-h-6" /> // TODO
  },
  {
    shortName: "kind",
    label: "kind",
    instanceTypeKey: "replicated",
    renderIcon: () => <KindIcon className="tw-w-6" />,
    renderIconLarge: () => <KindIcon className="tw-h-6" />
  },
  {
    shortName: "kurl",
    label: "kURL",
    instanceTypeKey: "replicated",
    renderIcon: () => <KurlIcon className="tw-w-4" />,
    renderIconLarge: () => <KurlIcon className="tw-h-6" />
  },
  {
    shortName: "linode",
    label: "Linode Kubernetes",
    instanceTypeKey: "linode",
    renderIcon: () => <LinodeIcon className="tw-w-4" />,
    renderIconLarge: () => <LinodeIcon className="tw-h-6" />
  },
  {
    shortName: "microk8s",
    label: "MicroK8s",
    instanceTypeKey: "canonical",
    renderIcon: () => <MicroK8sIcon className="tw-w-4" />,
    renderIconLarge: () => <MicroK8sIcon className="tw-h-6" />
  },
  {
    shortName: "oke",
    label: "OKE",
    instanceTypeKey: "oke",
    renderIcon: () => <OracleIcon className="tw-w-4" />,
    renderIconLarge: () => <OracleIcon className="tw-h-6" />
  },
  {
    shortName: "openshift",
    label: "Red Hat OpenShift",
    instanceTypeKey: "replicated",
    renderIcon: () => <OpenShiftIcon className="tw-w-4" />,
    renderIconLarge: () => <OpenShiftIcon className="tw-h-6" />
  },
  {
    shortName: "platform9",
    label: "Platform9 Kubernetes",
    instanceTypeKey: "platform9",
    renderIcon: () => <Platform9Icon className="tw-w-4" />,
    renderIconLarge: () => <Platform9Icon className="tw-h-6" />
  },
  {
    shortName: "rancher",
    label: "Rancher",
    instanceTypeKey: "rancher",
    renderIcon: () => <RancherIcon className="tw-w-4" />,
    renderIconLarge: () => <RancherIcon className="tw-h-6" />
  },
  {
    shortName: "spectrocloud",
    label: "Spectro Cloud Kubernetes",
    instanceTypeKey: "spectrocloud",
    renderIcon: () => <SpectroCloudIcon className="tw-w-4" />,
    renderIconLarge: () => <SpectroCloudIcon className="tw-h-6" />
  },
  {
    shortName: "tanzu",
    label: "VMware Tanzu",
    instanceTypeKey: "vmware",
    renderIcon: () => <TanzuIcon className="tw-w-4" />,
    renderIconLarge: () => <TanzuIcon className="tw-h-6" />
  }
];

export const generateTTLOptions = (
  defaultTTLOptions: string[],
  maxTTL: string
): { value: string; label: string }[] => {
  const alwaysTTLOptions = defaultTTLOptions.filter(ttl => {
    if (golangDurationToMinutes(ttl) > golangDurationToMinutes(maxTTL)) {
      return false;
    }

    return true;
  });

  const ttlOptions = alwaysTTLOptions.map(ttl => ({
    value: ttl,
    label: `${golangDurationToEnglish(ttl)}`
  }));

  // if the TTL is not in the always TTL, add it to the ttlOptions
  if (!alwaysTTLOptions.includes(maxTTL)) {
    ttlOptions.push({
      value: maxTTL,
      label: `${golangDurationToEnglish(maxTTL)}`
    });
  }

  // sort by the value, parsed duration, shortest first
  ttlOptions.sort((a, b) => {
    // convert the .value to minutes
    const aMinutes = golangDurationToMinutes(a.value);
    const bMinutes = golangDurationToMinutes(b.value);

    // if they are the same, sort by the label
    if (aMinutes === bMinutes) {
      return a.label.localeCompare(b.label);
    }

    // otherwise sort by the minutes
    return aMinutes - bMinutes;
  });

  return ttlOptions;
};

export const isUnknownDistro = (distro: string): boolean => {
  const isKind = distro === "kind";
  const isEks = distro === "eks";
  const isGke = distro === "gke";
  const isAks = distro === "aks";
  const isOke = distro === "oke";
  const isK3s = distro === "k3s";
  const isRke2 = distro === "rke2";
  const isKurl = distro === "kurl";
  const isEmbeddedCluster = distro === "embedded-cluster";
  const isOpenshift = distro === "openshift";
  return (
    !isKind &&
    !isEks &&
    !isGke &&
    !isAks &&
    !isOke &&
    !isK3s &&
    !isRke2 &&
    !isKurl &&
    !isEmbeddedCluster &&
    !isOpenshift
  );
};
