import "isomorphic-fetch";
import { logOutUser } from "../../../auth/sessions/actions";
import { loadingData, forbiddenData } from "../../../ui/main/actions";
import { apiFetchVendor, gzipData } from "@/utilities/VendorUtilities";

export const SET_SUPPORT_TICKET_URL = "SET_SUPPORT_TICKET_URL";
export const SET_SUPPORT_REQUEST_ERROR = "SET_SUPPORT_REQUEST_ERROR";
export const CLEAR_SUPPORT_REQUEST_ERROR = "CLEAR_SUPPORT_REQUEST_ERROR";
export const CLEAR_SUPPORT_REQUEST_SUBMITTED = "CLEAR_SUPPORT_REQUEST_SUBMITTED";

function setSupportRequestTicketURL(url) {
  return {
    type: SET_SUPPORT_TICKET_URL,
    payload: url
  };
}

function setSupportRequestError(err) {
  return {
    type: SET_SUPPORT_REQUEST_ERROR,
    payload: err
  };
}

export function clearSupportRequestError() {
  return {
    type: CLEAR_SUPPORT_REQUEST_ERROR
  };
}

export function clearSupportRequestSubmitted() {
  return {
    type: CLEAR_SUPPORT_REQUEST_SUBMITTED
  };
}

export function sendSupportTicket(payload, type = "support") {
  return async dispatch => {
    dispatch(loadingData("sendSupportTicket", true));
    const urlExtensions = {
      support: "-ticket",
      feature: "/feature-request",
      help: "/help-request"
    };
    let response;
    try {
      const path = `/support${urlExtensions[type]}`;
      response = await apiFetchVendor(path, {
        method: "POST",
        body: JSON.stringify({ payload: gzipData(payload) }),
        enableErrorHandling: false
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(forbiddenData("sendSupportTicket", true));
          dispatch(loadingData("sendSupportTicket", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setSupportRequestTicketURL(body.url));
      dispatch(forbiddenData("sendSupportTicket", false));
      dispatch(loadingData("sendSupportTicket", false));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("sendSupportTicket", false));
      dispatch(setSupportRequestError(error));
      return;
    }
  };
}
