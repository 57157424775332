import { DetailedHTMLProps, ButtonHTMLAttributes, forwardRef } from "react";
import { Link, LinkProps as ReactRouterLinkProps } from "react-router-dom";
import cx from "classnames";
import { Icon } from "./Icon";
import { IconName } from "./Icons";

type ButtonOrLinkProps = {
  kind:
    | "primary-teal"
    | "primary-dark"
    | "primary-purple"
    | "primary-blue"
    | "secondary-teal"
    | "secondary-purple"
    | "secondary-dark"
    | "tertiary"
    | "warning"
    | "warning-new"
    | "secondary-gray"
    | "close-icon";
  size?: "normal" | "small" | "medium" | "large";
  icon?: IconName;
  disabled?: boolean;
};

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  ButtonOrLinkProps;

type LinkProps = ReactRouterLinkProps & ButtonOrLinkProps;

const getKindClassName = (kind: ButtonOrLinkProps["kind"], disabled: boolean) => {
  switch (kind) {
    case "primary-teal": {
      return [
        "tw-bg-teal-300 tw-text-white hover:tw-bg-teal-medium tw-border-teal-muted-dark",
        disabled &&
          "tw-bg-disabled-teal-300 tw-border-disabled-teal-300 hover:tw-bg-disabled-teal-300 hover:tw-border-disabled-teal-300"
      ];
    }
    case "primary-dark": {
      return [
        "tw-bg-teal-medium tw-text-white tw-border-teal-medium",
        disabled && "tw-opacity-50"
      ];
    }
    case "primary-purple": {
      return [
        !disabled &&
          "tw-bg-indigo-500 tw-border-indigo-500 tw-text-white hover:tw-bg-indigo-600 hover:tw-border-indigo-600",
        disabled && "tw-bg-indigo-200 tw-border-indigo-200 tw-text-indigo-100"
      ];
    }
    case "primary-blue": {
      return [
        "tw-bg-blue-300 tw-text-white tw-border-blue-300",
        !disabled && "hover:tw-bg-blue-400 hover:tw-border-blue-400",
        disabled && "tw-opacity-50"
      ];
    }
    case "secondary-teal": {
      return [
        "tw-bg-white tw-text-teal-300 tw-border-teal-300",
        !disabled && "hover:tw-text-teal-medium hover:tw-border-teal-medium"
      ];
    }
    case "secondary-purple": {
      return [
        !disabled &&
          "tw-bg-indigo-100 tw-border-indigo-500 tw-text-indigo-500 hover:tw-bg-indigo-200 hover:tw-border-indigo-600 hover:tw-text-indigo-600",
        disabled && "tw-bg-indigo-100 tw-border-indigo-200 tw-text-indigo-300"
      ];
    }
    case "secondary-dark": {
      return [
        "tw-bg-white tw-text-teal-medium tw-border-teal-medium hover:tw-bg-teal-medium hover:tw-text-white",
        disabled && "tw-opacity-50"
      ];
    }
    case "tertiary": {
      return [
        "tw-bg-off-white tw-border-gray-600 tw-text-gray-600",
        !disabled && "hover:tw-text-gray-500 hover:tw-border-gray-500"
      ];
    }
    case "warning": {
      return [
        "tw-bg-white tw-border-error tw-text-error",
        !disabled &&
          "hover:tw-bg-error-xlight hover:tw-text-error-dark hover:tw-border-error-dark"
      ];
    }
    case "warning-new": {
      return [
        "tw-bg-[#FFEDEE] tw-text-[#FF4856] tw-border-[#FF4856]",
        disabled && "tw-opacity-50",
        !disabled && "hover:tw-text-[#B3323C] hover:tw-border-[#B3323C]"
      ];
    }
    case "secondary-gray": {
      return [
        "tw-bg-white tw-text-gray-400 tw-border-gray-400 hover:tw-text-gray-500 hover:tw-border-gray-500",
        disabled && "tw-opacity-50"
      ];
    }
    case "close-icon": {
      return [
        "tw-bg-transparent tw-border-0 tw-cursor-pointer tw-rounded-md -tw-mt-2 -tw-mr-2"
      ];
    }
  }
};

const getSizeClassName = (size: ButtonOrLinkProps["size"], hasIcon) => {
  switch (size) {
    case "normal": {
      return [
        "tw-py-1.5 tw-text-sm",
        hasIcon && "tw-px-2 tw-font-semibold",
        !hasIcon && "tw-px-3.5 tw-font-bold"
      ];
    }
    case "small": {
      return "!tw-p-2 tw-text-xs tw-font-semibold";
    }
    case "medium": {
      return "tw-px-2.5 tw-py-3 tw-font-semibold tw-text-sm !tw-rounded-[5px]";
    }
    case "large": {
      return "tw-px-5 tw-py-2 tw-font-bold tw-text-base";
    }
  }
};

const getBaseClassName = (disabled: boolean, hasIcon) => {
  return [
    "tw-cursor-pointer tw-leading-none tw-border tw-border-solid tw-rounded-sm",
    "tw-font-sans tw-inline-block tw-transition tw-box-border",
    "tw-flex tw-justify-center tw-gap-1.5 tw-items-center",
    hasIcon && "tw-flex tw-items-center",
    disabled && "!tw-cursor-not-allowed"
  ];
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    kind = "tertiary",
    size = "normal",
    disabled,
    icon,
    children,
    ...props
  }: ButtonProps,
  ref
) {
  return (
    <button
      ref={ref}
      className={cx(
        getBaseClassName(disabled, !!icon),
        getKindClassName(kind, disabled),
        getSizeClassName(size, !!icon),
        className
      )}
      disabled={disabled}
      {...props}
    >
      {icon && <Icon name={icon} className="tw-mr-2" size="small" />}
      {children}
    </button>
  );
});

export const ButtonLink = forwardRef<HTMLAnchorElement, LinkProps>(function ButtonLink(
  {
    className,
    kind = "tertiary",
    size = "normal",
    icon,
    disabled,
    to,
    children,
    ...props
  }: LinkProps,
  ref
) {
  return (
    <Link
      ref={ref}
      className={cx(
        getBaseClassName(disabled, !!icon),
        getKindClassName(kind, disabled),
        getSizeClassName(size, !!icon),
        disabled && "tw-pointer-events-none",
        className
      )}
      to={disabled ? null : to}
      {...props}
    >
      {icon && <Icon name={icon} className="tw-mr-2" />}
      {children}
    </Link>
  );
});

export const ButtonExternalLink = forwardRef<HTMLAnchorElement, LinkProps>(
  function ButtonLink(
    {
      className,
      kind = "tertiary",
      size = "normal",
      icon,
      disabled,
      href,
      children,
      ...props
    }: LinkProps,
    ref
  ) {
    return (
      <a
        className={cx(
          getBaseClassName(disabled, !!icon),
          getKindClassName(kind, disabled),
          getSizeClassName(size, !!icon),
          disabled && "tw-pointer-events-none",
          className
        )}
        href={disabled ? null : href}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
        ref={ref}
      >
        {icon && <Icon name={icon} className="tw-mr-2" />}
        {children}
      </a>
    );
  }
);
