import {
  CLEAR_PROMOTE_PATCH_RELEASE_ERROR,
  CLEAR_PROMOTE_RELEASE_ERROR,
  SET_ACTIVE_RELEASE_LIST,
  SET_LATEST_RELEASE,
  SET_PREFLIGHT_CHECKS,
  SET_PROMOTE_PATCH_RELEASE_ERROR,
  SET_PROMOTE_RELEASE_ERROR,
  SET_RELEASE_LIST,
  SET_START_INDEX_AND_PAGE_COUNT
} from "./actions";
import { SET_CHANNEL_LIST } from "../../../../data/main/apps/channels/actions";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import map from "lodash/map";
import type { Action } from "@/types";

const initialState = {
  releases: [],
  activeReleases: [],
  totalReleaseCount: 0,
  basicChannelData: {},
  preflightChecks: {},
  preflightCheckCategories: [],
  startIndex: 0,
  pageCount: 20,
  latestRelease: {},
  hasPromoteErr: false,
  promoteErr: {}
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_RELEASE_LIST:
      return Object.assign({}, state, {
        releases: action.payload.releases,
        totalReleaseCount: action.payload.total_count
      });
    case SET_ACTIVE_RELEASE_LIST:
      return Object.assign({}, state, {
        activeReleases: action.payload.activeReleases
      });
    case SET_PREFLIGHT_CHECKS: {
      const checks = Object.assign({}, action.payload);
      return Object.assign({}, state, {
        preflightChecks: groupBy(checks, "Category"),
        preflightCheckCategories: uniq(map(checks, "Category"))
      });
    }
    case SET_START_INDEX_AND_PAGE_COUNT: {
      return Object.assign({}, state, {
        startIndex: action.payload.startIndex,
        pageCount: action.payload.pageCount
      });
    }
    case SET_LATEST_RELEASE: {
      return Object.assign({}, state, {
        latestRelease: action.payload.release
      });
    }
    case SET_CHANNEL_LIST: {
      const _channelList = Object.assign({}, action.payload);
      const sortedChannelList = sortBy(_channelList, "Position");
      const channelData = sortedChannelList.map(c => ({
        Id: c.Id,
        Name: c.Name,
        ReleaseSequence: c.ReleaseSequence
      }));
      return Object.assign({}, state, {
        basicChannelData: keyBy(channelData, "Id")
      });
    }
    case SET_PROMOTE_PATCH_RELEASE_ERROR: {
      const error = Object.assign({}, action.payload);
      const err = {
        status: error.code,
        message: error.message
      };
      return Object.assign({}, state, {
        hasPromoteErr: true,
        promoteErr: err
      });
    }
    case CLEAR_PROMOTE_PATCH_RELEASE_ERROR:
      return Object.assign({}, state, {
        hasPromoteErr: false,
        promoteErr: {}
      });
    case SET_PROMOTE_RELEASE_ERROR: {
      const error = Object.assign({}, action.payload);
      const err = {
        status: error.code,
        message: error.message
      };
      return Object.assign({}, state, {
        hasPromoteErr: true,
        promoteErr: err
      });
    }
    case CLEAR_PROMOTE_RELEASE_ERROR:
      return Object.assign({}, state, {
        hasPromoteErr: false,
        promoteErr: {}
      });
    default:
      return state;
  }
}
