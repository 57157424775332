import { connect } from "react-redux";
import { handleInviteSignUp, registerTeam } from "../../redux/auth/sessions/actions";
import realTeamSelection from "../auth/TeamSelection";

const TeamSelection = connect(
  state => ({
    dataLoading: state.ui.main.loading
  }),
  dispatch => ({
    handleInviteSignUp(payload, router, inviteId, callback) {
      return dispatch(handleInviteSignUp(payload, router, inviteId, callback));
    },
    registerTeam(payload, router) {
      return dispatch(registerTeam(payload, router));
    }
  })
)(realTeamSelection);

export default TeamSelection;
