import "isomorphic-fetch";
import { logOutUser, clearServerError } from "../../../../auth/sessions/actions";
import { SessionManager } from "../../../../../services/SessionManager";
import {
  displayModal,
  loadingData,
  forbiddenData,
  errorData
} from "../../../../ui/main/actions";
import { showSavedToast } from "../../../../ui/main/apps/licenses/actions";

export const SET_LICENSES_LIST = "SET_LICENSES_LIST";
export const SET_LICENSES_VERSIONS = "SET_LICENSES_VERSIONS";
export const SET_CSV_LICENSES = "SET_CSV_LICENSES";
export const SET_SELECTED_LICENSE = "SET_SELECTED_LICENSE";
export const CLEAR_SELECTED_LICENSE = "CLEAR_SELECTED_LICENSE";
export const SET_LICENSE_INSTANCES = "SET_LICENSE_INSTANCES";
export const SET_INSTANCE_DETAIL = "SET_INSTANCE_DETAIL";
export const SET_PASSWORD_CONFIRM_DIALOG = "SET_PASSWORD_CONFIRM_DIALOG";
export const SET_SELECTED_LICENSE_FIELDS = "SET_SELECTED_LICENSE_FIELDS";
export const SET_LICENSE_ERROR = "SET_LICENSE_ERROR";
export const CLEAR_LICENSE_ERROR = "CLEAR_LICENSE_ERROR";

function setLicensesList(licenses) {
  return {
    type: SET_LICENSES_LIST,
    payload: licenses
  };
}

function setLicenseVersions(versions) {
  return {
    type: SET_LICENSES_VERSIONS,
    payload: versions
  };
}

export function setCSVLicenses(licenses) {
  return {
    type: SET_CSV_LICENSES,
    payload: licenses
  };
}

function setLicenseInstances(instances) {
  return {
    type: SET_LICENSE_INSTANCES,
    payload: instances
  };
}

function setInstanceDetail(instance) {
  return {
    type: SET_INSTANCE_DETAIL,
    payload: instance
  };
}

export function setSelectedLicense(license) {
  return {
    type: SET_SELECTED_LICENSE,
    payload: license
  };
}

export function clearSelectedLicense() {
  return {
    type: CLEAR_SELECTED_LICENSE
  };
}

export function setPasswordConfirmDialog(display, password) {
  return {
    type: SET_PASSWORD_CONFIRM_DIALOG,
    payload: {
      display,
      password
    }
  };
}

function setCurrentLicenseFields(fields) {
  return {
    type: SET_SELECTED_LICENSE_FIELDS,
    payload: fields
  };
}

export function setLicenseError(err) {
  return {
    type: SET_LICENSE_ERROR,
    payload: err
  };
}

export function clearLicenseError() {
  return {
    type: CLEAR_LICENSE_ERROR
  };
}

export function searchLicenses(appId, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("licenses", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("2")}/app/${appId}/licenses/search`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();

      const licenseIds = body.licenses.map(license => license.id);
      dispatch(getLicenseVersions(appId, licenseIds));

      dispatch(setLicensesList(body));
      dispatch(loadingData("licenses", false));
      dispatch(errorData("licenses", false));
    } catch (error) {
      dispatch(loadingData("licenses", false));
      dispatch(errorData("licenses", true));
      console.log(error);
      return;
    }
  };
}

function getLicenseVersions(appId, licenseIds) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("licenses", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint(
        "2"
      )}/app/${appId}/licenses/versions`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          license_ids: licenseIds
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setLicenseVersions(body));
      dispatch(loadingData("licenses", false));
      dispatch(errorData("licenses", false));
    } catch (error) {
      dispatch(loadingData("licenses", false));
      dispatch(errorData("licenses", true));
      console.log(error);
      return;
    }
  };
}

// call to get all licenses from mySQL, no pagination or filtering on these

export function getLicensesList(appId, forDownload = false) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("2")}/app/${appId}/licenses`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("licenses", false));
          dispatch(forbiddenData("licenses", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      if (forDownload) {
        dispatch(setCSVLicenses(body));
      } else {
        dispatch(setLicensesList(body));
      }
    } catch (error) {
      dispatch(errorData("licenses", true));
      console.log(error);
      return;
    }
  };
}

export function getSelectedLicense(licenseId) {
  return async (dispatch, getState) => {
    if (!licenseId) {
      return;
    }
    dispatch(loadingData("getLicense", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("2")}/licenses/${licenseId}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("getLicense", false));
          dispatch(forbiddenData("getLicense", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setSelectedLicense(body));
      dispatch(forbiddenData("getLicense", false));
      dispatch(loadingData("getLicense", false));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("getLicense", false));
      return;
    }
  };
}

export function getLicenseInstances(licenseId) {
  return async (dispatch, getState) => {
    if (!licenseId) {
      return;
    }
    dispatch(loadingData("instances", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${licenseId}/instances`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setLicenseInstances(body));
      dispatch(loadingData("instances", false));
    } catch (error) {
      dispatch(loadingData("instances", false));
      console.log(error);
      return;
    }
  };
}

export function getInstanceDetails(licenseId, instanceId) {
  return async (dispatch, getState) => {
    if (!licenseId) {
      return;
    }
    dispatch(loadingData("instanceDetail", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${licenseId}/instance/${instanceId}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setInstanceDetail(body));
      dispatch(loadingData("instanceDetail", false));
    } catch (error) {
      dispatch(loadingData("instanceDetail", false));
      console.log(error);
      return;
    }
  };
}

export function createLicense(appId, payload, callback) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("licenseCreate", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("2")}/license`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        const err = await response.json();
        dispatch(loadingData("licenseCreate", false));
        if (response.status === 400) {
          dispatch(
            setLicenseError({
              error: err.error
            })
          );
          return;
        }
        dispatch(setLicenseError(err));
        return;
      }
      const body = await response.json();
      dispatch(loadingData("licenseCreate", false));
      dispatch(
        searchLicenses(appId, {
          query: "",
          offset: 0,
          page_size: 20,
          sort_field: "assignee",
          sort_direction: "asc",
          include_dev: true,
          include_trial: true,
          include_paid: true,
          include_active: true,
          include_inactive: true,
          include_archived: false
        })
      );
      dispatch(clearServerError());
      callback(body.id);
    } catch (err) {
      dispatch(loadingData("licenseCreate", false));
      console.log(err);
      if (response.status === 403) {
        dispatch(displayModal("permissions", true));
      }
      return;
    }
  };
}

export function setLoadingAndSavedToastToFalse() {
  return async dispatch => {
    dispatch(loadingData("licenseUpdate", false));
    dispatch(showSavedToast(false));
  };
}

export function updateLicense(appId, licenseId, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("licenseUpdate", true));
    let response;
    const url = `${SessionManager.getApiEndpoint("2")}/license/${licenseId}`;
    try {
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        const err = await response.json();
        dispatch(loadingData("licenseUpdate", false));
        if (response.status === 400) {
          dispatch(
            setLicenseError({
              error: err.message
            })
          );
          return;
        }
        dispatch(setLicenseError(err));
        return;
      }
      await response.json();
      dispatch(loadingData("licenseUpdate", false));
      dispatch(showSavedToast(true));
      dispatch(
        searchLicenses(appId, {
          query: "",
          offset: 0,
          page_size: 20,
          sort_field: "assignee",
          sort_direction: "asc",
          include_dev: true,
          include_trial: true,
          include_paid: true,
          include_active: true,
          include_inactive: true,
          include_archived: false
        })
      );
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (err) {
      console.log(err);
      dispatch(loadingData("licenseUpdate", false));
      if (response.status === 403) {
        dispatch(displayModal("permissions", true));
      }
      return;
    }
  };
}

export function updateLicenseBilling(appId, licenseId, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("licenseBillingUpdate", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${licenseId}/billing`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        dispatch(loadingData("licenseBillingUpdate", false));
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      await response.json();
      dispatch(loadingData("licenseBillingUpdate", false));
      dispatch(showSavedToast(true));
      dispatch(getSelectedLicense(licenseId));
      dispatch(
        searchLicenses(appId, {
          query: "",
          offset: 0,
          page_size: 20,
          sort_field: "assignee",
          sort_direction: "asc",
          include_dev: true,
          include_trial: true,
          include_paid: true,
          include_active: true,
          include_inactive: true,
          include_archived: false
        })
      );
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (error) {
      dispatch(loadingData("licenseBillingUpdate", false));
      console.log(error);
      return;
    }
  };
}

export function handleArchive(appId, licenseId, filters) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${licenseId}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(
        searchLicenses(appId, {
          query: "",
          offset: 0,
          page_size: 20,
          sort_field: "assignee",
          sort_direction: "asc",
          include_dev:
            filters.license_type === "dev" || filters.license_type === ""
              ? true
              : false,
          include_trial:
            filters.license_type === "trials" || filters.license_type === ""
              ? true
              : false,
          include_paid:
            filters.license_type === "prod" || filters.license_type === ""
              ? true
              : false,
          include_active: filters.active,
          include_inactive: filters.inactive,
          include_archived: filters.archived
        })
      );
      dispatch(displayModal("confirmArchiveLicense", false));
    } catch (error) {
      console.log(error);
      dispatch(displayModal("confirmArchiveLicense", false));
      return;
    }
  };
}

export function handleUnarchive(appId, licenseId, filters) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${licenseId}/unarchive`;
      response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(
        searchLicenses(appId, {
          query: "",
          offset: 0,
          page_size: 20,
          sort_field: "assignee",
          sort_direction: "asc",
          include_dev:
            filters.license_type === "dev" || filters.license_type === ""
              ? true
              : false,
          include_trial:
            filters.license_type === "trials" || filters.license_type === ""
              ? true
              : false,
          include_paid:
            filters.license_type === "prod" || filters.license_type === ""
              ? true
              : false,
          include_active: filters.active,
          include_inactive: filters.inactive,
          include_archived: filters.archived
        })
      );
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function updateAirgapPassword(appId, licenseId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${licenseId}/airgap/password`;
      response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setPasswordConfirmDialog(true, body.password));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function getCurrentLicenseFields(id) {
  return async (dispatch, getState) => {
    if (!id) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${id}/fields`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setCurrentLicenseFields(body));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function handleRemoveLicenseChannels(appId, licenseId, filters) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/license/${licenseId}/app/${appId}/channels`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(
        searchLicenses(appId, {
          query: "",
          offset: 0,
          page_size: 20,
          sort_field: "assignee",
          sort_direction: "asc",
          include_dev:
            filters.license_type === "dev" || filters.license_type === ""
              ? true
              : false,
          include_trial:
            filters.license_type === "trials" || filters.license_type === ""
              ? true
              : false,
          include_paid:
            filters.license_type === "prod" || filters.license_type === ""
              ? true
              : false,
          include_active: filters.active,
          include_inactive: filters.inactive,
          include_archived: filters.archived
        })
      );
      dispatch(displayModal("confirmArchiveLicense", false));
    } catch (error) {
      console.log(error);
      dispatch(displayModal("confirmArchiveLicense", false));
      return;
    }
  };
}
