import { Component } from "react";
import Loader from "../shared/Loader";

export default class RedirectChangelog extends Component {
  componentDidMount() {
    window.location.assign("https://release-notes.replicated.com/");
  }

  render() {
    return (
      <div className="u-minHeight--full u-minWidth--full flex-column flex1 u-position--relative">
        <div className="flex-column flex-1-auto u-overflow--hidden justifyContent--center alignItems--center">
          <Loader size="70" />
        </div>
      </div>
    );
  }
}
