import "isomorphic-fetch";
import { displayModal, loadingData, forbiddenData } from "../../../../ui/main/actions";
import { logOutUser } from "../../../../auth/sessions/actions";
import { SessionManager } from "../../../../../services/SessionManager";

export const SET_LICENSE_FIELDS_LIST = "SET_LICENSE_FIELDS_LIST";

function setLicenseFieldsList(licenseFieldsList) {
  return {
    type: SET_LICENSE_FIELDS_LIST,
    payload: licenseFieldsList
  };
}

export function getLicenseFields(appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("licenseFields", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/licensefield`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("licenseFields", false));
          dispatch(forbiddenData("licenseFields", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setLicenseFieldsList(body));
      dispatch(loadingData("licenseFields", false));
      dispatch(forbiddenData("licenseFields", false));
    } catch (error) {
      dispatch(loadingData("licenseFields", false));
      console.log(error);
      return;
    }
  };
}

export function createLicenseField(appId, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/licensefield`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setLicenseFieldsList(body));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function updateLicenseField(appId, name, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    const _name = encodeURIComponent(name);
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/licensefield/${_name}`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setLicenseFieldsList(body));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}
