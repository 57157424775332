import "isomorphic-fetch";
import { logOutUser } from "../../../../auth/sessions/actions";
import { SessionManager } from "../../../../../services/SessionManager";
import { showSavedToast } from "../../../../ui/main/apps/view_release/actions";
import { displayModal, loadingData, forbiddenData } from "../../../../ui/main/actions";
import { setExternalRegistries } from "../../app_settings/actions";

export const SET_IMAGES_LIST = "SET_IMAGES_LIST";

function setImagesList(imagesList) {
  return {
    type: SET_IMAGES_LIST,
    payload: imagesList
  };
}

export function getImages(appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("images", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/images`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("images", false));
          dispatch(forbiddenData("images", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setImagesList(body));
      dispatch(forbiddenData("images", false));
      dispatch(loadingData("images", false));
    } catch (error) {
      dispatch(loadingData("images", false));
      console.log(error);
      return;
    }
  };
}

export function getRegistryImages(appId) {
  return dispatch => {
    dispatch(getExternalRegistries(appId));
    dispatch(getImages(appId));
  };
}

function getExternalRegistries(appId) {
  return async (dispatch, getState) => {
    dispatch(loadingData("externalRegistries", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/externalregistries`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(loadingData("externalRegistries", false));
          dispatch(forbiddenData("externalRegistries", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setExternalRegistries(body));
      dispatch(forbiddenData("externalRegistries", false));
      dispatch(loadingData("externalRegistries", false));
    } catch (error) {
      dispatch(loadingData("externalRegistries", false));
      return;
    }
  };
}

export function deleteImage(payload, appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    dispatch(loadingData("deleteKey", true));
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/keys/${
        payload.name
      }`;
      response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify({ password: payload.password }),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("deleteKey", false));
      dispatch(displayModal("confirmDeleteImageKey", false));
      dispatch(getImages(appId));
      dispatch(showSavedToast(true));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch (error) {
      dispatch(loadingData("deleteKey", false));
      return;
    }
  };
}
