const notificationUiState = {
  headerItems: [
    {
      label: "Trigger",
      style: { maxWidth: "none" }
    },
    {
      label: "Enabled",
      style: { maxWidth: "100px" }
    }
  ]
};

export function notificationUiData(state = notificationUiState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
