import "isomorphic-fetch";
import { SessionManager } from "../../../services/SessionManager";
import { loadingData, displayModal } from "../../../redux/ui/main/actions";
import has from "lodash/has";
import { getTeam } from "../../../redux/data/main/teams/actions";
import { getQueryClient } from "../../../query-client";
import { showSavedToast } from "../../../redux/ui/main/apps/view_release/actions";

export const PURGE_ALL = "PURGE_ALL";
export const SET_SESSION_DATA = "SET_SESSION_DATA";
export const SET_INVITE_DATA = "SET_INVITE_DATA";
export const SET_SESSION_ERROR = "SET_SESSION_ERROR";
export const SET_TOKEN = "SET_TOKEN";
export const CLEAR_SERVER_ERROR = "CLEAR_SERVER_ERROR";
export const SET_INVITE_ERROR = "SET_INVITE_ERROR";
export const CLEAR_INVITE_ERROR = "CLEAR_INVITE_ERROR";
export const CLEAR_SIGNUP_VALIDATION_ERRORS = "CLEAR_SIGNUP_VALIDATION_ERRORS";
export const SET_USER_ACCOUNT_INFORMATION = "SET_USER_ACCOUNT_INFORMATION";
export const SET_UPDATE_ACCOUNT_ERROR = "SET_UPDATE_ACCOUNT_ERROR";
export const SET_RESET_PASSWORD_STEP = "SET_RESET_PASSWORD_STEP";
export const SET_RESET_PASSWORD_ERROR = "SET_RESET_PASSWORD_ERROR";
export const SET_PASSWORD_RESET_STEP = "SET_PASSWORD_RESET_STEP";
export const SET_ACCOUNT_UNLOCK_STEP = "SET_ACCOUNT_UNLOCK_STEP";
export const SET_ACCOUNT_UNLOCKED_STEP = "SET_ACCOUNT_UNLOCKED_STEP";
export const SET_RESENT_CODE = "SET_RESENT_CODE";
export const SET_ENFORCED_TWO_FACTOR = "SET_ENFORCED_TWO_FACTOR";
export const SET_EXPIRED_TOKEN_MESSAGE = "SET_EXPIRED_TOKEN_MESSAGE";
export const CLEAR_EXPIRED_TOKEN_ERROR = "CLEAR_EXPIRED_TOKEN_ERROR";
export const SET_SIGNUP_VALIDATION_ERRORS = "SET_SIGNUP_VALIDATION_ERRORS";
export const SET_NEW_SESSION = "SET_NEW_SESSION";

function purgeAll() {
  return {
    type: PURGE_ALL
  };
}

function setSessionData(data) {
  return {
    type: SET_SESSION_DATA,
    payload: data
  };
}

function setNewSession(data) {
  return {
    type: SET_NEW_SESSION,
    payload: data
  };
}

function setInviteData(data) {
  return {
    type: SET_INVITE_DATA,
    payload: data
  };
}

function setResetPasswordError(err) {
  return {
    type: SET_RESET_PASSWORD_ERROR,
    payload: err
  };
}

function setInviteError(err) {
  return {
    type: SET_INVITE_ERROR,
    payload: err
  };
}

function setPasswordResetStep(step) {
  return {
    type: SET_PASSWORD_RESET_STEP,
    payload: step
  };
}

function setAccountUnlockedStep(step) {
  return {
    type: SET_ACCOUNT_UNLOCKED_STEP,
    payload: step
  };
}

function setEnforcedTwoFactor(isEnforced) {
  return {
    type: SET_ENFORCED_TWO_FACTOR,
    payload: isEnforced
  };
}

function setExpiredTokenMessage(errToken) {
  return {
    type: SET_EXPIRED_TOKEN_MESSAGE,
    payload: errToken
  };
}

function setSignupValidationErrors(err) {
  return {
    type: SET_SIGNUP_VALIDATION_ERRORS,
    payload: err
  };
}

export function setSessionError(err) {
  return {
    type: SET_SESSION_ERROR,
    payload: err
  };
}

export function clearServerError() {
  return {
    type: CLEAR_SERVER_ERROR
  };
}

export function clearSignupValidationErrors() {
  return {
    type: CLEAR_SIGNUP_VALIDATION_ERRORS
  };
}

export function clearInviteError() {
  return {
    type: CLEAR_INVITE_ERROR
  };
}

export function setResetPasswordStep(step) {
  return {
    type: SET_RESET_PASSWORD_STEP,
    payload: step
  };
}

export function setAccountUnlockStep(step) {
  return {
    type: SET_ACCOUNT_UNLOCK_STEP,
    payload: step
  };
}

export function clearExpiredTokenError() {
  return {
    type: CLEAR_EXPIRED_TOKEN_ERROR
  };
}

export function setUserAccountInformation(user) {
  return {
    type: SET_USER_ACCOUNT_INFORMATION,
    payload: user
  };
}

export function setUpdateAccountInformationError(err) {
  return {
    type: SET_UPDATE_ACCOUNT_ERROR,
    payload: err.error.message
  };
}

export function getTeamAndUserData() {
  return dispatch => {
    dispatch(getTeam());
    dispatch(getUserAccountSettings());
  };
}

export function handleSignUp(payload) {
  return async dispatch => {
    dispatch(loadingData("signup", true));
    const url = `${SessionManager.getApiEndpoint()}/signup`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    if (response.status === 400) {
      dispatch(loadingData("signup", false));
      const err = await response.json();
      if (has(err, "validateError")) {
        dispatch(setSignupValidationErrors(err));
      } else {
        dispatch(setSessionError(err));
      }
      return;
    }
    if (response.status > 400) {
      dispatch(loadingData("signup", false));
      const err = await response.json();
      dispatch(setSessionError(err));
      return;
    }

    dispatch(loadingData("signup", false));
    SessionManager.setSessionId();
    return response;
  };
}

export function registerTeam(payload, router) {
  return async (dispatch, getState) => {
    dispatch(loadingData("register", true));
    const url = `${SessionManager.getApiEndpoint()}/signup/register`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: getState().auth.sessions.sessionData.accessToken,
        "Content-Type": "application/json"
      }
    });
    if (response.status >= 400) {
      dispatch(loadingData("register", false));
      let err = response;
      if (response.status < 500) {
        err = await response.json();
      }
      dispatch(setSessionError(err));
      return;
    }
    const body = await response.json();
    dispatch(setSessionData(body));
    dispatch(loadingData("register", false));
    SessionManager.setSessionId();

    if (body.team.needs_profile) {
      router.push("/complete_signup");
      return;
    }

    if (response.status === 201) router.push("/new-application");
  };
}

export function setLoadingToFalse() {
  return async dispatch => {
    dispatch(loadingData("register", false));
  };
}

export function handleGoogleLoginComplete(router, nonce, nextPage) {
  return async dispatch => {
    const url = `${SessionManager.getApiEndpoint()}/login/nonce`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        nonce
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    if (response.status >= 400) {
      dispatch(loadingData("login", false));
      let err = response;
      if (response.status < 500) {
        err = await response.json();
      }
      dispatch(setSessionError(err));
      return;
    }
    const body = await response.json();
    if (body.signup_verification) {
      dispatch(setSessionData(body));
      dispatch(loadingData("activationCode", false));
      router.push("/select-team");
      return;
    }

    dispatch(setSessionData(body));
    dispatch(loadingData("login", false));
    SessionManager.setSessionId();

    if (body.team.needs_profile) {
      router.push("/complete_signup");
      return;
    }

    router.push(`${nextPage || "/apps"}`);
  };
}

export function handleLogIn(payload, router, nextPage) {
  return async dispatch => {
    dispatch(loadingData("login", true));
    let response;
    const url = `${SessionManager.getApiEndpoint()}/login`;

    try {
      response = await doLogIn(url, payload, fetch);
    } catch (err) {
      console.error(err);
      dispatch(loadingData("login", false));
      dispatch(
        setSessionError({
          error: { message: "Something went wrong, please try again." }
        })
      );
      return;
    }

    // If API returns 404
    if (response.status === 404) {
      dispatch(loadingData("login", false));
      dispatch(
        setSessionError({
          error: { message: "Something went wrong, please try again." }
        })
      );
      return;
    }
    // All other 400s
    if (response.status >= 400) {
      dispatch(loadingData("login", false));
      const err = await response.json();
      if (err.error.messageCode === "LOCKED_ACCOUNT") {
        router.push(`/account/locked?email=${payload.email}`);
        return;
      }
      dispatch(setSessionError(err));
      return;
    }

    const body = await response.json();
    dispatch(setSessionData(body));
    SessionManager.setSessionId();
    if (body.signup) {
      router.push("/signup");
      return;
    }

    if (body.ReplicatedNeedsOtp === "true" || body.needs_otp) {
      dispatch(loadingData("login", false));
      return;
    }

    if (!body.user["2fa_enabled"] && body.user["2fa_enforced"]) {
      dispatch(setEnforcedTwoFactor(true));
      router.push("/require-2fa");
      return;
    }

    router.push(`${nextPage || "/apps"}`);
  };
}

export async function doLogIn(url, payload, fetcher) {
  return await fetcher(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

export function handleOtpLogin(payload, callback) {
  return async (dispatch, getState) => {
    dispatch(loadingData("otpLogin", true));
    const url = `${SessionManager.getApiEndpoint()}/login/otp`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: getState().auth.sessions.sessionData.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    if (response.status === 401) {
      dispatch(loadingData("otpLogin", false));
      dispatch(
        setSessionError({
          error: { message: "Unauthorized, code is invalid" }
        })
      );
      return;
    }
    if (response.status >= 400) {
      dispatch(loadingData("otpLogin", false));
      dispatch(
        setSessionError({
          error: { message: "Trial expired" }
        })
      );
      return;
    }
    const body = await response.json();
    dispatch(setSessionData(body));
    callback();
  };
}

export function handleGoogleLogin() {
  return async dispatch => {
    dispatch(loadingData("googleLogin", true));

    const urlParameters = new URLSearchParams(window.location.search);
    const nextUrl = urlParameters.get("next");
    let url = `${SessionManager.getApiEndpoint()}/auth/google`;

    // Google login can also be used for signup, so we need to handle that case for builders/business plans
    const queryParts: string[] = [`isBuildersOnly=false`];
    if (nextUrl && nextUrl !== "/signup/verify") {
      queryParts.push(`next=${encodeURIComponent(nextUrl)}`);
    }
    url = `${url}?${queryParts.join("&")}`;

    let response;
    try {
      response = await doGetGoogleLoginRedirectUrl(url, fetch);
    } catch (err) {
      console.error(err);
      dispatch(loadingData("googleLogin", false));
      dispatch(
        setSessionError({
          error: { message: "Something went wrong, please try again." }
        })
      );
      return;
    }
    if (response.url !== "") {
      window.location.href = response.url;
      return;
    }
    console.error("error");
  };
}

export function handleSubmitActivationCode(payload, router) {
  return async dispatch => {
    dispatch(loadingData("activationCode", true));
    let response;
    const url = `${SessionManager.getApiEndpoint()}/signup/verify`;

    try {
      response = await doSignupActivation(url, payload, fetch);
    } catch (err) {
      console.error(err);
      dispatch(loadingData("activationCode", false));
      dispatch(
        setSessionError({
          error: { message: "Something went wrong, please try again." }
        })
      );
      return;
    }
    if (response.status >= 400) {
      if (response.status === 401) {
        router.push("/login");
        dispatch(loadingData("activationCode", false));
        dispatch(
          setSessionError({
            error: { message: "Please login to activate your account" }
          })
        );
        return;
      }
      const error = await response.json();
      dispatch(loadingData("activationCode", false));
      dispatch(setSessionError(error));
      return;
    }
    const body = await response.json();
    dispatch(
      setNewSession({
        token: body.token,
        user: body.user
      })
    );
    dispatch(loadingData("activationCode", false));

    router.push("/select-team");
  };
}

async function doGetGoogleLoginRedirectUrl(url, fetcher) {
  return await fetcher(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    redirect: "manual"
  });
}

export async function doSignupActivation(url, payload, fetcher) {
  return await fetcher(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

export function getInvite(inviteId) {
  return async dispatch => {
    dispatch(clearInviteError());
    dispatch(loadingData("invite", true));
    const url = `${SessionManager.getApiEndpoint()}/team/invite/${encodeURIComponent(
      inviteId
    )}`;
    let response;
    try {
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
    } catch (err) {
      dispatch(setInviteError({ error: { message: err.message } }));
      dispatch(loadingData("invite", false));
      return;
    }
    if (!response.ok) {
      if (response.status === 401) {
        dispatch(logOutUser());
      }
      const err = await response.json();
      dispatch(setInviteError({ error: { message: err.message } }));
      dispatch(loadingData("invite", false));
      return;
    }
    const body = await response.json();
    dispatch(setInviteData(body));
    dispatch(loadingData("invite", false));
  };
}

export function handleInviteSignUp(payload, router, inviteId, callback) {
  return async (dispatch, getState) => {
    dispatch(loadingData("signup", true));
    const { user, accessToken } = getState().auth.sessions.sessionData;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(user?.has_google_authed ? { Authorization: accessToken } : {})
    };

    const acceptInviteReqBody = {
      invite_id: inviteId,
      first_name: payload.firstname,
      last_name: payload.lastname,
      password: payload.password,
      replace_account: payload.replaceAccount,
      from_team_selection: payload.inviteFromTeamSelection
    };
    const url = `${SessionManager.getApiEndpoint()}/signup/accept-invite`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(acceptInviteReqBody),
      headers: headers
    });
    if (response.status === 400) {
      dispatch(loadingData("signup", false));
      const err = await response.json();
      if (has(err, "validateError")) {
        dispatch(setSignupValidationErrors(err));
      } else {
        dispatch(setSessionError(err));
      }
      return;
    }
    if (response.status >= 400) {
      dispatch(loadingData("signup", false));
      const err = await response.json();
      dispatch(setSessionError(err));
      return;
    }
    const body = await response.json();

    // team has enabled SAML
    if (body.samlRedirect) {
      dispatch(logOutUser());
      router.push(body.samlRedirect);
      return;
    }

    dispatch(setSessionData(body));
    dispatch(loadingData("signup", false));

    if (!body.user["2fa_enabled"] && body.user["2fa_enforced"]) {
      dispatch(setEnforcedTwoFactor(true));
      router.push("/require-2fa");
      return;
    }

    SessionManager.setSessionId();
    callback();
  };
}

export function sendPasswordResetEmail(payload) {
  return async dispatch => {
    dispatch(loadingData("passwordReset", true));
    const url = `${SessionManager.getApiEndpoint()}/password/reset`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    if (response.status >= 400) {
      dispatch(loadingData("passwordReset", false));
      let err = await response.json();
      if (!err || !err.error) {
        err = {
          error: {
            message: "Unknown error occurred"
          }
        };
      }
      dispatch(setResetPasswordError(err));
      return;
    }
    dispatch(loadingData("passwordReset", false));
    dispatch(setResetPasswordStep("success"));
  };
}

export function resetPassword(payload) {
  return async dispatch => {
    dispatch(loadingData("resettingPassword", true));
    const url = `${SessionManager.getApiEndpoint()}/password/reset`;
    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    if (response.status >= 400) {
      dispatch(loadingData("resettingPassword", false));
      let err = await response.json();
      if (!err || !err.error) {
        err = {
          error: {
            message: "Unknown error occurred"
          }
        };
      }
      dispatch(
        setExpiredTokenMessage({
          error: {
            message: err.error.message
          }
        })
      );
      return;
    }
    dispatch(loadingData("resettingPassword", false));
    dispatch(setPasswordResetStep("success"));
  };
}

export function sendAccountUnlockEmail(payload) {
  return async dispatch => {
    dispatch(loadingData("accountUnlockEmail", true));
    const url = `${SessionManager.getApiEndpoint()}/unlock/email`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    if (response.status >= 400) {
      dispatch(loadingData("accountUnlockEmail", false));
      const err = await response.json();
      dispatch(setSessionError(err));
      return;
    }
    dispatch(loadingData("accountUnlockEmail", false));
    dispatch(setAccountUnlockStep("success"));
  };
}

export function unlockAccount(payload) {
  return async dispatch => {
    dispatch(loadingData("unlockAccountLoading", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/unlock`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (response.status >= 400) {
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("unlockAccountLoading", false));
      dispatch(setAccountUnlockedStep("success"));
    } catch (error) {
      dispatch(loadingData("unlockAccountLoading", false));
      dispatch(
        setSessionError({
          error: {
            message:
              "Unable to unlock your account, please contact your team administrator."
          }
        })
      );
      return;
    }
  };
}

export function getUserAccountSettings() {
  return async (dispatch, getState) => {
    dispatch(loadingData("getUserAccountLoading", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/user`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.sessions.sessionData.accessToken
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(logOutUser());
        }
        dispatch(loadingData("getUserAccountLoading", false));
        dispatch(
          setSessionError({
            error: {
              message: "Unable to fetch user account."
            }
          })
        );
        return;
      }
      const body = await response.json();
      dispatch(loadingData("getUserAccountLoading", false));
      dispatch(setUserAccountInformation(body));
    } catch (error) {
      dispatch(loadingData("getUserAccountLoading", false));
      dispatch(
        setSessionError({
          error: {
            message: "Unable to fetch user account."
          }
        })
      );
      return;
    }
  };
}

export function updateAccountInformation(userId, payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("updateAccountInformation", true));
    dispatch(setUpdateAccountInformationError({ error: { message: "" } }));
    let response;
    let body;
    try {
      const url = `${SessionManager.getApiEndpoint("1")}/user/update/${userId}`;
      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.sessions.sessionData.accessToken
        }
      });
      body = await response.json();
      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(logOutUser());
        }
        dispatch(loadingData("updateAccountInformation", false));
        let err = await response.json();
        if (!err || !err.error) {
          err = {
            error: {
              message: "Unknown error occurred"
            }
          };
        }
        dispatch(
          setUpdateAccountInformationError({
            error: {
              message: err.error.message
            }
          })
        );
        return;
      }
      dispatch(loadingData("updateAccountInformation", false));
      dispatch(setUserAccountInformation(body));
      dispatch(showSavedToast(true));
      setTimeout(() => {
        dispatch(showSavedToast(false));
      }, 3000);
    } catch {
      dispatch(loadingData("updateAccountInformation", false));
      dispatch(
        setUpdateAccountInformationError({
          error: {
            message: body?.error?.message || "Unable to update user account."
          }
        })
      );
      return;
    }
  };
}

export function logOutUser(removeNextLink = false) {
  return async (dispatch, getState) => {
    if (getState().ui.main.loading.loggingOutLoading) {
      return;
    }
    dispatch(loadingData("loggingOut", true));

    let nextUrl: string | null = null;
    if (!removeNextLink) {
      const urlParameters = new URLSearchParams(window.location.search);
      nextUrl = urlParameters.get("next");
      if (!nextUrl && window.location.pathname !== "/login") {
        nextUrl = `${window.location.pathname}${window.location.search}`;
      }
    }

    const token = getState().auth.sessions.sessionData.accessToken;
    const queryClient = getQueryClient();
    let response;
    try {
      if (token) {
        const url = `${SessionManager.getApiEndpoint()}/logout`;
        response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: token
          }
        });
        if (!response.ok) {
          if (response.status >= 400) {
            dispatch(loadingData("loggingOut", false));
            dispatch(displayModal("logoutError", true));
          }
          throw new Error(`Unexpected response status code ${response.status}`);
        } else {
          window.localStorage.removeItem("reduxPersist:auth");
          window.sessionStorage.removeItem("reduxPersist:auth");
          queryClient.clear();
          dispatch(purgeAll());
        }
      }
      if (nextUrl && !nextUrl.includes("nonce")) {
        window.location.assign(`/login?next=${encodeURIComponent(nextUrl)}`);
      } else {
        window.location.assign("/login");
      }
    } catch (error) {
      dispatch(loadingData("loggingOut", false));
      dispatch(displayModal("logoutError", true));
      return;
    }
  };
}

async function doGetGoogleSignupRedirectUrl(url, fetcher) {
  return await fetcher(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    redirect: "manual"
  });
}

export function handleGoogleSignup(isBuildersOnly: boolean) {
  return async dispatch => {
    dispatch(loadingData("googleSignup", true));
    let response;

    let url = `${SessionManager.getApiEndpoint()}/auth/google`;
    if (isBuildersOnly) {
      url = `${url}?isBuildersOnly=true`;
    }

    try {
      response = await doGetGoogleSignupRedirectUrl(url, fetch);
    } catch (err) {
      console.error(err);
      dispatch(loadingData("googleSignup", false));
      dispatch(
        setSessionError({
          error: { message: "Something went wrong, please try again." }
        })
      );
      return;
    }
    if (response.url !== "") {
      window.location.href = response.url;
      return;
    }
    console.error("error");
  };
}

async function doGetGoogleInviteAcceptRedirectUrl(url, inviteId, fetcher) {
  return await fetcher(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Invite ${inviteId}`
    },
    redirect: "manual"
  });
}

export function handleGoogleInviteAccept(inviteId, replaceAccount) {
  return async dispatch => {
    dispatch(loadingData("googleSignup", true));

    let response;
    let url = `${SessionManager.getApiEndpoint()}/auth/google`;
    if (replaceAccount) {
      url += `?replaceAccount=true`;
    }

    try {
      response = await doGetGoogleInviteAcceptRedirectUrl(url, inviteId, fetch);
    } catch (err) {
      console.error(err);
      dispatch(loadingData("googleSignup", false));
      dispatch(
        setSessionError({
          error: { message: "Something went wrong, please try again." }
        })
      );
      return;
    }
    if (response.url !== "") {
      window.location.href = response.url;
      return;
    }
    console.error("error");
  };
}
