import { Component } from "react";

import "../../scss/components/shared/CompromisedPasswordBanner.scss";

export default class CompromisedPasswordBanner extends Component {
  render() {
    const { showBanner } = this.props;

    return (
      <div className="u-position--relative u-width--full" style={{ zIndex: 310 }}>
        {showBanner && (
          <div className="banner flex flex-row">
            <span className="icon u-passwordLockIcon"> </span>
            <div className="flex1 flex-column u-marginLeft--small">
              <span className="u-fontSize--14 u-fontWeight--bold u-color--white">
                Your password is vulnerable
              </span>
              <span className="u-color--white u-marginTop--small">
                According to{" "}
                <a
                  href="https://haveibeenpwned.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-color--white u-cursor--pointer u-textDecoration--underline"
                >
                  haveibeenpwned.com
                </a>{" "}
                you may be using a compromised password.{" "}
                <a
                  href="https://help.replicated.com/docs/security/vendor-password-compromised"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-color--white u-cursor--pointer u-textDecoration--underline"
                >
                  Read more
                </a>{" "}
                about how we are able to determine this and what actions you should
                take.
              </span>
            </div>
            <span
              className="icon clickable u-closeWhiteIcon u-marginBottom--most xIcon"
              onClick={() => this.props.onCloseBanner()}
            ></span>
          </div>
        )}
      </div>
    );
  }
}
