import { connect } from "react-redux";
import realCreateTeam from "../auth/CreateTeam";

import { setLoadingToFalse, clearServerError } from "../../redux/auth/sessions/actions";

const CreateTeam = connect(
  state => ({
    err: state.auth.sessions.sessionData.err
  }),
  dispatch => ({
    clearServerError() {
      return dispatch(clearServerError());
    },
    setLoadingToFalse() {
      return dispatch(setLoadingToFalse());
    }
  })
)(realCreateTeam);

export default CreateTeam;
