import {
  useQueryParam,
  withDefault,
  encodeDelimitedArray,
  decodeDelimitedArray
} from "use-query-params";
import { useFeatureValues, useTeamEntitlements } from "@/query";

const CommaArrayParam = {
  encode: (array: string[] | undefined) => encodeDelimitedArray(array, ","),
  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ",")
};

// Add/remove feature flags from the arrays below. Enforced via typescript - removing a flag from any of these lists without removing
// its usage will fail CI, and also provide errors pointing to every usage site.

// Query string flags go here. These are useful for shipping dead code (eg., no backend yet) and prototypes.
// These are client side only, not to be confused with database backed feature flags.
// Usage:
//   (url: ?flags=uptime-ratio,some-other-flag)
//   const myFeatureEnabled = useFlag("myFeature");
//   if (myFeatureEnabled) {
//     // do something
//   }
const queryStringFlags = ["uptime-ratio"] as const;

// Database backed feature flags go here. These are useful for shipping features that are not yet ready for all teams.
// These are the feature flags (used in Vendor Portal) that you can toggle for teams in Admin.
export const adminFeatureFlags = [
  "compatibility-matrix",
  "default_to_airgap_docker_registry_format",
  "external-support-bundle",
  "heritage_eol_notice",
  "kots_identity_service",
  "multiple_apps",
  "ship_vendor_support_analyzers",
  "use-github-issues",
  "allow_disaster_recovery",
  "ai_model_repository",
  "multi_channel_licenses",
  "license_release_pinning_enabled",
  "install_types",
  "kots_identity_service",
  "allow_snapshots",
  "installer_support_optional"
] as const;

// These feature flags are for features that are not yet ready for all teams, but are not yet ready to be toggled in Admin.
// These can be used to toggle "fake doors" in the UI.
export const futureFeatureFlags = [] as const;

export const useQueryFlag = (flag: (typeof queryStringFlags)[number]) => {
  const [flagsParam] = useQueryParam<string[]>(
    "flags",
    withDefault(CommaArrayParam, [])
  );
  if (flag in queryStringFlags) {
    return flagsParam?.includes(flag);
  }
  return false;
};

export const useFeatureFlag = (flag: (typeof adminFeatureFlags)[number]) => {
  const { features } = useFeatureValues();
  if (adminFeatureFlags.includes(flag) && ["0", "1"].includes(features[flag])) {
    return features[flag] === "1";
  }
  if (adminFeatureFlags[flag]) {
    return features[flag];
  }
  return false;
};

// eslint-disable-next-line import/no-unused-modules
export const useFutureFlag = (flag: (typeof futureFeatureFlags)[number]) => {
  const { futureFeatures } = useFeatureValues();
  if (futureFeatureFlags.includes(flag)) {
    return futureFeatures[flag];
  }
  return false;
};

export const useKurlInstallerAppFlag = () => {
  const kurlInstallerEntitlementEnabled = useTeamEntitlements()?.enable_kurl_installer;
  return kurlInstallerEntitlementEnabled;
};

export const useKotsInstallerAppFlag = () => {
  const kotsInstallerEntitlementEnabled = useTeamEntitlements()?.enable_kots_installer;
  return kotsInstallerEntitlementEnabled;
};
