import "isomorphic-fetch";
import { SessionManager } from "../../../../services/SessionManager";
import { getChannelList, getBranding } from "./channels/actions";
import {
  getActiveReleases,
  getReleaseList,
  setStartIndexAndPageCount
} from "./releases/actions";
import { searchLicenses } from "./licenses/actions";
import { getLicenseFields } from "./license_fields/actions";
import { getImages } from "./images/actions";
import { logOutUser } from "../../../auth/sessions/actions";
import { loadingData } from "../../../ui/main/actions";
import { getAppSettings } from "../app_settings/actions";
import { forbiddenData } from "../../../ui/main/actions";

export const RECEIVE_APP_LIST = "RECEIVE_APP_LIST";

function receiveAppList(appList) {
  return {
    type: RECEIVE_APP_LIST,
    payload: appList
  };
}

export function refreshForNewApp(appId, isLegacy) {
  return (dispatch, getState) => {
    dispatch(getChannelList(appId, isLegacy));
    dispatch(getActiveReleases(appId));
    dispatch(getReleaseList(appId, 0, 20));
    dispatch(setStartIndexAndPageCount(0, 20));
    dispatch(
      searchLicenses(appId, {
        query: getState().ui.main.apps.licenses.customSearchQuery,
        offset: 0,
        page_size: 20,
        sort_field: "assignee",
        sort_direction: "asc",
        include_dev: true,
        include_trial: true,
        include_paid: true,
        include_active: true,
        include_inactive: true,
        include_archived: false
      })
    );
    dispatch(getLicenseFields(appId));
    dispatch(getBranding(appId));
    dispatch(getImages(appId));
    dispatch(getAppSettings(appId));
  };
}

export function getAppList() {
  return async (dispatch, getState) => {
    dispatch(loadingData("appList", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/apps?excludeChannels=true`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("appList", false));
          dispatch(forbiddenData("appList", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      if (body) {
        const appList = body.map(value => {
          return value.App;
        });
        dispatch(receiveAppList(appList));
      } else {
        dispatch(receiveAppList([]));
      }
      dispatch(loadingData("appList", false));
    } catch (error) {
      dispatch(loadingData("appList", false));
      console.log(error);
      return;
    }
  };
}
