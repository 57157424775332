import "isomorphic-fetch";

import {
  displayModal,
  loadingData,
  forbiddenData,
  errorData
} from "../../../../ui/main/actions";
import { logOutUser } from "../../../../auth/sessions/actions";
import { SessionManager } from "../../../../../services/SessionManager";
import { getChannelList } from "../channels/actions";
import { getReleaseProperties } from "../view_release/actions";

export const SET_RELEASE_LIST = "SET_RELEASE_LIST";
export const SET_ACTIVE_RELEASE_LIST = "SET_ACTIVE_RELEASE_LIST";
export const SET_PREFLIGHT_CHECKS = "SET_PREFLIGHT_CHECKS";
export const SET_START_INDEX_AND_PAGE_COUNT = "SET_START_INDEX_AND_PAGE_COUNT";
export const SET_PROMOTE_PATCH_RELEASE_ERROR = "SET_PROMOTE_PATCH_RELEASE_ERROR";
export const CLEAR_PROMOTE_PATCH_RELEASE_ERROR = "CLEAR_PROMOTE_PATCH_RELEASE_ERROR";
export const SET_LATEST_RELEASE = "SET_LATEST_RELEASE";
export const SET_PROMOTE_RELEASE_ERROR = "SET_PROMOTE_RELEASE_ERROR";
export const CLEAR_PROMOTE_RELEASE_ERROR = "CLEAR_PROMOTE_RELEASE_ERROR";

function setReleaseList(releaseList) {
  return {
    type: SET_RELEASE_LIST,
    payload: {
      releases: releaseList.releases,
      total_count: releaseList.total_count
    }
  };
}

function setActiveReleases(activeReleases) {
  return {
    type: SET_ACTIVE_RELEASE_LIST,
    payload: {
      activeReleases: activeReleases.releases
    }
  };
}

export function setStartIndexAndPageCount(startIndex, pageCount) {
  return {
    type: SET_START_INDEX_AND_PAGE_COUNT,
    payload: {
      startIndex: startIndex,
      pageCount: pageCount
    }
  };
}

function setPreflightChecks(checks) {
  return {
    type: SET_PREFLIGHT_CHECKS,
    payload: checks
  };
}

function setPromotePatchReleaseError(err) {
  return {
    type: SET_PROMOTE_PATCH_RELEASE_ERROR,
    payload: err
  };
}

export function clearPromotePatchReleaseError() {
  return {
    type: CLEAR_PROMOTE_PATCH_RELEASE_ERROR
  };
}

function setPromoteReleaseError(err) {
  return {
    type: SET_PROMOTE_RELEASE_ERROR,
    payload: err
  };
}

export function clearPromoteReleaseError() {
  return {
    type: CLEAR_PROMOTE_RELEASE_ERROR
  };
}

export function getActiveReleases(appId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("activeReleases", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/releases/active`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        },
        mode: "cors"
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("activeReleases", false));
          dispatch(forbiddenData("activeReleases", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setActiveReleases(body));
      dispatch(forbiddenData("activeReleases", false));
      dispatch(loadingData("activeReleases", false));
      dispatch(errorData("activeReleases", false));
    } catch (error) {
      dispatch(loadingData("activeReleases", false));
      dispatch(errorData("activeReleases", true));
      console.log(error);
      return;
    }
  };
}

export function getReleaseList(appId, startIndex = 0, pageCount = 20) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("releases", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/releases/paged?start=${startIndex}&count=${pageCount}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        },
        mode: "cors"
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("releases", false));
          dispatch(forbiddenData("releases", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setReleaseList(body));
      dispatch(setStartIndexAndPageCount(startIndex, pageCount));
      dispatch(forbiddenData("releases", false));
      dispatch(loadingData("releases", false));
      dispatch(errorData("releases", false));
    } catch (error) {
      dispatch(loadingData("releases", false));
      dispatch(errorData("releases", true));
      console.log(error);
      return;
    }
  };
}

export function promoteRelease(appId, sequence, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("promoteRelease", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/${sequence}/promote`;

      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        if (response.status === 409) {
          try {
            const error = await response.json();
            dispatch(setPromoteReleaseError(error?.error));
          } catch (err) {
            console.log(err);
          }
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }

      dispatch(getActiveReleases(appId));
      dispatch(getReleaseList(appId));
      dispatch(getReleaseProperties(appId, sequence));
      dispatch(loadingData("promoteRelease", false));
      dispatch(displayModal("promote", false));
      dispatch(getChannelList(appId, true));
    } catch (error) {
      dispatch(loadingData("promoteRelease", false));
      console.log(error);
      return;
    }
  };
}

export function promotePatchRelease(appId, sequence, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("promoteRelease", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/${sequence}/promote-patch`;

      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        const res = await response.json();
        dispatch(setPromotePatchReleaseError(res.error));
        dispatch(loadingData("promoteRelease", false));
        dispatch(displayModal("promote", true));
        return;
      }

      dispatch(getActiveReleases(appId));
      dispatch(getReleaseList(appId));
      dispatch(getReleaseProperties(appId, sequence));
      dispatch(loadingData("promoteRelease", false));
      dispatch(displayModal("promote", false));
      dispatch(getChannelList(appId, true));
    } catch (error) {
      dispatch(loadingData("promoteRelease", false));
      console.log(error);
      return;
    }
  };
}

export function getPreflightChecks(appId, sequence) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("preflightChecks", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/${sequence}/preflightchecks`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("preflightChecks", false));
          dispatch(forbiddenData("preflightChecks", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setPreflightChecks(body));
      dispatch(loadingData("preflightChecks", false));
    } catch (e) {
      dispatch(loadingData("preflightChecks", false));
      return;
    }
  };
}

export function archiveRelease(appId, sequence) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/${sequence}/archive`;
      response = await fetch(url, {
        method: "POST",
        body: sequence,
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(getReleaseList(appId));
      dispatch(displayModal("archiveRelease", false));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function archiveMultipleReleases(appId, sequences) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/release?${sequences}`;
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(getReleaseList(appId));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}
