import { combineReducers } from "redux";
import { modals, loading, forbidden, error } from "./reducer";
import apps from "./apps";
import accountSettings from "./account_settings";

export default combineReducers({
  apps,
  accountSettings,
  modals,
  loading,
  forbidden,
  error
});
