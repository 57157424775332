import { Helmet } from "react-helmet";

const defaultTitle = "Replicated";

const PageTitle = ({ children = defaultTitle }) => (
  <Helmet>
    <title>{children}</title>
  </Helmet>
);

export { PageTitle };
