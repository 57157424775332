import {
  CLEAR_ARCHIVE_ERROR,
  SET_ARCHIVE_ERROR,
  SET_CHANNEL_BRANDING,
  SET_CHANNEL_LIST,
  SET_LEGACY_CHANNEL_LIST
} from "./actions";
import keyBy from "lodash/keyBy";
import type { Action } from "@/types";

const initialState = {
  channels: {},
  branding: {},
  archiveErr: false,
  archiveErrMessage: ""
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_CHANNEL_LIST:
      return Object.assign({}, state, {
        channels: keyBy(action.payload, "id")
      });
    case SET_LEGACY_CHANNEL_LIST:
      return {
        ...state,
        channels: action.payload
      };
    case SET_CHANNEL_BRANDING:
      return Object.assign({}, state, {
        branding: action.payload
      });
    case CLEAR_ARCHIVE_ERROR:
      return Object.assign({}, state, {
        archiveErr: false,
        archiveErrMessage: ""
      });
    case SET_ARCHIVE_ERROR:
      return Object.assign({}, state, {
        archiveErr: true,
        archiveErrMessage: action.payload
      });
    default:
      return state;
  }
}
