import { v4 as uuid } from "uuid";
import { apiFetchVendor, gzipData, notifyError } from "@/utilities/VendorUtilities";

// Singleton pageLoadID for all events on a given page load.
const pageLoadID = uuid();

const useSendEvent = () => {
  return async eventName => {
    if (process.env.NODE_ENV === "test") {
      // There is no API server in test mode, and metrics endpoints are not mocked anywhere.
      return;
    }

    const event = {
      eventName,
      pageLoadID,
      sourceURL: window.location.href
    };
    const payload = gzipData(event);
    const response = await apiFetchVendor("/portal/data", {
      method: "POST",
      body: JSON.stringify({ payload }),
      enableErrorHandling: false
    });
    if (!response.ok) {
      try {
        const body = await response.json();
        notifyError(new Error(JSON.stringify(body)));
      } catch (e) {
        // no-op
      }
    }
  };
};

export default useSendEvent;
