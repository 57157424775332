import cx from "classnames";
import Description from "@/components/shared/Description";

interface ModalHeaderProps {
  className?: string;
  hasBorder?: boolean;
  centerText?: boolean;
  title?: string;
  showClose?: boolean;
  requestClose?: () => void;
  description?: string;
  docsUrl?: string;
  subdescription?: string;
}

const ModalHeader = ({
  className,
  centerText,
  title,
  showClose = true,
  description,
  docsUrl,
  subdescription,
  requestClose
}: ModalHeaderProps) => (
  <div className={cx("Modal-header-compat tw-pb-4", className)}>
    <div className="flex flex1 justifyContent--spaceBetween">
      <h3
        className={cx(
          centerText && "u-textAlign--center",
          "tw-font-poppins tw-font-semibold tw-text-2xl tw-tracking-normal tw-text-[#161616]"
        )}
      >
        {title}
      </h3>
      {showClose && (
        <div
          data-testid="close-modal-btn"
          className="flex-auto CloseButton-wrapper flex-column flex-verticalCenter"
          onClick={() => requestClose?.()}
        >
          <span className="icon clickable u-closeIcon"></span>
        </div>
      )}
    </div>
    {description && (
      <Description
        text={description}
        docsUrl={docsUrl}
        className="u-paddingRight--more"
      />
    )}
    {subdescription && (
      <Description text={subdescription} className="u-paddingRight--more" />
    )}
  </div>
);

export default ModalHeader;
