import { Switch } from "@headlessui/react";
import cx from "classnames";

const getContainerSizeClass = (size: string) => {
  switch (size) {
    case "small":
      return "tw-h-5 tw-w-10";
    case "normal":
      return "tw-h-6 tw-w-11";
    case "large":
      return "tw-h-7 tw-w-12";
    default:
      return "tw-h-6 tw-w-11";
  }
};

const getSwitchSizeClass = (size: string) => {
  switch (size) {
    case "small":
      return "tw-h-4 tw-w-4";
    case "normal":
      return "tw-h-5 tw-w-5";
    case "large":
      return "tw-h-6 tw-w-6";
    default:
      return "tw-h-5 tw-w-5";
  }
};

interface SwitchInputProps {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  labelDirection?: "right" | "left";
  name: string;
  onChange: (checked: boolean) => void;
  size?: "small" | "normal" | "large";
}

const SwitchInput = ({
  className,
  checked,
  disabled,
  label,
  labelDirection = "left",
  name,
  onChange,
  size = "normal"
}: SwitchInputProps) => {
  return (
    <div
      className={cx(
        "tw-text-gray-600 tw-text-sm tw-font-semibold",
        "tw-flex tw-items-center tw-gap-2",
        className
      )}
      data-testid={`${name}-switch`}
    >
      {label && labelDirection === "left" && <span>{label}</span>}
      <Switch
        disabled={disabled}
        name={`${name}-switch`}
        checked={checked}
        onChange={onChange}
        className={cx(
          checked ? "tw-bg-teal-300" : "tw-bg-gray-400",
          disabled ? "tw-opacity-50 tw-cursor-not-allowed" : "tw-cursor-pointer",
          "tw-inline-flex tw-items-center tw-rounded-full tw-border-0",
          getContainerSizeClass(size)
        )}
      >
        <span className="tw-sr-only">{label}</span>
        <span
          className={cx(
            checked ? "tw-translate-x-4" : "tw--translate-x-1",
            "tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-transition",
            getSwitchSizeClass(size)
          )}
        />
      </Switch>
      {label && labelDirection === "right" && <span>{label}</span>}
    </div>
  );
};

export default SwitchInput;
