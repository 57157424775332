import { connect } from "react-redux";
import realLogin from "../auth/Login";

import {
  handleLogIn,
  handleOtpLogin,
  handleGoogleLogin,
  sendPasswordResetEmail,
  setResetPasswordStep,
  clearServerError,
  logOutUser
} from "../../redux/auth/sessions/actions";
import { displayModal, loadingData } from "../../redux/ui/main/actions";

const Login = connect(
  state => ({
    modals: state.ui.main.modals,
    err: state.auth.sessions.sessionData.err,
    errMessage: state.auth.sessions.sessionData.errMessage,
    hasRehydrated: state.auth.sessions.sessionData.hasRehydrated,
    dataLoading: state.ui.main.loading,
    needsOtp: state.auth.sessions.sessionData.needsOtp,
    hasToken: state.auth.sessions.sessionData.accessToken,
    resetPasswordStep: state.auth.sessions.sessionData.resetPasswordStep,
    resetPasswordError: state.auth.sessions.sessionData.resetPasswordError,
    user: state.auth.sessions.sessionData.user
  }),
  dispatch => ({
    handleLogIn(payload, router, nextPage) {
      return dispatch(handleLogIn(payload, router, nextPage));
    },
    handleOtpLogin(payload, callback) {
      return dispatch(handleOtpLogin(payload, callback));
    },
    handleGoogleLogin() {
      return dispatch(handleGoogleLogin());
    },
    sendPasswordResetEmail(payload) {
      return dispatch(sendPasswordResetEmail(payload));
    },
    setResetPasswordStep(step) {
      return dispatch(setResetPasswordStep(step));
    },
    clearServerError() {
      return dispatch(clearServerError());
    },
    logOutUser() {
      return dispatch(logOutUser());
    },
    loadingData(key, isLoading) {
      return dispatch(loadingData(key, isLoading));
    },
    displayModal(key, display) {
      return dispatch(displayModal(key, display));
    }
  })
)(realLogin);

export default Login;
