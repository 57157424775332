import { useEffect, useReducer } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";

import { apiFetch, VendorUtilities } from "@/utilities/VendorUtilities";
import { CookieStore } from "@/services/CookieStore";
import { getTeamAndUserData, setLoadingToFalse } from "@/redux/auth/sessions/actions";
import { SessionManager } from "@/services/SessionManager";
import SwitchInput from "@/components/shared/SwitchInput";

import "@/scss/components/auth/AuthPage.scss";
import { Button } from "../shared/Button";

const CompleteSignup = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      company: "",
      autojoinEnabled: false
    }
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.auth.sessions.sessionData.user);
  const team = useSelector(state => state.auth.sessions.sessionData.team);
  const {
    mutate: updateAccountInformation,
    loading: updateAccountLoading,
    error: updateAccountError
  } = useMutation({
    mutationFn: async payload => {
      const path = `/user/update/${user.id}`;
      return (
        await apiFetch(path, {
          endpoint: SessionManager.getApiEndpoint(),
          method: "PUT",
          body: JSON.stringify(payload)
        })
      ).json();
    },
    retry: false,
    onSuccess: () => {
      updateTeamInformation({
        name: state.company.trim(),
        team_autojoin_enabled: state.autojoinEnabled
      });
    }
  });
  const {
    mutate: updateTeamInformation,
    loading: updateTeamLoading,
    error: updateTeamError
  } = useMutation({
    mutationFn: async payload => {
      const path = "/team";
      return (
        await apiFetch(path, {
          endpoint: SessionManager.getApiEndpoint(),
          method: "PUT",
          body: JSON.stringify(payload)
        })
      ).json();
    },
    retry: false,
    onSuccess: () => {
      if (CookieStore.getItem("team-selection")) {
        history.push("/team-selection");
      } else {
        history.push("/apps");
      }
    }
  });

  const handleAutojoinToggle = async () => {
    setState({
      autojoinEnabled: !state.autojoinEnabled
    });
  };

  const handleFormChange = (field, e) => {
    let nextState = {};
    let val = e.target.value;
    nextState[field] = val;
    setState(nextState);
  };

  const onSubmit = e => {
    e.preventDefault();

    updateAccountInformation({
      firstname: state.firstName.trim(),
      lastname: state.lastName.trim()
    });
  };

  useEffect(() => {
    VendorUtilities.purgeVOneData();
    dispatch(setLoadingToFalse());
    dispatch(getTeamAndUserData());
  }, []);

  useEffect(() => {
    if (user.firstname !== state.firstName || user.lastname !== state.lastName) {
      setState({
        firstName: user.firstname || "",
        lastName: user.lastname || ""
      });
    }
  }, [user]);

  useEffect(() => {
    if (team && !team.needs_profile) {
      history.push("/apps");
    }
  }, [team]);

  return (
    <div className="u-overflow--auto container flex-column flex1 alignItems--center flex-verticalCenter bg-color--teal">
      <div className="tw-flex tw-flex-col tw-items-center lg:tw-flex-row tw-gap-4 lg:tw-gap-0 tw-flex-auto tw-w-full CreateApp-wrapper tw-my-6">
        <div className="flex1 flex-verticalCenter illustration-wrapper tw-hidden sm:tw-flex sm:tw-flex-col">
          <div className="left-content-wrap flex-column justifyContent--center alignItems--center">
            <span className="icon u-createTeam"></span>
            <div className="u-marginTop--normal">
              <p className="u-fontSize--large u-fontWeight--normal tw-text-gray-600 u-lineHeight--normal u-marginTop--normal">
                Your account is ready, but to use Replicated you'll need to create a
                Team.
              </p>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-8">
          <div className="right-content-wrap tw-flex tw-flex-col tw-gap-8 tw-box-border">
            <div>
              <h1 className="tw-text-indigo-500 u-lineHeight--normal">
                Create your team
              </h1>
              <p className="u-fontSize--large u-fontWeight--normal tw-text-gray-600 u-lineHeight--normal u-marginTop--normal">
                Before we get started, let's get some information about you and your
                team.
              </p>
            </div>
            <div className="CreateAppPlatform--formWrapper">
              <div className="auth-pages tw-flex tw-flex-col tw-gap-4">
                {updateAccountError && (
                  <div className="ErrorBlock !tw-m-0 !tw-p-4">
                    <p>{updateAccountError?.message}</p>
                  </div>
                )}
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                  <div>
                    <label htmlFor="first-name">First Name</label>
                    <input
                      className="Input"
                      type="text"
                      id="first-name"
                      placeholder="Jack"
                      size="20"
                      value={state.firstName}
                      onChange={e => {
                        handleFormChange("firstName", e);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="last-name">Last Name</label>
                    <input
                      className="Input"
                      type="text"
                      id="last-name"
                      placeholder="Shephard"
                      size="20"
                      value={state.lastName}
                      onChange={e => {
                        handleFormChange("lastName", e);
                      }}
                    />
                  </div>
                </div>
                <div>
                  {updateTeamError && (
                    <div className="ErrorBlock !tw-m-0 !tw-p-4">
                      <p>{updateTeamError?.message}</p>
                    </div>
                  )}
                  <label htmlFor="company-name">Company</label>
                  <input
                    className="Input"
                    type="text"
                    id="company-name"
                    placeholder="Company Name"
                    value={state.company}
                    onChange={e => {
                      handleFormChange("company", e);
                    }}
                  />
                </div>
                {user?.has_private_email_domain && (
                  <div className="tw-mt-2 tw-flex tw-flex-col tw-gap-2">
                    <SwitchInput
                      label="Enable Team Auto-join"
                      checked={state.autojoinEnabled}
                      onChange={handleAutojoinToggle}
                    />
                    <p className="tw-text-gray-600 tw-text-sm">
                      When team auto-join is enabled, anyone with a validated{" "}
                      <span className="tw-font-semibold">
                        {user?.email?.split("@")[1]}
                      </span>{" "}
                      email will be able to join this team. The default policy assigned
                      is <span className="tw-font-semibold">Read Only</span> - you can
                      change the default on the{" "}
                      <Link
                        target="_blank"
                        to="/team/autojoin"
                        className="tw-text-blue-300 hover:tw-underline tw-font-semibold"
                      >
                        Auto-join settings page
                      </Link>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-column right-content-wrap">
            <div className="FormButton-wrapper flex">
              <Button kind="primary-purple" size="large" onClick={onSubmit}>
                {updateAccountLoading || updateTeamLoading ? "Loading..." : "Continue"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteSignup;
