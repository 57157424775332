import type { Action } from "@/types";
import { IS_TROUBLESHOOT_OPEN_FROM_NAVBAR } from "./actions";

const initialState = {
  isTroubleshootOpenedFromNavbar: true
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case IS_TROUBLESHOOT_OPEN_FROM_NAVBAR:
      return Object.assign({}, state, {
        isTroubleshootOpenedFromNavbar: action.payload
      });
    default:
      return state;
  }
}
