import type { Action } from "@/types";
import {
  CLEAR_SERVER_ERROR,
  KOTS_SET_PASSWORD_CONFIRM_DIALOG,
  SET_CUSTOMER,
  SET_CUSTOMERS_LAST_UPDATED,
  SET_KOTS_CUSTOMERS_LIST,
  SET_PAGE_SIZE,
  SET_SERVER_ERROR
} from "./actions";

const initialState = {
  kotsCustomers: [],
  err: false,
  errMsg: "",
  displayConfirmDialog: false,
  pass: "",
  totalKotsCustomers: 0,
  pageSize: 20,
  customersLastUpdated: Date.now()
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_KOTS_CUSTOMERS_LIST:
      return Object.assign({}, state, {
        kotsCustomers: action.payload.kotsCustomers,
        totalKotsCustomers: action.payload.totalKotsCustomers
      });
    case SET_PAGE_SIZE: {
      return Object.assign({}, state, {
        pageSize: action.payload
      });
    }
    case SET_SERVER_ERROR:
      return Object.assign({}, state, {
        err: true,
        errMsg: action.payload.errorMsg
      });
    case CLEAR_SERVER_ERROR:
      return Object.assign({}, state, {
        err: false,
        errMsg: ""
      });
    case KOTS_SET_PASSWORD_CONFIRM_DIALOG:
      return Object.assign({}, state, {
        displayConfirmDialog: action.payload.display,
        pass: action.payload.password
      });
    case SET_CUSTOMER:
      return Object.assign({}, state, {
        kotsCustomer: action.payload.customer
      });
    case SET_CUSTOMERS_LAST_UPDATED:
      return Object.assign({}, state, {
        customersLastUpdated: action.payload
      });

    default:
      return state;
  }
}
