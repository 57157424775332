import type { Action } from "@/types";
import { SET_KOTS_APP_LIST } from "./actions";

const initialState = {
  kotsApps: []
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_KOTS_APP_LIST:
      return Object.assign({}, state, {
        kotsApps: action.payload.kotsApps.apps
      });
    default:
      return state;
  }
}
