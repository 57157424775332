import styled from "styled-components";
import { Header1, Header5 } from "../../styles";

export const Title = styled(Header1)`
  font-size: 36px;
  font-family: ${props => props.theme.tertiaryFont};
  margin-bottom: 10px;
`;

export const SubHeader = styled(Header5)`
  color: ${props => props.theme.gray600};
  line-height: 28px;
`;

export const TeamCard = styled.div`
  background-color: white;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

export const LeftTeamCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const TeamName = styled.p`
  font-size: 16px;
  color: ${props => props.theme.gray600};
  line-height: 22px;
  font-weight: 600;
`;

export const OrBlock = styled.div`
  width: 100%;
  display: flex;
  color: #bebebe;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 10px 0;
`;

export const Divider = styled.span`
  display: inline-block;
  flex-grow: 1;
  height: 1px;
  background-color: #bebebe;
`;

export const UpperTeamcard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TeamMembers = styled.div`
  width: 100%;
  font-size: 14px;
  font-family: "Helvetica Neue";
  font-weight: 500;
  margin-top: 8px;
`;
