import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import {
  createTransform,
  persistStore,
  autoRehydrate,
  getStoredState
} from "redux-persist";
import merge from "lodash/merge";
import thunk from "redux-thunk";

// Reducers
import AuthReducers from "./auth";
import DataReducers from "./data";
import UiReducers from "./ui";
import TroubleshootReducer from "./troubleshoot/reducer";
import SelectedAppReducer from "./selected-app/reducer";
import KotsReducer from "./kots";

// Constants
import { PURGE_ALL } from "./auth/sessions/actions";

const appReducer = combineReducers({
  data: DataReducers,
  ui: UiReducers,
  auth: AuthReducers,
  troubleshoot: TroubleshootReducer,
  selectedApp: SelectedAppReducer,
  kots: KotsReducer
});

export const rootReducer = (state, action) => {
  if (action.type === PURGE_ALL) {
    state = undefined;
  }
  return appReducer(state, action);
};

// Resets password reset state on hydration to ensure password reset form
// is not hidden by previous password reset success
export function hydratePasswordResetStep(state) {
  if (state.sessions === undefined) {
    return state;
  }

  return merge(state, {
    sessions: {
      sessionData: {
        passwordResetStep: "info"
      }
    }
  });
}
const passwordResetStepTransform = createTransform(
  state => state,
  state => hydratePasswordResetStep(state),
  {}
);

let store;
export function configStore() {
  return new Promise((resolve, reject) => {
    try {
      const hasExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
      getStoredState(["auth", "troubleshoot", "selectedApp"], (err, state) => {
        store = createStore(
          rootReducer,
          state,
          compose(
            applyMiddleware(thunk),
            autoRehydrate(),
            hasExtension ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : f => f
          )
        );

        const config = {
          transforms: [passwordResetStepTransform],
          whitelist: ["auth", "troubleshoot", "selectedApp"]
        };
        persistStore(store, config, () => resolve(store));
      });
    } catch (e) {
      reject(e);
    }
  });
}

export const getStore = () => store;
