export const IS_TROUBLESHOOT_OPEN_FROM_NAVBAR = "IS_TROUBLESHOOT_OPEN_FROM_NAVBAR";

export function setIsTroubleshootOpenFromNavbar() {
  return {
    type: IS_TROUBLESHOOT_OPEN_FROM_NAVBAR,
    payload: true
  };
}

export function setIsTroubleshootOpenFromSidebar() {
  return {
    type: IS_TROUBLESHOOT_OPEN_FROM_NAVBAR,
    payload: false
  };
}
