import isEmpty from "lodash/isEmpty";
import { Response } from "node-fetch";
import queryString from "query-string";
import { ChangeEvent, SyntheticEvent, useEffect, useReducer } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { DataLoading, User } from "../../types";
import { VendorUtilities } from "../../utilities/VendorUtilities";
import { Button } from "../shared/Button";
import Footer from "../shared/Footer";
import ReplicatedInfo from "./ReplicatedInfo";

import "../../scss/components/auth/AuthPage.scss";

interface RouteParams {
  next: string;
}

interface SignupProps extends RouteComponentProps<RouteParams> {
  clearSignupValidationErrors: () => void;
  dataLoading: DataLoading;
  handleGoogleSignup: (isBuildersOnly: boolean) => void;
  handleSignUp: ({
    email,
    isBuildersOnly
  }: {
    email: string;
    isBuildersOnly?: boolean;
  }) => Response;
  hasToken?: string;
  setLoadingToFalse: () => void;
  signupValidationErrors: {
    email?: {
      error: string;
    };
  };
  user: User;
  isBuildersOnly?: boolean;
}

interface SignupState {
  email?: string;
  emailError?: boolean;
  emailMessage?: string;
}

const Signup = ({
  clearSignupValidationErrors,
  dataLoading,
  handleGoogleSignup,
  handleSignUp,
  hasToken,
  history,
  location,
  match,
  setLoadingToFalse,
  signupValidationErrors,
  user,
  isBuildersOnly
}: SignupProps) => {
  const [state, setState] = useReducer(
    (prevState: SignupState, newState: SignupState) => ({
      ...prevState,
      ...newState
    }),
    {
      email: "",
      emailError: false,
      emailMessage: ""
    }
  );

  const clearErrors = () => {
    setState({
      emailError: false,
      emailMessage: ""
    });
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (state.emailError) {
      clearErrors();
    }
    if (!isEmpty(signupValidationErrors)) {
      clearSignupValidationErrors();
    }
    setState({
      email: e.target.value
    });
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    clearErrors();

    const response = await handleSignUp({
      email: state.email.trim(),
      ...(isBuildersOnly ? { isBuildersOnly: true } : {})
    });

    if (response?.status === 201) {
      if (response.json) {
        const body: { token?: string } = await response.json();
        if (body.token) {
          history.push(`/signup/verify#${body.token}`);
          return;
        }
      }

      history.push("/signup/verify");
    }
  };

  const handleGoogleSignupClick = ev => {
    ev.preventDefault();
    handleGoogleSignup(isBuildersOnly ? true : false);
  };

  const buildErrors = (error: { email?: { error: string } }) => {
    if (error.email) {
      setState({
        emailError: true,
        emailMessage: error.email.error
      });
    }
  };

  useEffect(() => {
    if (signupValidationErrors) {
      buildErrors(signupValidationErrors);
    }
  }, [signupValidationErrors]);

  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    const inviteId = parsedSearch?.invite || location.hash.slice(1);
    const replaceAccount = parsedSearch?.replace_account;
    if (inviteId || replaceAccount) {
      const inviteParams = inviteId ? `#${inviteId}` : "";
      const replaceAccountParams = replaceAccount
        ? `?replace_account=${replaceAccount}`
        : "";

      history.push(
        `/invite${replaceAccountParams}${
          inviteParams && replaceAccountParams && "&"
        }${inviteParams}`
      );
      return;
    }

    VendorUtilities.purgeVOneData();
    clearSignupValidationErrors();

    const { next } = match.params;

    // if user is logged in, redirect to the next page
    if (!!hasToken && user?.id) {
      history.push(`/${next || "apps"}`);
      return;
    }

    setLoadingToFalse();
  }, []);

  // if a user is already logged in
  // we return nothing while we wait for our useEffect to redirect them
  if (!!hasToken && user?.id) return null;

  return (
    <div className="u-width--full u-overflow--auto flex-column">
      <div className="u-flexTabletReflowReverse flex-1-auto u-width--full">
        <ReplicatedInfo needsActivation={false} />
        <div className="Login-wrapper flex1 flex-column flex-verticalCenter alignItems--center u-paddingBottom--normal u-textAlign--left auth-pages">
          <div className="Form-wrapper">
            <p className="form-title u-lineHeight--normal">Start a trial</p>
          </div>
          <div className="Form-wrapper">
            <p className="u-fontSize--large u-fontWeight--normal u-color--dustyGray u-lineHeight--normal u-marginTop--normal">
              Sign up and get access to the full Replicated platform for 21 days to
              build your technical proof-of-concept.
            </p>
            <div className="flex u-marginTop--15 u-marginBottom--more">
              <a
                href="#"
                onClick={handleGoogleSignupClick}
                className="Button third-party-auth google flex alignItems--center justifyContent--center"
              >
                <span className="icon clickable google-g u-marginRight--normal" />{" "}
                Continue with Google
              </a>
            </div>
            <div className="u-orTransitionBlock">
              <span>or</span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="FormSection-wrapper auth-pages">
                {state.emailError && (
                  <div className="ErrorBlock u-marginBottom--small">
                    <p>{state.emailMessage}</p>
                  </div>
                )}
                <div className="u-marginBottom--normal">
                  <label htmlFor="companyEmail">Company Email</label>
                  <input
                    id="companyEmail"
                    className="Input"
                    type="text"
                    placeholder="jack@example.com"
                    value={state.email}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="FormButton-wrapper flex">
                <Button
                  kind="primary-purple"
                  className="flex-auto tw-mt-2.5 tw-mb-5 tw-w-full !tw-text-lg"
                  disabled={dataLoading.signupLoading}
                >
                  {dataLoading.signupLoading ? "Loading..." : "Get Started"}
                </Button>
              </div>
              <div className="flex-auto u-marginLeft--normal u-fontSize--normal flex-verticalCenter flex-column">
                <p className="u-color--dustyGray u-textAlign--center">
                  Have an account?{" "}
                  <Link
                    to="/login"
                    className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                  >
                    Log in
                  </Link>
                </p>
              </div>
              <div className="flex-auto u-marginLeft--normal u-fontSize--normal flex-verticalCenter flex-column">
                <p className="u-color--dustyGray u-textAlign--center u-paddingTop--more">
                  By clicking "Get Started", I agree to Replicated’s{" "}
                  <a
                    className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                    target="_blank"
                    href="https://replicated.com/terms"
                    rel="noreferrer"
                  >
                    TOS
                  </a>{" "}
                  and{" "}
                  <a
                    className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                    target="_blank"
                    href="https://replicated.com/privacy"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </form>
          </div>
          <div className="auth-footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signup);
