import { connect } from "react-redux";
import realLogout from "../auth/Logout";

import { logOutUser } from "../../redux/auth/sessions/actions";
const Logout = connect(
  state => ({
    dataLoading: state.ui.main.loading
  }),
  dispatch => ({
    logOutUser() {
      return dispatch(logOutUser());
    }
  })
)(realLogout);

export default Logout;
