import { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="u-minHeight--full u-width--full u-overflow--auto container">
        <div className="PolicyPage u-paddingTop--most">
          <h3>Privacy policy</h3>
          <p>
            Replicated collects data across a number of channels, including our
            websites, newsletters, account setup process, and from usage of the
            Replicated platform itself. Here is what we collect via which channels, and
            how we use it.
          </p>

          <h4>Website</h4>

          <p>
            Replicated operates multiple websites, including replicated.com,
            enterpriseready.io, enterprisegrade.io and fromlatest.io. Data collected on
            those sites includes:
          </p>

          <ul>
            <li>
              <b>
                Various log data (including browser, location and device information):
              </b>{" "}
              Any log data we collect is aggregated and used to improve the content of
              our web pages and the quality of our service.
            </li>
            <li>
              <b>IP addresses:</b> IP addresses may be shared anonymously with third
              parties (e.g., Twitter) for advertising purposes, but are not otherwise
              used to track visitors.
            </li>
            <li>
              <b>Cookies:</b> We use cookies to record current session information and
              manage account logins, but do not use permanent cookies.
            </li>
          </ul>

          <h4>Newsletters and blog</h4>

          <p>
            Replicated operates multiple newsletters and blog sites. Data collected via
            those services includes:
          </p>

          <ul>
            <li>
              <b>Email addresses:</b> Email addresses are used only to communicate with
              subscribers.
            </li>
            <li>
              <b>IP addresses and location data:</b> IP addresses and location data are
              used to gain visibility into where subscribers reside, for purposes of
              better understanding our reach.
            </li>
            <li>
              <b>Subscriber activity:</b> We collect aggregate data such as newsletter
              open and click rates, and individual data such as whether subscribers open
              an email or click on a link.
            </li>
          </ul>

          <p>
            All data collected via our newsletters and blog are used solely to improve
            the content of those services, and are not shared or sold for commercial
            purposes.
          </p>

          <h4>Account setup</h4>

          <p>
            Signing up for an account with the Replicated service requires providing
            several pieces of information, including:
          </p>

          <ul>
            <li>
              <b>Company name</b>
            </li>
            <li>
              <b>Email address</b>
            </li>
            <li>
              <b>Password</b>
            </li>
          </ul>

          <p>
            Account holders that become Replicated customers will also need to provide
            billing data through a third-party payment processor. Information gathered
            will include:
          </p>

          <ul>
            <li>
              <b>Name</b>
            </li>
            <li>
              <b>Billing Address</b>
            </li>
          </ul>

          <p>
            Replicated uses information collected from account holders for the following
            general purposes: products and services provision, billing, identification
            and authentication, services improvement, contact, and research. Any
            collection and usage of account data is also governed by a separate Terms of
            Service agreement.
          </p>

          <h4>The Replicated platform</h4>

          <p>
            Data collection and privacy related to our product, the Replicated platform,
            is governed by a separate customer agreement and Data Processing Addendum.
            These documents lay out our compliance with the European Union General Data
            Protection Regulation (GDPR), as well as the EU-US and Swiss-US Privacy
            Shield frameworks, with regard to privacy of personal data collected and
            processed as part of the customer relationship and platform usage.
          </p>

          <h4>Cookie policy</h4>

          <p>
            A cookie is a small amount of data, which often includes an anonymous unique
            identifier, that is sent to your browser from a website’s computers and
            stored on your computer’s hard drive. We use cookies to record current
            session information, but do not use permanent cookies. You are required to
            re-login to your Replicated account after a certain period of time has
            elapsed to protect you against others accidentally accessing your account
            contents (and you are highly encouraged to activate two-factor
            authentication on your account).
          </p>

          <h4>Analytics services</h4>

          <p>
            Replicated uses Google Analytics to determine how users use our websites.
            Google Analytics uses cookies to gather information about which pages users
            access on the website and which sites users visited prior to accessing a
            Replicated website. Google Analytics will collect only IP Address assigned
            to the user on the day they visit the website, we do not combine Google
            Analytics with any personally identifying information. We use Google
            Analytics only to improve our websites.
          </p>

          <h4>When we share your data</h4>

          <p>
            Replicated may share or store data collected via our web properties
            (website, newsletters, blog, account setup) with third parties, for the
            following purposes:
          </p>

          <ul>
            <li>
              <b>Legal requirements:</b> It is necessary to share information in order
              to investigate, prevent, or take action regarding illegal activities,
              suspected fraud, situations involving potential threats to the physical
              safety of any person, or as otherwise required by law.
            </li>
            <li>
              <b>Acquisition:</b> IP addresses and location data are used to gain
              visibility into where subscribers reside, for purposes of better
              understanding our reach.
            </li>
            <li>
              <b>Advertising:</b> We occasionally (and automatically) send anonymous
              data about site visitors to third-party platforms for the purposes of
              targeted advertising. For example, individuals who have visited our site
              while logged into Twitter might see relevant ads in their timeline,
              although Replicated does not know who visited the site or who is served
              such ads.
            </li>
            <li>
              <b>Customer research:</b> We use data provided during the account setup
              process in order to determine who is signing up and to qualify sales
              leads. In this case, information may be combined with data from other
              sources in order to verify identity and assess business prospects.
            </li>
          </ul>

          <h4>International transfers of information</h4>

          <p>
            Replicated and our service providers may transfer, store and process
            information, including information collected in the European Economic Area
            (“EEA”), to the United States and other countries with personal data
            protection laws with different laws and regulations governing personal data
            collection differing from your home country. Replicated’s application and
            database servers are located in the United States using Amazon Web Services
            and Google Compute Engine services which have certified with the Department
            of Commerce that they adhere to the Privacy Shield Principles under the
            EU-U.S. and Swiss-U.S. Privacy Shield frameworks. Replicated will protect
            all Personal Data in accordance with this Privacy Policy wherever it is
            processed and take appropriate steps to protect your personal data in
            accordance with applicable laws.
          </p>

          <h4>Changes</h4>

          <p>
            Replicated may periodically update this policy. We will notify you about
            significant changes in the way we treat personal information by sending a
            notice to the primary email address specified in your Replicated primary
            account holder account or by placing a prominent notice on our site. Such
            notice will be given at least 4 days in advance of the date the new policy
            will be applied.
          </p>

          <h4>Questions</h4>

          <p>
            Any questions about this Privacy Policy should be addressed to
            <a href="mailto:privacy@replicated.com"> privacy@replicated.com</a>.
          </p>
        </div>
      </div>
    );
  }
}
