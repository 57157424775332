import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import { useEffect, useReducer, useRef } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import Footer from "@/components/shared/Footer";
import { useLogOutUserMutation } from "@/query";
import {
  SET_ENFORCED_TWO_FACTOR,
  SET_SESSION_DATA
} from "@/redux/auth/sessions/actions";
import { SessionManager } from "@/services/SessionManager";
import { Team, User } from "@/types";
import { VendorUtilities, apiFetchVendorV1 } from "@/utilities/VendorUtilities";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Button } from "../shared/Button";
import ReplicatedInfo from "./ReplicatedInfo";

import "@/scss/components/auth/AuthPage.scss";

interface RouteParams {
  next: string;
}

interface AcceptInviteProps extends RouteComponentProps<RouteParams> {
  getInvite: (id: string) => void;
  handleGoogleInviteAccept: (id: string, replaceAccount: boolean) => void;
  hasToken?: string;
  invite: {
    createdAt: string;
    email: string;
    hasConflict: boolean;
    id: string;
  };
  team: Team;
  user: User;
}

interface AcceptInviteState {
  firstName?: string;
  lastName?: string;
  password?: string;
  email?: string;
  errors?: {
    validateError: boolean;
    firstName?: {
      error: string;
    };
    lastName?: {
      error: string;
    };
    password?: {
      error: string;
    };
    server?: {
      error: string;
    };
  };
  confirmPassword?: string;
  activationCodeSubmitted?: boolean;
  activationCode?: string;
  passwordVisible?: boolean;
  confirmPasswordVisible?: boolean;
}

const AcceptInvite = ({
  getInvite,
  handleGoogleInviteAccept,
  hasToken,
  history,
  invite,
  location,
  match,
  team,
  user
}: AcceptInviteProps) => {
  const [state, setState] = useReducer(
    (prevState: AcceptInviteState, newState: AcceptInviteState) => ({
      ...prevState,
      ...newState
    }),
    {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      confirmPassword: "",
      errors: null,
      activationCodeSubmitted: false,
      activationCode: "",
      passwordVisible: false,
      confirmPasswordVisible: false
    }
  );
  const firstNameRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const { mutate: logOutUser } = useLogOutUserMutation();
  const dispatch = useDispatch();

  const {
    mutate: handleAcceptInvite,
    isLoading: acceptInviteLoading,
    error: acceptInviteError
  } = useMutation({
    mutationFn: async (payload: {
      invite_id: string;
      first_name: string;
      last_name: string;
      password: string;
      replace_account: boolean;
      from_team_selection: boolean;
    }) => {
      const path = "/signup/accept-invite";
      return (
        await apiFetchVendorV1(path, {
          endpoint: SessionManager.getApiEndpoint(),
          method: "POST",
          body: JSON.stringify(payload),
          includeAuth: user?.has_google_authed
        })
      ).json();
    },
    onSuccess: data => {
      if (data.samlRedirect) {
        logOutUser(true);
        history.push(data.samlRedirect);
        return;
      }
      dispatch({ type: SET_SESSION_DATA, payload: data });
      SessionManager.setSessionId();

      if (!data.user["2fa_enabled"] && data.user["2fa_enforced"]) {
        dispatch({ type: SET_ENFORCED_TWO_FACTOR, payload: true });
        history.push("/require-2fa");
        return;
      }
    },
    onError: async (error: Error) => {
      const err = JSON.parse(error?.message);
      setState({
        errors: err
      });
    },
    retry: false
  });

  const toggleVisiblePassword = key => {
    setState({
      [`${key}Visible`]: !state[`${key}Visible`]
    });
    if (key === "password") {
      passwordRef.current.focus();
    } else {
      confirmPasswordRef.current.focus();
    }
  };

  const handleFormChange = e => {
    setState({
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setState({
      errors: null
    });

    if (state.password.trim() !== state.confirmPassword.trim()) {
      setState({
        errors: {
          password: {
            error: "Passwords must match"
          },
          validateError: true
        }
      });
      return;
    }

    const parsedSearch = queryString.parse(location.search);
    const inviteId = location.hash.slice(1);

    let replaceAccount = false;
    if (parsedSearch.replace_account) {
      replaceAccount = parsedSearch.replace_account === "1";
    }

    let inviteFromTeamSelection = false;
    if (parsedSearch.invite_from_team_selection) {
      inviteFromTeamSelection = parsedSearch.invite_from_team_selection === "1";
    }

    const payload = {
      invite_id: inviteId,
      first_name: state.firstName.trim(),
      last_name: state.lastName.trim(),
      password: state.password.trim(),
      replace_account: replaceAccount,
      from_team_selection: inviteFromTeamSelection
    };

    handleAcceptInvite(payload);
  };

  const setInviteState = () => {
    setState({
      email: invite.email
    });
  };

  const handleGoogleSignupClick = ev => {
    ev.preventDefault();

    const isInvite = !isEmpty(invite);
    if (isInvite) {
      const parsedSearch = queryString.parse(location.search);
      let replaceAccount = false;
      if (parsedSearch.replace_account) {
        replaceAccount = parsedSearch.replace_account === "1";
      }

      handleGoogleInviteAccept(invite.id, replaceAccount);
    }
  };

  useEffect(() => {
    if (invite && team) {
      setInviteState();
    }
  }, [invite, team]);

  useEffect(() => {
    VendorUtilities.purgeVOneData();

    const { next } = match.params;

    // if user is logged in, redirect to the next page
    if (!!hasToken && user?.id) {
      history.push(`/${next || "apps"}`);
      return;
    }

    const inviteId = location.hash.slice(1);
    if (inviteId) {
      getInvite(inviteId);
    } else {
      history.push("/signup");
    }

    if (isEmpty(acceptInviteError)) {
      if (firstNameRef.current) {
        firstNameRef.current.focus();
      }
    }
  }, []);

  // if a user is already logged in
  // we return nothing while we wait for our useEffect to redirect them
  if (!!hasToken && user?.id) return null;

  const isInvite = !isEmpty(invite) || (location.hash.slice(1) ? true : false);

  // if invite hasn't been set yet, show nothing until it loads
  if (!invite || !team) return null;

  return (
    <div className="u-width--full u-overflow--auto flex-column">
      <div className="u-flexTabletReflowReverse flex-1-auto u-width--full">
        <ReplicatedInfo needsActivation={false} />
        <div className="Login-wrapper flex1 flex-column flex-verticalCenter alignItems--center u-paddingBottom--normal u-textAlign--left auth-pages">
          <div className="Form-wrapper !tw-mt-8 md:tw-mt-0">
            <h1 className="tw-text-3xl tw-text-indigo-500 u-lineHeight--normal tw-text-center">
              Join {team.name}'s team
            </h1>
          </div>
          <div className="Form-wrapper">
            <div className="flex u-marginTop--15 u-marginBottom--more">
              <a
                href="#"
                onClick={handleGoogleSignupClick}
                className="Button third-party-auth google flex alignItems--center justifyContent--center"
              >
                <span className="icon clickable google-g u-marginRight--normal" />{" "}
                Continue with Google
              </a>
            </div>
            {!team?.is_google_auth_required && (
              <>
                <div className="u-orTransitionBlock">
                  <span>or</span>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="FormSection-wrapper auth-pages !tw-pb-2 tw-flex tw-flex-col tw-gap-4">
                    {state.errors?.server ? (
                      <div className="ErrorBlock !tw-text-pink-600 u-marginBottom--small">
                        {state.errors.server?.error}
                      </div>
                    ) : null}
                    {state.errors?.firstName ? (
                      <div className="ErrorBlock !tw-text-pink-600 u-marginBottom--small">
                        {state.errors.firstName?.error}
                      </div>
                    ) : null}
                    {state.errors?.lastName ? (
                      <div className="ErrorBlock !tw-text-pink-600 u-marginBottom--small">
                        {state.errors.lastName?.error}
                      </div>
                    ) : null}
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <div>
                        <label htmlFor="firstName">First Name</label>
                        <input
                          id="firstName"
                          className={`Input ${
                            state.errors?.firstName && "!tw-border-pink-400"
                          }`}
                          type="text"
                          placeholder="Jack"
                          ref={firstNameRef}
                          size={20}
                          value={state.firstName}
                          name="firstName"
                          onChange={handleFormChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          id="lastName"
                          className={`Input ${
                            state.errors?.lastName && "!tw-border-pink-400"
                          }`}
                          type="text"
                          placeholder="Shephard"
                          size={20}
                          value={state.lastName}
                          name="lastName"
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="companyEmail">Company Email</label>
                      <input
                        id="companyEmail"
                        className={`Input ${isInvite ? "is-disabled" : ""}`}
                        type="text"
                        placeholder="jack@example.com"
                        value={state.email}
                        disabled={isInvite}
                        name="email"
                      />
                    </div>
                    {state.errors?.password ? (
                      <div className="ErrorBlock !tw-text-pink-600 u-marginBottom--small">
                        {state.errors.password?.error}
                      </div>
                    ) : null}
                    <div className="u-position--relative">
                      <label htmlFor="password">Password</label>
                      <input
                        id="password"
                        className={`Input ${
                          state.errors?.password && "!tw-border-pink-400"
                        }`}
                        type={state.passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        ref={passwordRef}
                        value={state.password}
                        name="password"
                        onChange={handleFormChange}
                        role="password"
                      />
                      <span
                        className="see-pass u-color--astral u-fontSize--small u-fontWeight--medium u-textDecoration--underlineOnHover"
                        onClick={() => toggleVisiblePassword("password")}
                      >
                        {state.passwordVisible ? "Hide" : "Show"}
                      </span>
                    </div>
                    <div className="u-position--relative u-marginBottom--normal">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        id="confirmPassword"
                        className={`Input ${
                          state.errors?.password && "!tw-border-pink-400"
                        }`}
                        type={state.confirmPasswordVisible ? "text" : "password"}
                        placeholder="Confirm password"
                        ref={confirmPasswordRef}
                        value={state.confirmPassword}
                        name="confirmPassword"
                        onChange={handleFormChange}
                        role="password"
                      />
                      <span
                        className="see-pass u-color--astral u-fontSize--small u-fontWeight--medium u-textDecoration--underlineOnHover"
                        onClick={() => toggleVisiblePassword("confirmPassword")}
                      >
                        {state.confirmPasswordVisible ? "Hide" : "Show"}
                      </span>
                    </div>
                  </div>
                  {!state.errors && acceptInviteError?.message && (
                    <div className="ErrorBlock !tw-mt-0 tw-text-pink-600 tw-text-base">
                      {acceptInviteError.message}
                    </div>
                  )}
                  <div className="FormButton-wrapper flex">
                    <Button
                      kind="primary-purple"
                      size="large"
                      type="submit"
                      className="flex-auto tw-mt-2.5 tw-mb-5 tw-w-full !tw-text-lg"
                      disabled={acceptInviteLoading}
                    >
                      {acceptInviteLoading ? "Creating" : "Create account"}
                    </Button>
                  </div>
                  <div className="flex-auto u-marginLeft--normal u-fontSize--normal flex-verticalCenter flex-column">
                    <p className="u-color--dustyGray u-textAlign--center">
                      Have an account?{" "}
                      <Link
                        to="/login"
                        className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                      >
                        Log in
                      </Link>
                    </p>
                  </div>
                  <div className="flex-auto u-marginLeft--normal u-fontSize--normal flex-verticalCenter flex-column">
                    <p className="u-color--dustyGray u-textAlign--center u-paddingTop--more">
                      By clicking "Get Started", I agree to Replicated's{" "}
                      <a
                        className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                        target="_blank"
                        href="https://replicated.com/terms"
                        rel="noreferrer"
                      >
                        TOS
                      </a>{" "}
                      and{" "}
                      <a
                        className="u-color--blue u-textDecoration--underlineOnHover u-fontWeight--medium"
                        target="_blank"
                        href="https://replicated.com/privacy"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </form>
              </>
            )}
          </div>
          <div className="auth-footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AcceptInvite);
