import { VendorUtilities } from "../../../utilities/VendorUtilities";
import { SessionManager } from "../../../services/SessionManager";

export async function getAvailableTeams() {
  const response = await fetch(`${SessionManager.getApiEndpoint()}/signup/teams`, {
    method: "GET",
    headers: {
      Authorization: VendorUtilities.getToken(),
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });

  return await response.json();
}
