import { logOutUser } from "@/redux/auth/sessions/actions";
import { VendorUtilities } from "@/utilities/VendorUtilities";
import { VendorClient } from "@/VendorClient";

const getClient = store => {
  return VendorClient(
    import.meta.env.VITE_VENDOR_API_ENDPOINT,
    () => VendorUtilities.getToken(),
    null,
    () => store.dispatch(logOutUser())
  );
};

export default getClient;
