export const SHOW_SAVE_LICENSE_TOAST = "SHOW_SAVE_LICENSE_TOAST";
export const SET_CUSTOM_SEARCH_QUERY = "SET_CUSTOM_SEARCH_QUERY";
export const SAVE_LICENCE_FILTERS = "SAVE_LICENSE_FILTERS";
export const RESET_LICENSE_FILTERS = "RESET_LICENSE_FILTERS";

export function showSavedToast(shouldShow) {
  return {
    type: SHOW_SAVE_LICENSE_TOAST,
    payload: shouldShow
  };
}

export function setCustomSearchQuery(query) {
  return {
    type: SET_CUSTOM_SEARCH_QUERY,
    payload: query
  };
}

export function saveLicenseFilters(filters) {
  return {
    type: SAVE_LICENCE_FILTERS,
    payload: filters
  };
}
