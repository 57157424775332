import type { Action } from "@/types";
import {
  SET_ACTIVE_KOTS_RELEASE_LIST,
  SET_DRAFT_RELEASE,
  SET_KOTS_RELEASE_LIST,
  SET_KOTS_START_INDEX_AND_PAGE_COUNT,
  SET_LATEST_KOTS_RELEASE,
  SET_PROMOTE_ERROR,
  SET_PROMOTE_ERROR_CHANNEL,
  SET_PROMOTE_ERROR_LINT_EXPRESSIONS,
  SET_VIEW_MODE
} from "./actions";

const initialState = {
  kotsReleases: [],
  activeKotsReleases: [],
  approximateTotalCount: 0,
  basicChannelData: {},
  startIndex: 0,
  pageCount: 20,
  latestRelease: {},
  promoteErrorLintExpressions: [],
  promoteErrorChannel: null,
  promoteErrorMessage: "",
  viewMode: "",
  draftRelease: {}
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_KOTS_RELEASE_LIST:
      return Object.assign({}, state, {
        kotsReleases: action.payload.releases,
        approximateTotalCount: action.payload.approximateTotalCount
      });
    case SET_ACTIVE_KOTS_RELEASE_LIST:
      return Object.assign({}, state, {
        activeKotsReleases: action.payload.activeReleases
      });
    case SET_KOTS_START_INDEX_AND_PAGE_COUNT: {
      return Object.assign({}, state, {
        startIndex: action.payload.startIndex,
        pageCount: action.payload.pageCount
      });
    }
    case SET_LATEST_KOTS_RELEASE: {
      return Object.assign({}, state, {
        latestRelease: action.payload.release
      });
    }
    case SET_PROMOTE_ERROR_LINT_EXPRESSIONS: {
      return Object.assign({}, state, {
        promoteErrorLintExpressions: action.payload.lintExpressions
      });
    }
    case SET_PROMOTE_ERROR_CHANNEL: {
      return Object.assign({}, state, {
        promoteErrorChannel: action.payload.channel
      });
    }
    case SET_PROMOTE_ERROR: {
      return Object.assign({}, state, {
        promoteErrorMessage: action.payload
      });
    }
    case SET_DRAFT_RELEASE: {
      return Object.assign({}, state, {
        viewMode: action.payload.viewMode,
        draftRelease: action.payload.draftRelease
      });
    }
    case SET_VIEW_MODE: {
      return Object.assign({}, state, {
        viewMode: action.payload
      });
    }
    default:
      return state;
  }
}
