import { Component } from "react";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import keyBy from "lodash/keyBy";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
  nextRoute: string;
  apps: any[];
  appsLoaded: any;
  kotsApps: any[];
  getAppList: () => void;
  getKotsAppList: () => void;
}

class ReplRedirect extends Component<IProps> {
  state = {
    appList: [],
    apps: null
  };

  perform = () => {
    const { appList, apps } = this.state;
    if (!this.props.nextRoute || appList.length === 0) {
      return;
    }
    const { nextRoute, history } = this.props;
    let appSlug;
    if (apps[appList[0]].channels) {
      appSlug = apps[appList[0]].slug;
    } else {
      appSlug = apps[appList[0]].Slug;
    }
    const to = `/apps/${appSlug}${nextRoute}`;

    history.replace(to);
  };

  mergeApps = () => {
    const { apps, kotsApps } = this.props;

    let allApps = apps;
    const kots = keyBy(kotsApps, "id");
    allApps = merge(allApps, kots);

    const sortedAppKeys = Object.keys(allApps).sort((a, b) => {
      const nameA = allApps[a].Name || allApps[a].name;
      const nameB = allApps[b].Name || allApps[b].name;
      return nameA.localeCompare(nameB);
    });

    this.setState({ appList: sortedAppKeys, apps: allApps });
  };

  checkInitialApps = async () => {
    try {
      await this.props.getAppList();
      await this.props.getKotsAppList();
      if (
        this.props.appsLoaded.all &&
        isEmpty(this.props.apps) &&
        isEmpty(this.props.kotsApps)
      ) {
        this.props.history.replace("/new-application");
      } else {
        this.mergeApps();
      }
    } catch (error) {
      return;
    }
  };

  componentDidMount() {
    this.checkInitialApps();
  }

  componentDidUpdate(lastProps, lastState) {
    if (this.props.appsLoaded.all && !lastProps.appsLoaded.all) {
      this.checkInitialApps();
    }
    if (this.state.appList !== lastState.appList && this.state.appList.length > 0) {
      this.perform();
    }
  }
  render() {
    return null;
  }
}

export default withRouter(ReplRedirect);
