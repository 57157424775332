import { combineReducers } from "redux";
import { apps, appsBySlug, appsLoaded } from "./reducer";
import ReleasesReducer from "./releases/reducer";
import ViewReleaseReducer from "./view_release/reducer";
import ChannelsReducer from "./channels/reducer";
import ChannelReleasesReducer from "./channel_releases/reducer";
import LicensesReducer from "./licenses/reducer";
import LicenseFieldsReducer from "./license_fields/reducer";
import ImagesReducer from "./images/reducer";

export default combineReducers({
  apps,
  appsBySlug,
  appsLoaded,
  releases: ReleasesReducer,
  viewRelease: ViewReleaseReducer,
  channels: ChannelsReducer,
  channelReleases: ChannelReleasesReducer,
  licenses: LicensesReducer,
  licenseFields: LicenseFieldsReducer,
  images: ImagesReducer
});
