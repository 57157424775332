import { Component } from "react";
import { Link } from "react-router-dom";

export default class PopoverItem extends Component {
  render() {
    const { className, label, href, linkTo, onClick } = this.props;

    return (
      <li className={`PopoverItem ${className || ""}`}>
        {onClick ? (
          <span className="PopoverLabel u-noSelect" onClick={onClick}>
            {label}
          </span>
        ) : linkTo ? (
          <Link className="PopoverLabel u-noSelect" to={linkTo}>
            {label}
          </Link>
        ) : (
          <a
            className="PopoverLabel u-noSelect"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {label}
          </a>
        )}
      </li>
    );
  }
}
