import { SET_CHANNEL_HISTORY_LIST, SET_PAGE_SIZE_AND_CURRENT_PAGE } from "./actions";
import sortBy from "lodash/sortBy";
import type { Action } from "@/types";

const initialState = {
  release_histories: {},
  totalHistoryCount: 0,
  pageSize: 20,
  currentPage: 0
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_CHANNEL_HISTORY_LIST: {
      const _channelHistory = Object.assign({}, action.payload.histories);
      const _histories = sortBy(_channelHistory.releases, n => {
        return n.release_sequence * 100 + (n.patch_release_sequence || 0);
      }).reverse();
      const _release_history = {
        [_channelHistory.channel.Id]: _histories
      };
      return Object.assign({}, state, {
        release_histories: _release_history,
        totalHistoryCount: action.payload.total_count
      });
    }
    case SET_PAGE_SIZE_AND_CURRENT_PAGE: {
      return Object.assign({}, state, {
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage
      });
    }
    default:
      return state;
  }
}
