import styled from "styled-components";

export const ParagraphLarge = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 12px;
  color: ${props => props.theme.dustyGray};
`;

export const ParagraphMedium = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.gray600};

  b {
    font-weight: 700;
  }
`;

export const ParagraphSmall = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 12px;
  color: ${props => props.theme.dustyGray};
`;
