import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";

import { VendorUtilities, apiFetchVendorV1 } from "@/utilities/VendorUtilities";
import { SessionManager } from "@/services/SessionManager";
import SwitchInput from "@/components/shared/SwitchInput";
import { SET_SESSION_DATA } from "@/redux/auth/sessions/actions";
import { Button } from "../shared/Button";
import { Icon } from "../shared/Icon";

import "@/scss/components/auth/AuthPage.scss";

const CreateTeam = ({ err, clearServerError, setLoadingToFalse }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [autojoinEnabled, setAutojoinEnabled] = useState(true);
  const [errors, setErrors] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.sessions.sessionData.user);

  const { mutate: registerTeam, isLoading: registerTeamLoading } = useMutation({
    mutationFn: async (payload: any) => {
      const path = "/signup/register";
      return (
        await apiFetchVendorV1(path, {
          endpoint: SessionManager.getApiEndpoint(),
          method: "POST",
          body: JSON.stringify(payload)
        })
      ).json();
    },
    retry: false,
    onError: async (err: any) => {
      setErrors(JSON.parse(err?.message));
    },
    onSuccess: async data => {
      const parsedData = data;
      dispatch({ type: SET_SESSION_DATA, payload: parsedData });
      SessionManager.setSessionId();
      history.push("/new-application");
    }
  });

  const handleFormChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        return;
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (!isEmpty(errors)) {
      setErrors(null);
    }
    if (err) {
      clearServerError();
    }

    const canSubmit = !registerTeamLoading && validatePasswordFields();

    if (canSubmit) {
      registerTeam({
        password: password.trim() || "",
        first_name: firstName.trim() || "",
        last_name: lastName.trim() || "",
        company: company.trim() || "",
        team_autojoin_enabled: user?.has_private_email_domain ? autojoinEnabled : false
      });
    }
  };

  useEffect(() => {
    VendorUtilities.purgeVOneData();
    setLoadingToFalse();
  }, []);

  const validatePasswordFields = () => {
    if (password !== confirmPassword) {
      setErrors({
        ...errors,
        password: "Passwords do not match"
      });
      return false;
    }
    if (password.length > 72) {
      setErrors({
        ...errors,
        password: "Password cannot be longer than 72 characters"
      });
      return false;
    }
    return true;
  };

  const handleAutojoinToggle = async () => {
    setAutojoinEnabled(!autojoinEnabled);
  };

  return (
    <div className="tw-flex tw-flex-col tw-w-full bg-color--teal tw-justify-center tw-items-center">
      <BasicNavBar />
      <div className="tw-py-10 tw-px-4 tw-box-border u-minHeight--full u-width--full u-overflow--auto flex-column flex1 alignItems--center flex-verticalCenter bg-color--teal">
        <div className="u-flexTabletReflow flex-auto u-width--full lg:tw-my-10 CreateApp-wrapper">
          <div className="illustration-wrapper tw-hidden lg:tw-flex lg:tw-flex-1 lg:tw-flex-column lg:tw-justify-center">
            <div className="left-content-wrap flex-column justifyContent--center alignItems--center">
              <span className="icon u-createTeam"></span>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-flex-1 tw-items-center lg:tw-items-start">
            <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-8 right-content-wrap tw-box-border">
              <div>
                <h1 className="tw-text-indigo-500 u-lineHeight--normal">
                  Create your team
                </h1>
                <p className="u-fontSize--large u-fontWeight--normal tw-text-gray-600 u-lineHeight--normal u-marginTop--normal">
                  Before we get started, let's get some information about you and your
                  team.
                </p>
              </div>
              {/* Catchall for all other server errors when registering */}
              {errors && errors?.validateError && (
                <div className="ErrorBlock !tw-m-0 !tw-p-4">
                  {Object.keys(errors).map(key =>
                    key === "validateError" ? null : (
                      <p key={key}>{errors[key].error}</p>
                    )
                  )}
                </div>
              )}
              <div className="CreateAppPlatform--formWrapper">
                <div className="auth-pages tw-flex tw-flex-col tw-gap-4">
                  <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                    <div>
                      {!errors?.validateError && errors?.firstName && (
                        <div className="ErrorBlock !tw-m-0 !tw-p-4">
                          <p>{errors?.firstName}</p>
                        </div>
                      )}
                      <label htmlFor="firstName">First Name</label>
                      <input
                        className="Input"
                        type="text"
                        placeholder="Jack"
                        size={20}
                        value={firstName}
                        name="firstName"
                        onChange={handleFormChange}
                        aria-label="First Name"
                      />
                    </div>
                    <div>
                      {!errors?.validateError && errors?.lastName && (
                        <div className="ErrorBlock !tw-m-0 !tw-p-4">
                          <p>{errors?.lastName}</p>
                        </div>
                      )}
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        className="Input"
                        type="text"
                        placeholder="Shephard"
                        size={20}
                        value={lastName}
                        name="lastName"
                        onChange={handleFormChange}
                        aria-label="Last Name"
                      />
                    </div>
                  </div>
                  <div>
                    {!errors?.validateError && errors?.company && (
                      <div className="ErrorBlock !tw-m-0 !tw-p-4">
                        <p>{errors?.company}</p>
                      </div>
                    )}
                    <label htmlFor="company">Company</label>
                    <input
                      className={`Input`}
                      type="text"
                      placeholder="Company Name"
                      value={company}
                      name="company"
                      onChange={handleFormChange}
                      aria-label="Company Name"
                    />
                  </div>
                  {!errors?.validateError && errors?.password && (
                    <div className="ErrorBlock !tw-m-0 !tw-p-4">
                      <p>{errors?.password}</p>
                    </div>
                  )}
                  <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                    <div>
                      <label htmlFor="password">Password</label>
                      <input
                        className="Input"
                        type="password"
                        placeholder="Password"
                        size={20}
                        value={password}
                        name="password"
                        onChange={handleFormChange}
                        aria-label="Password"
                        role="password"
                      />
                    </div>
                    <div>
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        className="Input"
                        type="password"
                        placeholder="Confirm Password"
                        size={20}
                        value={confirmPassword}
                        name="confirmPassword"
                        onChange={handleFormChange}
                        aria-label="Confirm Password"
                        role="password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {user?.has_private_email_domain && (
              <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-2 right-content-wrap tw-box-border">
                <SwitchInput
                  name="create-team-autojoin"
                  label="Enable Team Auto-join"
                  checked={autojoinEnabled}
                  onChange={handleAutojoinToggle}
                />
                <p className="tw-text-gray-600 tw-text-sm">
                  When team auto-join is enabled, anyone with a validated{" "}
                  <span className="tw-font-semibold">{user?.domain}</span> email will be
                  able to join this team. The default policy assigned is{" "}
                  <span className="tw-font-semibold">Read Only</span> - you can change
                  the default on the{" "}
                  <Link
                    target="_blank"
                    to="/team/autojoin"
                    className="tw-text-blue-300 hover:tw-underline tw-font-semibold"
                  >
                    Auto-join settings page
                  </Link>{" "}
                  after you create your team.
                </p>
              </div>
            )}
            <div className="flex-column right-content-wrap tw-box-border">
              <div className="FormButton-wrapper tw-flex tw-mt-8">
                <Button kind="primary-purple" size="large" onClick={onSubmit}>
                  {registerTeamLoading ? "Loading..." : "Continue"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Footer = () => (
  <footer className="FooterContent-wrapper tw-flex tw-items-end">
    <div className={"container"}>
      <div className="tw-flex tw-justify-center tw-items-center">
        <div className="FooterItem-wrapper">
          <Link to="/privacy" target="_blank" className="FooterItem">
            Privacy policy
          </Link>
        </div>
        <div className="FooterItem-wrapper">
          <Link to="/terms" target="_blank" className="FooterItem">
            Terms of service
          </Link>
        </div>
        <div className="FooterItem-wrapper">
          <span className="FooterItem">{SessionManager.getBuildVersion()}</span>
        </div>
      </div>
    </div>
  </footer>
);

const BasicNavBar = () => (
  <header className="tw-w-full flex-column">
    <div className="NavBarWrapper tw-px-5 tw-py-3 tw-box-border flex flex-1 justifyContent--flexStart alignItems--center">
      <div className="HeaderLogo-wrapper u-position--relative">
        <Link to="/" className="HeaderLogo">
          <Icon name="replicatedLogo" width="150" height="34" />
        </Link>
      </div>
    </div>
  </header>
);

export default CreateTeam;
