import styled from "styled-components";

export const Header1 = styled.h1`
  line-height: 1.4;
  color: ${props => props.theme.title};
`;

export const Header3 = styled.h3`
  font-family: ${props => props.theme.tertiaryFont};
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 12px 0;
`;

export const Header4 = styled.h4`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 4px;
  color: ${props => props.theme.allCapsHeader};
`;

export const Header5 = styled.h5`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
`;
