import { Component } from "react";
import { withRouter } from "react-router-dom";
import autoBind from "react-autobind";
import { VendorUtilities } from "../../utilities/VendorUtilities";

import Footer from "../shared/Footer";

import "../../scss/components/auth/AuthPage.scss";
import { Button } from "../shared/Button";

class AccountLocked extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      emailError: false,
      emailMessage: ""
    };
    autoBind(this);
  }

  clearErrors = () => {
    this.setState({
      emailError: false,
      emailMessage: ""
    });
  };

  handleFormChange = (field, e) => {
    if (this.state.emailError) {
      this.clearErrors();
    }
    if (this.props.err) {
      this.props.clearServerError();
    }
    let nextState = {};
    nextState[field] = e.target.value;
    this.setState(nextState);
  };

  buildPayload = () => {
    return {
      email: this.state.email.trim()
    };
  };

  validateFields = () => {
    if (!VendorUtilities.isEmailValid(this.state.email)) {
      this.setState({
        emailError: true,
        emailMessage: "Please use a valid email address"
      });
      return false;
    }
    return true;
  };

  onSubmit = e => {
    e.preventDefault();
    this.clearErrors();
    const payload = this.buildPayload();
    const canSubmit = this.validateFields();
    if (canSubmit) {
      this.props.sendAccountUnlockEmail(payload);
    }
  };

  componentDidMount() {
    this.props.clearServerError();
    const lockAccountParams = new URLSearchParams(this.props.location.search);
    const emailParam = lockAccountParams.get("email");
    if (emailParam) {
      this.setState({ email: emailParam });
    }
  }

  render() {
    const { dataLoading } = this.props;

    return (
      <div className="u-width--full u-overflow--auto flex-column">
        <div className="u-flex--mobileoff flex-1-auto u-width--full">
          <div className="AuthIllustration-wrapper">
            <div className="ReplicatedLogoShadow logo-standalone"></div>
          </div>

          <div className="Login-wrapper flex1 flex-column flex-verticalCenter u-paddingBottom--normal alignItems--center auth-pages">
            {this.props.accountUnlockStep === "success" ? (
              <div className="Form-wrapper">
                <p className="u-fontSize--giant u-fontWeight--light u-color--tuna u-lineHeight--normal">
                  Account locked
                </p>
                <p className="u-fontSize--large u-fontWeight--normal u-color--dustyGray u-lineHeight--normal u-marginTop--normal">
                  The unlock email has been sent. Please check your email for the next
                  steps.
                </p>
                <div className="flex u-marginTop--more">
                  <div className="FormButton-wrapper flex">
                    <Button
                      kind="secondary-gray"
                      className="tw-mr-3 flex-auto tw-mt-2.5 tw-mb-5"
                      onClick={() => this.props.setAccountUnlockStep("info")}
                    >
                      Resend email
                    </Button>
                  </div>
                  <div className="FormButton-wrapper flex">
                    <Button
                      kind="primary-purple"
                      className="flex-auto tw-mt-2.5 tw-mb-5"
                      onClick={() => this.props.history.push("/login")}
                    >
                      Back to login
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="Form-wrapper">
                <p className="form-title u-color--blue u-lineHeight--normal">
                  Account locked
                </p>
                <p className="form-subhead u-fontSize--large u-fontWeight--normal u-color--dustyGray u-lineHeight--normal u-marginTop--normal">
                  Your account has been locked because of too many invalid login
                  attempts. We've done this as a precaution to prevent unauthorized
                  access to your account.
                </p>
                <p className="form-subhead u-fontSize--large u-fontWeight--normal u-color--dustyGray u-lineHeight--normal u-marginTop--normal">
                  To unlock your account, confirm your email address below and click the
                  Send button. You'll receive an email with a link to click to verify
                  you have access to the email address associated with this account.
                </p>
                <div>
                  <form
                    onSubmit={e => {
                      this.onSubmit(e);
                    }}
                  >
                    <div className="FormSection-wrapper">
                      {this.state.emailError ? (
                        <div className="ErrorBlock u-marginBottom--small">
                          <p>{this.state.emailMessage}</p>
                        </div>
                      ) : null}
                      {this.props.err ? (
                        <div className="ErrorBlock u-marginBottom--small">
                          <p>{this.props.errMessage}</p>
                        </div>
                      ) : null}
                      <div className="u-marginBottom--normal">
                        <input
                          className="Input"
                          type="text"
                          placeholder="Email address"
                          value={this.state.email}
                          onChange={e => {
                            this.handleFormChange("email", e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="FormButton-wrapper flex">
                      <Button
                        kind="primary-purple"
                        size="small"
                        type="submit"
                        className="flex-auto tw-mt-2.5 tw-mb-5 tw-w-full"
                        disabled={
                          dataLoading.accountUnlockEmailLoading ||
                          this.state.email === ""
                        }
                      >
                        {dataLoading.accountUnlockEmailLoading
                          ? "Sending"
                          : "Send validation email"}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className="auth-footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountLocked);
