import { datadogRum } from "@datadog/browser-rum";
import process from "process/browser";
import { createRoot } from "react-dom/client";

import Root from "./Root";
import { configStore } from "./redux/AppStore";

if (["staging", "production"].includes(import.meta.env.MODE)) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "vendor-portal",
    env: import.meta.env.MODE,
    version: import.meta.env.VITE_DATADOG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [/^https:\/\/([\w-]+\.)+replicated\.com\//],
    defaultPrivacyLevel: "mask-user-input"
  });
  datadogRum.startSessionReplayRecording();
}

// do not remove, breaks anything using replicated-lint
window.process = process;
const root = createRoot(document.getElementById("app"));

configStore().then(() => {
  root.render(<Root />);
});
