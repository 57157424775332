import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  flip,
  offset,
  safePolygon,
  shift,
  useFloating,
  useHover,
  useInteractions
} from "@floating-ui/react";
import cx from "classnames";
import { MouseEventHandler, useRef, useState } from "react";

import * as icons from "./Icons";
import TooltipContent from "./TooltipContent";

const getIconSize = iconSize => {
  switch (iconSize) {
    case "x-small":
      return "12px";
    case "small":
      return "16px";
    case "normal":
      return "20px";
    case "large":
      return "24px";
    case "x-large":
      return "32px";
    case "xx-large":
      return "44px";
  }
};

interface IconProps {
  name: string;
  className?: string;
  size?: "x-small" | "small" | "normal" | "large" | "x-large" | "xx-large";
  color?: string;
  hoverColor?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  orientation?: "horizontal" | "vertical";
  width?: string;
  height?: string;
}

export const Icon = ({
  name,
  className,
  size = "normal",
  onClick,
  orientation = "vertical",
  color,
  hoverColor,
  width,
  height
}: IconProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const IconSvg = icons[name];
  if (!IconSvg) {
    console.warn(`Icon ${name} does not exist`);
    return null;
  }
  const widthValue =
    width || (orientation === "vertical" ? getIconSize(size) : undefined);
  const heightValue =
    height || (orientation === "horizontal" ? getIconSize(size) : undefined);
  return (
    <div className={className} data-testid={name} onClick={onClick}>
      <IconSvg
        className="tw-block tw-overflow-visible"
        width={widthValue}
        height={heightValue}
        size={width}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          ...(color ? { color } : {}),
          ...(isHovered && hoverColor ? { color: hoverColor } : {}),
          width: widthValue,
          height: heightValue || widthValue
        }}
      />
    </div>
  );
};

interface IconWithTooltipProps extends IconProps {
  disabled?: boolean;
  tooltipContent: string;
  tooltipWidth?: string | number;
  hoverDelayMs?: number;
}

export const IconWithTooltip = ({
  name,
  className,
  size = "normal",
  onClick,
  disabled = false,
  orientation = "vertical",
  color,
  hoverColor,
  width,
  height,
  tooltipContent: tooltipMarkdown,
  tooltipWidth,
  hoverDelayMs
}: IconWithTooltipProps) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);
  const { x, y, refs, strategy, context } = useFloating({
    placement: "top",
    open,
    middleware: [
      offset({ mainAxis: 8, crossAxis: 4 }),
      flip({
        crossAxis: false,
        padding: {
          top: 65
        }
      }),
      shift({
        padding: {
          top: 65
        }
      }),
      arrow({ element: arrowRef })
    ],
    onOpenChange: setOpen
  });
  const hover = useHover(context, {
    restMs: hoverDelayMs ?? 0,
    handleClose: safePolygon({ blockPointerEvents: false })
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <Icon
          name={name}
          className={cx(
            disabled ? "tw-cursor-not-allowed" : "tw-cursor-pointer",
            className
          )}
          size={size}
          onClick={onClick}
          orientation={orientation}
          color={disabled ? "#C4C4C4" : color}
          hoverColor={!disabled && hoverColor}
          width={width}
          height={height}
        />
      </div>
      <FloatingPortal>
        {open && (
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0
            }}
            className={cx(
              "tw-flex tw-flex-col tw-gap-2 tw-z-50",
              "tw-p-2 tw-bg-white tw-shadow-lg",
              "tw-border tw-border-solid tw-border-[#9B9B9B] tw-rounded"
            )}
            {...getFloatingProps()}
          >
            <TooltipContent
              className={cx(
                tooltipWidth
                  ? `tw-max-w-[${tooltipWidth}px]`
                  : "tw-w-fit tw-max-w-[300px]"
              )}
              markdown={tooltipMarkdown}
            />
            <FloatingArrow
              height={4}
              width={8}
              context={context}
              ref={arrowRef}
              fill="white"
              stroke="#9b9b9b"
              strokeWidth={1}
            />
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
