export const DISPLAY_MODAL = "DISPLAY_MODAL";
export const LOADING_DATA = "LOADING_DATA";
export const FORBIDDEN_DATA = "FORBIDDEN_DATA";
export const ERROR_DATA = "ERROR_DATA";

export function displayModal(key, display) {
  return {
    type: DISPLAY_MODAL,
    payload: {
      key,
      display
    }
  };
}

export function loadingData(key, isLoading) {
  return {
    type: LOADING_DATA,
    payload: {
      key,
      isLoading
    }
  };
}

export function forbiddenData(key, isForbidden) {
  return {
    type: FORBIDDEN_DATA,
    payload: {
      key,
      isForbidden
    }
  };
}

export function errorData(key, isError) {
  return {
    type: ERROR_DATA,
    payload: {
      key,
      isError
    }
  };
}
