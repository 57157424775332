import { Component } from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class MobileNavBar extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func
  };
  static defaultProps = {
    user: {}
  };

  render() {
    const { isOpen, items, onClose } = this.props;

    const mobileDropdownVisibleClasses = `MobileNav flex ${isOpen ? "is-open" : ""}`;
    const bottomItems = items
      .filter(item => item.dropdownContent)
      .reduce((accum, item) => {
        if (Array.isArray(item.dropdownContent)) {
          item.dropdownContent.forEach(dItem => {
            if (dItem) {
              accum.push(dItem);
            }
          });
        }
        return accum;
      }, []);
    return (
      <nav className={mobileDropdownVisibleClasses}>
        <div className="MobileNav-menu flex-column flex1 u-overflow--hidden">
          <div className="u-overflow--auto flex-1-auto" style={{ paddingTop: "50px" }}>
            <ul className="MobileNav-items">
              {items.map((item, i) => (
                <li
                  className={`MobileNav-item ${item.isActive ? "is-active" : ""}`}
                  key={`item-${i}`}
                  onClick={onClose}
                >
                  {item.linkTo ? (
                    <Link className="mobile-label" to={item.linkTo}>
                      {item.label}
                    </Link>
                  ) : item.href ? (
                    <a
                      className="mobile-label"
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <a className="mobile-label" onClick={item.onClick}>
                      {item.label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-column flex1 BottomNavWrapper justifyContent--flexEnd">
            <ul className="MobileNav-items">
              {bottomItems.map((item, i) => {
                return (
                  <li
                    className={`MobileNav-item bottom-nav-item ${
                      item.isActive ? "is-active" : ""
                    } ${item.className || ""}`}
                    key={`bottom-item-${i}`}
                  >
                    <a href={item.href} onClick={item.onClick}>
                      {item.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="MobileNav-bg flex1" onClick={onClose} />
      </nav>
    );
  }
}
