import cx from "classnames";
import ReactMarkdown from "react-markdown";
import * as R from "remeda";

interface DescriptionProps {
  text: string;
  docsUrl?: string;
  className?: string;
}

const DescriptionText = ({ text }) => (
  <ReactMarkdown
    allowedElements={["a", "p", "strong", "em"]}
    components={{
      p: "span",
      a: ({ ...props }) => (
        <a
          className="tw-text-blue-300 tw-font-semibold hover:tw-underline"
          {...R.omit(props, ["node"])}
        />
      )
    }}
    linkTarget="_blank"
    skipHtml
  >
    {text}
  </ReactMarkdown>
);

const DocsLink = ({ docsUrl }) => (
  <span style={{ whiteSpace: "nowrap" }}>
    <a
      href={docsUrl}
      target="_blank"
      className="tw-text-blue-300 tw-font-semibold hover:tw-underline"
    >
      Learn more
    </a>
  </span>
);

const Description = ({ text, docsUrl, className }: DescriptionProps) => (
  <p
    className={cx(
      "u-marginTop--normal tw-text-gray-600 u-fontSize--normal u-lineHeight--normal",
      className
    )}
    style={{ paddingLeft: "1px", maxWidth: "1000px" }}
  >
    <DescriptionText text={text} />
    {docsUrl && (
      <>
        {" "}
        <DocsLink docsUrl={docsUrl} />
      </>
    )}
  </p>
);

export default Description;
