import "isomorphic-fetch";
import { loadingData, displayModal } from "../../ui/main/actions";
import { logOutUser } from "../../auth/sessions/actions";
import { SessionManager } from "../../../services/SessionManager";

export const SET_KOTS_CUSTOMERS_LIST = "SET_KOTS_CUSTOMERS_LIST";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const CLEAR_SERVER_ERROR = "CLEAR_SERVER_ERROR";
export const KOTS_SET_PASSWORD_CONFIRM_DIALOG = "KOTS_SET_PASSWORD_CONFIRM_DIALOG";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_CUSTOMERS_LAST_UPDATED = "SET_CUSTOMERS_LAST_UPDATED";

function setServerErr(err: any) {
  return {
    type: SET_SERVER_ERROR,
    payload: err
  };
}

export function clearServerErr() {
  return {
    type: CLEAR_SERVER_ERROR
  };
}

export function setPasswordConfirmDialog(display: boolean, password: string) {
  return {
    type: KOTS_SET_PASSWORD_CONFIRM_DIALOG,
    payload: {
      display,
      password
    }
  };
}

function setCustomer(customer: any) {
  return {
    type: SET_CUSTOMER,
    payload: customer
  };
}

export function createKotsCustomer(payload, callback) {
  return async (dispatch, getState) => {
    dispatch(loadingData("createKotsCustomer", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("3")}/customer`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        dispatch(loadingData("createKotsCustomer", false));
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        const err = await response.json();
        if (response.status === 400) {
          dispatch(
            setServerErr({
              errorMsg: err.message
                ? err.message
                : "Error while creating customer, please try again!"
            })
          );
          return;
        }
        dispatch(
          setServerErr({
            errorMsg: err.message
              ? err.message
              : "Error while creating customer, please try again!"
          })
        );
        return;
      }
      const body = await response.json();
      callback(body.customer.id);
      dispatch(loadingData("createKotsCustomer", false));
    } catch (error) {
      dispatch(loadingData("createKotsCustomer", false));
      dispatch(
        setServerErr({ errorMsg: "Error while creating customer, please try again!" })
      );
      console.log(error);
      return;
    }
  };
}

export function updateKotsCustomer(appId, customerId, payload) {
  return async (dispatch, getState) => {
    dispatch(loadingData("updateKotsCustomer", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("3")}/customer/${customerId}`;

      response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        dispatch(loadingData("updateKotsCustomer", false));
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        const err = await response.json();
        if (response.status === 400) {
          dispatch(
            setServerErr({
              errorMsg: err.message
                ? err.message
                : "Error while creating customer, please try again!"
            })
          );
          return;
        }
        dispatch(
          setServerErr({
            errorMsg: err.message
              ? err.message
              : "Error while creating customer, please try again!"
          })
        );
        return;
      }
      dispatch(loadingData("updateKotsCustomer", false));
      dispatch(getCustomer(appId, customerId));
    } catch (error) {
      dispatch(loadingData("updateKotsCustomer", false));
      dispatch(
        setServerErr({ errorMsg: "Error while updating customer, please try again!" })
      );
      console.log(error);
      return;
    }
  };
}

export function unarchiveKotsCustomer(appId, customerId) {
  return async (dispatch, getState) => {
    if (!customerId) {
      return;
    }
    dispatch(loadingData("unarchiveKotsCustomer", true));
    try {
      const url = `${SessionManager.getApiEndpoint(
        "3"
      )}/customer/${customerId}/unarchive`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        dispatch(loadingData("unarchiveKotsCustomer", false));
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        if (response.status === 400) {
          dispatch(
            setServerErr({
              errorMsg: "Error while unarchiving customer, please try again!"
            })
          );
          return;
        }
        dispatch(
          setServerErr({
            errorMsg: "Error while unarchiving customer, please try again!"
          })
        );
        return;
      }
      dispatch(loadingData("unarchiveKotsCustomer", false));
      dispatch(getCustomer(appId, customerId));
    } catch (error) {
      dispatch(loadingData("unarchiveKotsCustomer", false));
      dispatch(
        setServerErr({
          errorMsg: "Error while unarchiving customer, please try again!"
        })
      );
      return;
    }
  };
}

export function getCustomer(appId, customerId) {
  return async (dispatch, getState) => {
    if (!appId || !customerId) {
      return;
    }
    dispatch(loadingData("kotsCustomer", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint(
        "3"
      )}/app/${appId}/customer/${customerId}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setCustomer(body));
      dispatch(loadingData("kotsCustomer", false));
    } catch (error) {
      dispatch(loadingData("kotsCustomer", false));
      console.log(error);
      return;
    }
  };
}
