import styled from "styled-components";

export const TextLink = styled.span`
  color: ${props => props.theme.astral};
  font-weight: 500;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ReadMoreLink = styled.a`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  vertical-align: middle;
  color: #097992;
`;

export const BaseButton = styled.button`
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Open Sans", sans-serif;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background: #097992;
  border: 1px solid #097992;
  color: #ffffff;
`;

export const SecondaryButton = styled(BaseButton)`
  background: white;
  border: 1px solid #097992;
  color: #097992;
`;

export const LightButton = styled(BaseButton)`
  background-color: white;
  border: 1px solid #959595;
  color: #959595;
`;
