import cx from "classnames";
import { Link } from "react-router-dom";

import useLegacyBreakpoint from "@/hooks/use-legacy-breakpoint";
import { SessionManager } from "@/services/SessionManager";

import "@/scss/components/main/Footer.scss";

const Footer = ({ isSidebar = false }) => {
  const breakpoint = useLegacyBreakpoint();
  const isMobile = breakpoint === "mobile";
  const showFullFooter = !isMobile && !isSidebar;
  return (
    <div className="FooterContent-wrapper">
      <div className={cx(!isSidebar && "container")}>
        <div className="tw-flex tw-justify-center tw-items-center">
          <div className="FooterItem-wrapper">
            <Link to="/privacy" target="_blank" className="FooterItem">
              Privacy {showFullFooter && "policy"}
            </Link>
          </div>
          <div className="FooterItem-wrapper">
            <Link to="/terms" target="_blank" className="FooterItem">
              Terms {showFullFooter && "of service"}
            </Link>
          </div>
          <div className="FooterItem-wrapper">
            <a
              href="https://docs.replicated.com/release-notes/rn-vendor-platform"
              className="FooterItem"
            >
              {SessionManager.getBuildVersion()}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
