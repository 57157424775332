// @ts-strict
import { createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { setIsTroubleshootOpenFromNavbar as setIsTroubleshootOpenFromNavbarAction } from "@/redux/troubleshoot/actions";
import { displayModal as displayModalAction } from "@/redux/ui/main/actions";
import { DataLoading, User } from "@/types";

interface AppStateContextValue {
  currentAppId: string;
  accessToken: string;
  modals: Record<string, boolean>;
  dataLoading: DataLoading;
  displayModal: (key: string, display: boolean) => void;
  isTroubleshootOpenedFromNavbar: boolean;
  setIsTroubleshootOpenedFromNavbar: () => void;
  user: User;
  isAuthenticated: boolean;
}

const AppStateContext = createContext<AppStateContextValue>({} as AppStateContextValue);

export const AppStateProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const location = useLocation();

  const user = useSelector((state: any) => state.auth.sessions.sessionData.user);
  const currentAppId = useSelector((state: any) => state.selectedApp);
  const accessToken = useSelector(
    (state: any) => state.auth.sessions.sessionData.accessToken
  );
  const isAuthenticated = Boolean(
    user?.id && accessToken && location.pathname !== "/saml"
  );
  const isTroubleshootOpenedFromNavbar = useSelector(
    (state: any) => state.troubleshoot.isTroubleshootOpenedFromNavbar
  );
  const modals = useSelector((state: any) => state.ui.main.modals);
  const dataLoading = useSelector((state: any) => state.ui.main.loading);

  const displayModal = (key: string, display: boolean) => {
    return dispatch(displayModalAction(key, display));
  };

  const setIsTroubleshootOpenedFromNavbar = () => {
    return dispatch(setIsTroubleshootOpenFromNavbarAction());
  };

  const contextValue = {
    currentAppId,
    displayModal,
    modals,
    dataLoading,
    accessToken,
    isTroubleshootOpenedFromNavbar,
    setIsTroubleshootOpenedFromNavbar,
    user,
    isAuthenticated
  };

  return (
    <AppStateContext.Provider value={contextValue}>{children}</AppStateContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(AppStateContext);
};
