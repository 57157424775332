import { Component } from "react";

export default class TermsOfService extends Component {
  render() {
    return (
      <div className="u-minHeight--full u-width--full u-overflow--auto container">
        <div className="PolicyPage u-paddingTop--most">
          <h3>Terms of Service</h3>

          <h4>1. SCOPE</h4>

          <p>
            These Terms and Conditions shall apply to Customer's use of Services of the
            Replicated software. This agreement will be superseded by any signed
            contract between Replicated and Customer. This Agreement represents the
            parties' entire understanding regarding the Services and shall control over
            any different or additional terms of any purchase order or other
            non-Replicated ordering document, and no terms included in any such purchase
            order or other non-Replicated ordering document shall apply to the Services.
          </p>

          <h4>2. SAAS SERVICES</h4>

          <p>
            2.1 As part of the registration process, Customer will identify an
            administrative user name and password for Customer’s Replicated account.
            Replicated reserves the right to refuse registration of, or cancel passwords
            it deems inappropriate.
          </p>

          <h4>3. RESTRICTIONS AND RESPONSIBILITIES</h4>

          <p>
            3.1 Customer will not, directly or indirectly: reverse engineer, decompile,
            disassemble or otherwise attempt to discover the source code, object code or
            underlying structure, ideas, know-how or algorithms of the SaaS Services or
            any Distributed Software or other software, documentation or data related to
            the Services (collectively “Software”); modify, translate, or create
            derivative works based on the Services or any Software (except to the extent
            expressly permitted by Replicated or authorized within the Services); use
            the Services or any Software for timesharing or service bureau purposes or
            otherwise outside of the rights expressly granted to Customer; or otherwise
            for the benefit of a third party (including by assisting a third party in
            building or supporting, products or services competitive to Replicated); use
            the Services to deliver any application other than the Customer Application
            without Replicated’s express written consent; remove any proprietary notices
            or labels.
          </p>

          <p>
            3.2 Customer shall not make commercial use of Replicated service without
            agreeing to service and payment terms defined in the Replicated Order Form.
            To the extent that Customer provides its Clients with access to the Customer
            Application in connection with the Services, Customer will enter into a
            written agreement with the applicable Client (a “Client Agreement”) which
            (i) prohibits Client from directly or indirectly taking any of the actions
            enumerated under Section 3.1 hereof, (ii) prohibits title to the Services or
            any portion thereof from passing to Client or any person claiming through
            Client, (iii) disclaims any liability of Replicated to Client for damages,
            whether direct or indirect, incidental or consequential or for loss of
            profits, arising in connection with the Client Agreement, and (iv) state
            that Replicated disclaims any warranty of any kind directly to the Client
            with respect to the Services, including any warranty of performance, title,
            merchantability, fitness for a particular purpose, and non-infringement. In
            such Client Agreement, the references to Replicated may be made by
            referencing “[Customer]’s licensor(s).”
          </p>

          <p>
            3.3 Customer represents, covenants, and warrants that Customer will use the
            Services only in compliance with all applicable laws and regulations.
            Without limiting the foregoing, Customer may not remove or export from the
            United States or allow the export or re-export of the Services, Software or
            anything related thereto, or any direct product thereof in violation of any
            restrictions, laws or regulations of the United States Department of
            Commerce, the United States Department of Treasury Office of Foreign Assets
            Control, or any other United States or foreign agency or authority. As
            defined in FAR section 2.101, the Software and documentation are “commercial
            items” and according to DFAR section 252.227 7014(a)(1) and (5) are deemed
            to be “commercial computer software” and “commercial computer software
            documentation.” Consistent with DFAR section 227.7202 and FAR section
            12.212, any use modification, reproduction, release, performance, display,
            or disclosure of such commercial software or commercial software
            documentation by the U.S. Government will be governed solely by the terms of
            this Agreement and will be prohibited except to the extent expressly
            permitted by the terms of this Agreement.
          </p>

          <p>
            3.4 Customer shall not knowingly or willfully use the Services in any manner
            that could damage, disable, overburden, impair or otherwise interfere with
            Replicated's provision of the Services.
          </p>

          <p>
            3.5 From time to time, Replicated may provide updates to the Distributed
            Software (“Updates”). Customer and Clients shall promptly, and in no event
            later than twenty (20) business days from the availability of any Update,
            install such Update and cease to use any former versions of the Distributed
            Software. Failure to do so may result in loss of support and product
            functionality.
          </p>

          <p>
            3.6 Although Replicated has no obligation to monitor Customer’s use of the
            Services, Replicated may do so, and Replicated may immediately suspend
            Customer's password, account, and access to the Services if (i) Customer
            fails to make payment due within ten business days after Replicated has
            provided Customer with notice of such failure; or (ii) Customer violates
            Section 3, 4, or 10 of these Terms and Conditions. Any suspension by
            Replicated of the Services under the preceding sentence shall not relieve
            Customer of its payment obligations under this Agreement.
          </p>

          <p>
            3.7 Customer shall be responsible for obtaining and maintaining any
            equipment and ancillary services needed to connect to, access or otherwise
            use the Services, including, without limitation, modems, hardware, servers,
            software, operating systems, networking, web servers and the like
            (collectively, “Equipment”). Customer shall also be responsible for
            maintaining the security of the Equipment, Customer account, passwords
            (including but not limited to administrative and user passwords) and files,
            and for all uses of Customer account or the Equipment with or without
            Customer’s knowledge or consent.
          </p>

          <h4>4. CONFIDENTIALITY; PROPRIETARY RIGHTS</h4>

          <p>
            4.1 Each party (the “Receiving Party”) understands that the other party (the
            “Disclosing Party”) has disclosed or may disclose business, technical or
            financial information relating to the Disclosing Party’s business
            (hereinafter referred to as “Proprietary Information” of the Disclosing
            Party). Proprietary Information of Replicated includes non-public
            information regarding features, functionality and performance of the
            Services, and shall include the Software (notwithstanding any term of this
            Agreement to the contrary). Proprietary Information of Customer includes
            non-public data provided by or on behalf of Customer to Replicated to enable
            the provision of the Services (“Customer Data”). The Receiving Party agrees:
            (i) to take reasonable precautions to protect such Proprietary Information,
            and (ii) not to use (except in performance of the Services or as otherwise
            permitted herein) or divulge to any third person any such Proprietary
            Information. The Receiving Party’s obligations hereunder will last for five
            years following disclosure by the Disclosing Party. Notwithstanding the
            foregoing, the Receiving Party will have no obligations with respect to
            Proprietary Information that the Receiving Party can document (a) is or
            becomes generally available to the public, or (b) was in its possession or
            known by it prior to receipt from the Disclosing Party, or (c) was
            rightfully disclosed to it without restriction by a third party, or (d) was
            independently developed without use of any Proprietary Information of the
            Disclosing Party or (e) is required to be disclosed by law.
          </p>

          <p>
            4.2 Customer shall own all right, title and interest in and to the Customer
            Data. Replicated shall own and retain all right, title and interest in and
            to (a) the Services and Software, all improvements, enhancements or
            modifications thereto, (b) any software, applications, inventions or other
            technology developed in connection with Implementation Services or support,
            and (c) all intellectual property rights related to any of the foregoing
            listed in (a) – (c).
          </p>

          <p>
            4.3 Notwithstanding anything to the contrary, Replicated shall have the
            right collect and analyze data and other information relating to the
            provision, use and performance of various aspects of the Services and
            related systems and technologies (including, without limitation, information
            concerning Customer Data, whether relating to Customer or its Clients, and
            any data derived therefrom), and Replicated will be free (during and after
            the term hereof) to (i) use such information and data to improve and enhance
            the Services and for other development, diagnostic and corrective purposes
            in connection with the Services and other Replicated offerings, and (ii)
            disclose such data solely in aggregate or other de-identified form in
            connection with its business; provided, however, that the foregoing
            expressly excludes any and all information of Clients (including, without
            limitation, Customer Data of Customer’s Clients). No rights or licenses are
            granted except as expressly set forth herein.
          </p>

          <h4>5. TERM AND TERMINATION</h4>

          <p>
            5.1 Replicated shall have the right to terminate this agreement at any time
            without notice.
          </p>

          <p>
            5.2 Upon termination of this Agreement, Customer shall promptly cease to use
            any Distributed Software, return all copies of any Distributed Software in
            its possession, and delete any Distributed Software installed on servers or
            machines under Customer’s control, and shall promptly cause Clients to take
            the foregoing actions unless Customer and Company enter into a written
            agreement providing for continued use of any distributed Software by such
            Clients.
          </p>

          <h4>6. WARRANTY AND DISCLAIMER</h4>

          <p>
            Customer represents and warrants to Replicated that: (a) it is duly
            organized and validly existing under the laws of the state of its
            incorporation and has full corporate power and authority, and is duly
            authorized, to enter into the Agreement and to carry out the provisions
            thereof; (b) the person executing the Agreement on such party’s behalf is
            authorized to do so; (c) the execution, delivery and performance of the
            Agreement does not violate any agreement, instrument or understanding, oral
            or written, to which it is a party or by which it may be bound, nor violate
            any law or regulation of any court, governmental body or administrative or
            other agency having jurisdiction over it; and (d) will comply with all
            applicable federal, state and local laws, rules and regulations in
            fulfilling its obligations hereunder, including, without limitation, privacy
            and data security statutes and regulations promulgated and in effect under
            such statutes and all applicable privacy and data security standards and
            generally accepted industry standards.
          </p>

          <h4>7. LIMITATION OF LIABILITY</h4>

          <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, REPLICATED AND ITS SUPPLIERS
            (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS),
            OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT
            BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS
            AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT,
            NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION
            OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF
            PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS;
            (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
            DAMAGES; (C) FOR ANY MATTER BEYOND REPLICATED’S REASONABLE CONTROL; OR (D)
            FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER
            CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER
            THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE
            LIABILITY, IN EACH CASE, WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </p>

          <h4>8. MISCELLANEOUS</h4>

          <p>
            If any provision of this Agreement is found to be unenforceable or invalid,
            that provision will be limited or eliminated to the minimum extent necessary
            so that this Agreement will otherwise remain in full force and effect and
            enforceable. This Agreement is not assignable, transferable or sublicensable
            by Customer except with Replicated’s prior written consent; provided that
            Customer may assign this Agreement in connection with a merger,
            reorganization, or sale of all or substantially all of Customer’s assets.
            Replicated may not transfer and assign any of its rights and obligations
            under this Agreement without Customer’s consent; provided that Replicated
            may assign this Agreement in connection with a merger, reorganization, or
            sale of all or substantially all of Customer’s assets. This Agreement is the
            complete and exclusive statement of the mutual understanding of the parties
            and supersedes and cancels all previous written and oral agreements,
            communications and other understandings relating to the subject matter of
            this Agreement, and that all waivers and modifications must be in writing
            signed by both parties, except as otherwise provided herein. No agency,
            partnership, joint venture, or employment is created as a result of this
            Agreement and Customer does not have any authority of any kind to bind
            Replicated in any respect whatsoever. In any action or proceeding to enforce
            rights under this Agreement, the prevailing party will be entitled to
            recover costs and attorneys’ fees. All notices under this Agreement will be
            in writing and will be deemed to have been duly given when received, if
            personally delivered; when receipt is electronically confirmed, if
            transmitted by facsimile or e-mail; the day after it is sent, if sent for
            next day delivery by recognized overnight delivery service; and upon
            receipt, if sent by certified or registered mail, return receipt requested.
            This Agreement shall be governed by the laws of the State of California
            without regard to its conflict of laws/provisions.
          </p>
        </div>
      </div>
    );
  }
}
