const theme = {
  primaryColor: "#F44247",
  secondaryColor: "#337AB7",
  tertiaryColor: "#EBB55C",
  accentColor: "#4DB9C0",
  mutedAccentColor: "#577981",
  title: "#4DB9C0",
  focus: "#4DB9C0",
  formLabel: "#585858",
  error: "#BC4752",
  errorBright: "#EE5042",
  errorDark: "#98222D",
  errorLight: "#FFDADA",
  errorXlight: "#FBEDEB",
  dustyGray: "#9B9B9B",
  allCapsHeader: "#2F2F2F",
  astral: "#337AB7",
  gray100: "#DFDFDF",
  gray200: "#C4C8CA",
  gray300: "#B3B3B3",
  gray400: "#959595",
  gray500: "#717171",
  gray600: "#585858",
  gray700: "#4F4F4F",
  gray800: "#323232",
  borderColor: "#BCCACD",
  primaryFont: "Montserrat",
  secondaryFont: "Open Sans",
  tertiaryFont: "Poppins",
  lightTaupe: "#FFFDF6",
  lightAccent: "#E1F0F1",
  backgroundColor: "#F5F8F9",
  fontSizeXsmall: "12px",
  fontSizeSmall: "14px",
  fontSizeMedium: "16px",
  fontSizeLarge: "18px",
  h1: "26px"
};

export default theme;
