import { RECEIVE_APP_LIST } from "./actions";
import keyBy from "lodash/keyBy";
import { SET_KOTS_APP_LIST } from "@/redux/kots/app/actions";

export function apps(state = {}, action) {
  switch (action.type) {
    case RECEIVE_APP_LIST:
      return keyBy(action.payload, "Id");
    default:
      return state;
  }
}

export function appsBySlug(state = {}, action) {
  switch (action.type) {
    case RECEIVE_APP_LIST:
      return keyBy(action.payload, "Slug");
    default:
      return state;
  }
}

interface AppsLoadedState {
  all?: boolean;
  kots?: boolean;
  platform?: boolean;
}

export function appsLoaded(state: AppsLoadedState = {}, action) {
  switch (action.type) {
    case RECEIVE_APP_LIST:
      return {
        ...state,
        platform: true,
        all: state.all || state.kots
      };
    case SET_KOTS_APP_LIST:
      return {
        ...state,
        kots: true,
        all: state.all || state.platform
      };
    default:
      return state;
  }
}
