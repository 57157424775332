import "isomorphic-fetch";
import { logOutUser } from "../../../../auth/sessions/actions";
import { SessionManager } from "../../../../../services/SessionManager";
import { displayModal, loadingData, forbiddenData } from "../../../../ui/main/actions";
import { showSavedToast } from "../../../../ui/main/apps/view_release/actions";
import { getChannelList } from "../channels/actions";
import { getReleaseList } from "../releases/actions";
import { setNewRelease } from "../view_release/actions";

export const SET_CHANNEL_HISTORY_LIST = "SET_CHANNEL_HISTORY_LIST";
export const SET_PAGE_SIZE_AND_CURRENT_PAGE = "SET_PAGE_SIZE_AND_CURRENT_PAGE";

function setChannelHistoryList(channelHistoryList) {
  return {
    type: SET_CHANNEL_HISTORY_LIST,
    payload: {
      histories: channelHistoryList,
      total_count: channelHistoryList.totalReleases
    }
  };
}

function setPageSizeAndCurrentPage(pageSize, currentPage) {
  return {
    type: SET_PAGE_SIZE_AND_CURRENT_PAGE,
    payload: {
      pageSize: pageSize,
      currentPage: currentPage
    }
  };
}

export function getChannelReleaseHistory(appId, channelId, pageSize, currentPage) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("channelHistories", true));
    let response;
    try {
      let url;
      if (pageSize) {
        url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel/${channelId}/releases?pageSize=${pageSize}&currentPage=${currentPage}`;
      } else {
        url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel/${channelId}/releases`;
      }
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("channelHistories", false));
          dispatch(forbiddenData("channelHistories", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setChannelHistoryList(body));
      dispatch(setPageSizeAndCurrentPage(pageSize, currentPage));
      dispatch(loadingData("channelHistories", false));
    } catch (error) {
      dispatch(loadingData("channelHistories", false));
      console.log(error);
      return;
    }
  };
}

export function buildAirgapChannelRelease(appId, sequence, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    const channelId = payload.channel;
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/${sequence}/airgap/build`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(showSavedToast(true));
      setTimeout(() => {
        dispatch(showSavedToast(false));
        dispatch(getChannelReleaseHistory(appId, channelId));
      }, 3000);
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function updateChannelHistoryRelease(
  appId,
  channelId,
  channelSequence,
  payload
) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("releaseUpdate", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel/${channelId}/release/${channelSequence}`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("releaseUpdate", false));
      dispatch(displayModal("editHistory", false));
      dispatch(getChannelReleaseHistory(appId, channelId));
      dispatch(getChannelList(appId, true));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("releaseUpdate", false));
      return;
    }
  };
}

export function createPatchRelease(appId, payload, callback) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/patch-release`;
      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setNewRelease(body));
      callback(body.PatchSequence);
      dispatch(getReleaseList(appId));
    } catch (error) {
      console.log(error);
      return;
    }
  };
}

export function archiveChannelHistoryRelease(appId, channelId, channelSequence) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("hideChannelRelease", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel/${channelId}/archive-releases?sequence=${channelSequence}`;
      response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("hideRelease", false));
      dispatch(displayModal("hideChannelRelease", false));
      dispatch(getChannelReleaseHistory(appId, channelId));
      dispatch(getChannelList(appId, true));
      dispatch(getReleaseList(appId));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("hideChannelRelease", false));
      return;
    }
  };
}

export function unarchiveChannelHistoryRelease(appId, channelId, channelSequence) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }
    dispatch(loadingData("unhideChannelRelease", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/app/${appId}/channel/${channelId}/unarchive-releases?sequence=${channelSequence}`;
      response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      dispatch(loadingData("unhideChannelRelease", false));
      dispatch(displayModal("showChannelRelease", false));
      dispatch(getChannelReleaseHistory(appId, channelId));
      dispatch(getChannelList(appId, true));
      dispatch(getReleaseList(appId));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("unhideChannelRelease", false));
      return;
    }
  };
}
