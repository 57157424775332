import type { Action } from "@/types";
import { SET_KOTS_CHANNEL } from "./actions";

const initialState = {
  channel: {},
  channelReleases: []
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_KOTS_CHANNEL:
      return Object.assign({}, state, {
        channel: action.payload.channel.channel
      });
    default:
      return state;
  }
}
