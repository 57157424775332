// @ts-strict
import cx from "classnames";
import { ReactNode } from "react";
import { useTransition } from "react-transition-state";

import useTimeout from "@/hooks/use-timeout";
import { Icon } from "./Icon";

const InlineCode = ({
  children,
  isShellCommand = false
}: {
  children: ReactNode | undefined;
  isShellCommand?: boolean;
}) => {
  const [{ status }, toggle] = useTransition({ timeout: 150 });
  const timeout = useTimeout();
  const isEntering = status === "entering";
  const isEntered = status === "entered";
  const isExiting = status === "exiting";
  const handleCopy = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    toggle(true);
    timeout(() => toggle(false), 2000);
  };
  const code = Array.isArray(children) ? children[0] : children;
  return (
    <div
      className={cx(
        "tw-group tw-relative tw-inline-flex tw-gap-1 tw-items-center tw-bg-[#eee] tw-rounded tw-box-border",
        "tw-px-1 tw-cursor-pointer tw-border tw-border-solid tw-border-transparent hover:tw-border-blue-300",
        "tw-transition tw-transition-duration-150 tw-ease-in-out",
        (isEntering || isEntered) && "tw-border-green-300 hover:tw-border-green-300"
      )}
      data-testid="inline-code"
      onClick={() => handleCopy(code)}
    >
      <code className="tw-bg-[#eee] tw-text-gray-600 tw-text-xs tw-p-0">
        {isShellCommand && "$ "}
        {code}
      </code>
      <Icon
        className={cx(
          "tw-cursor-pointer tw-text-gray-200 tw-transition tw-transition-duration-150 tw-ease-in-out group-hover:tw-text-blue-300",
          (isEntering || isEntered) && "tw-text-green-300 group-hover:tw-text-green-300"
        )}
        name={isEntering || isEntered || isExiting ? "check" : "copy"}
        width="12px"
      />
      <div
        className={cx(
          "tw-absolute tw-h-fit tw-py-1 tw-flex tw-items-center tw-gap-1 tw-bottom-full tw-rounded-t tw-right-1 tw-px-1 tw-bg-blue-300",
          "tw-transition tw-transition-duration-150 tw-ease-in-out tw-opacity-0 group-hover:tw-opacity-100",
          (isEntering || isEntered) && "tw-bg-green-300 tw-opacity-100"
        )}
      >
        <span className="tw-text-white tw-text-[10px] tw-leading-3 tw-font-sans tw-font-semibold">
          {isEntering || isEntered || isExiting ? "Copied!" : "Click to copy"}
        </span>
      </div>
    </div>
  );
};

export default InlineCode;
