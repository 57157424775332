import "isomorphic-fetch";
import { SessionManager } from "../../../../services/SessionManager";
import { logOutUser } from "../../../auth/sessions/actions";
import { loadingData, forbiddenData } from "../../../ui/main/actions";

export const SET_RETRACED_TOKEN = "SET_RETRACED_TOKEN";
export const SET_RETRACED_ERROR = "SET_RETRACED_ERROR";
export const CLEAR_RETRACED_ERROR = "CLEAR_RETRACED_ERROR";

function updateRetracedToken(scope, token) {
  return {
    type: SET_RETRACED_TOKEN,
    payload: {
      scope,
      token
    }
  };
}

function setRetracedError(err) {
  return {
    type: SET_RETRACED_ERROR,
    payload: err
  };
}

export function clearRetracedError() {
  return {
    type: CLEAR_RETRACED_ERROR
  };
}

export function getRetracedToken() {
  return async (dispatch, getState) => {
    dispatch(loadingData("retraced", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/auditlogtoken`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(forbiddenData("retraced", true));
          dispatch(loadingData("retraced", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(updateRetracedToken("global", body.token));
      dispatch(forbiddenData("retraced", false));
      dispatch(loadingData("retraced", false));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("retraced", false));
      dispatch(
        setRetracedError({
          error: {
            message:
              "Unable to load audit logs. Try refreshing the page. If the problem persists, please submit a support ticket using the Support tab."
          }
        })
      );
      return;
    }
  };
}

export function getScopedRetracedToken(targetId) {
  return async (dispatch, getState) => {
    dispatch(loadingData("retraced", true));
    let response;
    try {
      const url = `${SessionManager.getApiEndpoint()}/auditlogtoken?target_id=${targetId}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        }
      });
      if (!response.ok) {
        if (response.status === 403) {
          dispatch(forbiddenData("scopedRetraced", true));
          dispatch(loadingData("retraced", false));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(updateRetracedToken(targetId, body.token));
      dispatch(forbiddenData("scopedRetraced", false));
      dispatch(loadingData("retraced", false));
    } catch (error) {
      console.log(error);
      dispatch(loadingData("retraced", false));
      dispatch(
        setRetracedError({
          error: {
            message:
              "Unable to load audit logs for this item. Try refreshing the page.If the problem persists, please submit a support ticket using the Support tab."
          }
        })
      );
      return;
    }
  };
}
