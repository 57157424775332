import { useState, useEffect } from "react";

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

// Simple hook to return the legacy breakpoint string.
// Use CSS media and container queries instead of this hook.
const useLegacyBreakpoint = () => {
  const { width } = useWindowSize();
  if (typeof width === "undefined") {
    return;
  }
  if (width < 768) {
    return "mobile";
  }
  if (width < 840) {
    return "tablet";
  }
};

export default useLegacyBreakpoint;
