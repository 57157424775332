import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import Footer from "@/components/shared/Footer";
import "@/scss/components/auth/AuthPage.scss";
import { Button } from "../shared/Button";
import { SessionManager } from "@/services/SessionManager";

const LoginLink = ({ children }: { children: string }) => (
  <Link to="/login" className="replicated-link">
    {children}
  </Link>
);

const ErrorMessage = ({ children }: { children: React.ReactNode }) => (
  <div className="u-fontWeight--medium u-fontSize--large u-color--error u-lineHeight--more">
    {children}
  </div>
);

const UserGoogleAuthDisabledMessage = () => (
  <ErrorMessage>
    Your attempt to log in with Google was rejected. Your personal Replicated account
    has disabled Google Auth as an option. Try <LoginLink>logging in</LoginLink> with
    your username/password (or SAML if setup) and then visit your account settings to
    re-enable Google Auth.
  </ErrorMessage>
);

const TeamGoogleAuthDisabledMessage = () => (
  <ErrorMessage>
    Your attempt to log in with Google was rejected. Your Replicated team admin has
    disabled Google Auth for everyone. Try <LoginLink>logging in</LoginLink> with your
    username/password (or SAML if setup) or ask an administrator to adjust your team
    settings to allow Google Auth.
  </ErrorMessage>
);

const TrialExpiredButExtendableMessage = ({ nonce }) => (
  <ErrorMessage>
    <Button
      kind="primary-purple"
      size="large"
      className="flex-auto u-marginTop--normal"
      onClick={async () => {
        const response = await fetch(
          `${SessionManager.getApiEndpoint()}/team/extend-trial`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            body: JSON.stringify({ nonce })
          }
        );

        if (response.ok) {
          alert("Your trial has been extended by 12 hours. Click ok to log in again");
          window.location.href = "/login";
        }
      }}
    >
      Auto-extend trial by 12 hours
    </Button>
  </ErrorMessage>
);

const TrialExpiredMessage = () => (
  <ErrorMessage>
    Your free trial has ended. Please contact us at{" "}
    <a
      href="mailto:contact@replicated.com"
      target="_blank"
      rel="noopener noreferrer"
      className="u-color--astral u-fontWeight--medium u-textDecoration--underlineOnHover"
    >
      contact@replicated.com
    </a>{" "}
    if you need an extension.
  </ErrorMessage>
);

const MarketingPanel = () => (
  <div className="AuthIllustration-wrapper">
    <div className="ReplicatedLogoShadow logo-standalone"></div>
  </div>
);

const FormPanel = ({ children }: { children: React.ReactNode }) => (
  <div className="Login-wrapper flex1 flex-column flex-verticalCenter u-paddingBottom--normal alignItems--center auth-pages">
    <div className="Form-wrapper">{children}</div>
  </div>
);

const ErrorTitle = ({ children }: { children: string }) => (
  <>
    <span className="icon clickable google-g u-marginRight--normal" />
    <p className="form-title error u-lineHeight--normal">{children}</p>
  </>
);

const Page = ({ children }: { children: React.ReactNode }) => (
  <div className="u-width--full u-overflow--auto flex-column">
    <div className="u-flex--mobileoff flex-1-auto u-width--full">
      {children}
      <div className="auth-footer">
        <Footer />
      </div>
    </div>
  </div>
);

const AuthGoogleError = () => {
  const location = useLocation();
  const errorType = queryString.parse(location.search).error;

  const renderErrorMessage = () => {
    switch (errorType) {
      case "TEAM_GOOGLE_AUTH_DISABLED": {
        return <TeamGoogleAuthDisabledMessage />;
      }
      case "USER_GOOGLE_AUTH_DISABLED": {
        return <UserGoogleAuthDisabledMessage />;
      }
      case "TRIAL_EXPIRED_ALLOW_EXTENSION": {
        const nonce = queryString.parse(location.search).nonce;
        return <TrialExpiredButExtendableMessage nonce={nonce as string} />;
      }
      case "TRIAL_EXPIRED": {
        return <TrialExpiredMessage />;
      }
      default: {
        return <UserGoogleAuthDisabledMessage />;
      }
    }
  };

  const renderTitle = () => {
    switch (errorType) {
      case "TRIAL_EXPIRED_ALLOW_EXTENSION": {
        return <ErrorTitle>Trial Has Ended. But...</ErrorTitle>;
      }
      case "TRIAL_EXPIRED": {
        return <ErrorTitle>Your Trial Has Ended</ErrorTitle>;
      }
      default: {
        return <ErrorTitle>Authentication Error</ErrorTitle>;
      }
    }
  };

  const renderBelowMessage = () => {
    switch (errorType) {
      case "TRIAL_EXPIRED_ALLOW_EXTENSION":
      case "TRIAL_EXPIRED": {
        return (
          <p className="u-fontSize--normal u-fontWeight--medium u-lineHeight--normal u-marginTop--more">
            You will need to log in again. Contact your account rep for a longer
            extension.
          </p>
        );
      }
      default: {
        return <LoginLink>Click here to try again</LoginLink>;
      }
    }
  };

  return (
    <Page>
      <MarketingPanel />
      <FormPanel>
        <div className="u-marginTop--15">
          <div className="flex alignItems--center">{renderTitle()}</div>
          {renderErrorMessage()}
          <p className="u-fontSize--large u-fontWeight--medium u-lineHeight--normal u-marginTop--more">
            {renderBelowMessage()}
          </p>
        </div>
      </FormPanel>
    </Page>
  );
};

export default AuthGoogleError;
